<template>
  <div>
    <Sortable
      v-if="attachments.length > 0"
      :list="attachments"
      item-key="_id"
      tag="ul"
      class="editable-list"
      :options="sortableOptions"
      @end="(evt) => moveItems(evt, attachments)">
      <template #item="{ element }">
        <li class="file-details">
          <div v-if="!checkProgress(element)" class="image-container">
            <img v-if="element.downloadURL && checkURL(element)" :src="element.downloadURL" />
            <div v-else-if="element.title" class="file-name display-flex align-center">
              <span class="" ref="attachmentTitle">{{ element.title }}</span>
            </div>
            <img v-else :src="element" />
          </div>
          <div class="spinner-border" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="file-name display-flex align-center" v-if="checkProgress(element)">
            <span class="" ref="attachmentTitle">{{ element.title }}</span>
          </div>
          <div class="file-info" v-if="checkProgress(element)">
            <span class="upload-prgress">
              {{ `${element.progress} %` }}
            </span>
          </div>
          <i class="item-remove material-icons" @click="removeImage(index, element)">delete</i>
        </li>
      </template>
    </Sortable>
  </div>
</template>

<script>
import { Sortable } from 'sortablejs-vue3';
export default {
  name: 'AttachmentList',
  computed: {
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  methods: {
    checkProgress(attachment) {
      return attachment.progress === null || attachment.progress === undefined || attachment.progress === 100 ? false : true;
    },
    checkURL(attachment) {
      return attachment.downloadURL.match(/\.(jpeg|jpg|gif|png)$/) != null || attachment.extension.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    removeImage(index, tempAttachment) {
      if (tempAttachment.ref) {
        tempAttachment.ref.delete();
      }
      this.attachments.splice(index, 1);
    },
    moveItems(evt, array) {
      const item = array.splice(evt.oldIndex, 1)[0];
      array.splice(evt.newIndex, 0, item);
    },
  },
  props: ['attachments'],
  components: {
    Sortable,
  },
};
</script>

<style scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.file-details {
  text-align: center;
  padding: 10px;
  font-size: 12px;
  width: 120px;
}
.file-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.image-container {
  height: 100px;
  width: 100px;
}
img {
  max-height: 100px;
  max-width: 100px;
}
</style>
