<template>
  <div class="mb-3" id="file">
    <template>
      <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
        File
        <a href="/listnm/file-mgmt" class="add-item" target="_blank">Create New File</a>
      </legend>
    </template>
    <cool-select
      :value="value"
      :items="itemList"
      :placeholder="value ? '' : '-- Select File --'"
      item-value="value"
      item-text="title"
      :error-message="state == false ? 'File is required.' : null"
      :successful="state"
      @select="selectFile">
      <!-- slot for each item in the menu -->
      <template #item="{ item }">
        <div :class="getSelectItemClass(item)">
          <span v-if="item.level > 0">└</span>
          {{ item.title }}
        </div>
      </template>
      <template #selection="{ item }">
        <div :class="getSelectItemClass(item)">
          {{ item.title }}
        </div>
      </template>
    </cool-select>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { CoolSelect } from 'vue-cool-select';
export default {
  components: {
    CoolSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: true,
    },
    community: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemList: [],
    };
  },
  watch: {
    community: function () {
      this.updateContents();
    },
  },
  async created() {
    this.updateContents();
  },
  methods: {
    updateContents: async function () {
      const communityKey = this.community || this.getCampgroundKey;
      await this.getFolderContents(`${communityKey}/file-mgmt`, 0);
    },
    selectFile: function (value) {
      if (value.value !== this.value) {
        //Fix for clicking one disabled to another
        this.$emit('input', '-');
        this.$nextTick(() => {
          this.$emit('input', value.value);
          this.$emit('select', value.value);
        });
      } else {
        this.$emit('input', value.value);
        this.$emit('select', value.value);
      }
    },
    sortFiles: function (objectList) {
      return objectList.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    },
    async getFolderContents(path, level) {
      const ref = firebase.storage().ref(path);
      await ref
        .listAll()
        .then(async (dir) => {
          let folders = this.sortFiles(dir.prefixes);
          for (const folderRef of folders) {
            await this.itemList.push({
              title: folderRef.name,
              disabled: true,
              path: folderRef.fullPath,
              level: level,
            });
            await this.getFolderContents(folderRef.fullPath, level + 1);
          }
          let files = this.sortFiles(dir.items);
          for (const fileRef of files) {
            if (fileRef.name !== 'init.txt') {
              await this.itemList.push({
                title: fileRef.name,
                disabled: false,
                value: await fileRef.getDownloadURL(),
                path: fileRef.fullPath,
                level: level,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSelectItemClass: function (item) {
      return `select__item ${item.disabled ? 'disabled' : ''} ms-${item.level * 2}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.select__item.disabled::before {
  content: '📂';
}
</style>
