<template>
  <div class="two-column">
    <div id="map-edit">
      <div class="button-header d-flex">
        <div>
          <img src="/amc_logo.png" alt="" id="amc-logo" @click="goHome()" />
          <i class="material-icons logo-icons" @click="goHome()">home</i>
        </div>
        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" id="button-bar">
          <div class="btn-group mx-1" role="group">
            <button class="btn btn-secondary" @click="buttonClick('zoom', 100)" v-tooltip-bottom="'Zoom Out'">
              <i class="material-icons" role="button">remove</i>
            </button>
            <button class="btn btn-secondary">{{ Math.round(canvasScale * 100) }}%</button>
            <button class="btn btn-secondary" @click="buttonClick('zoom', -100)" v-tooltip-bottom="'Zoom In'">
              <i class="material-icons" role="button">add</i>
            </button>
            <button
              class="btn btn-secondary"
              v-if="canvasScale > 1"
              :class="{ active: isPanning }"
              @click="buttonClick('pan')"
              v-tooltip-bottom="'Pan'">
              <i class="material-icons" role="button">open_with</i>
            </button>
          </div>
          <div class="btn-group mx-1" role="group">
            <button
              class="btn btn-secondary"
              @click="buttonClick('select')"
              :class="{ active: action == 'select' }"
              v-tooltip-bottom="'Select and Modify Shapes'">
              <i class="material-icons" role="button">format_shapes</i>
            </button>
          </div>
          <div class="btn-group mx-1" role="group">
            <button
              class="btn btn-secondary"
              @click="buttonClick('delete')"
              v-if="canvas && canvas.getActiveObject()"
              v-tooltip-bottom="'Delete Selected Shape'">
              <i class="material-icons" role="button">delete</i>
            </button>
            <button class="btn btn-secondary" @click="buttonClick('clear')" v-tooltip-bottom="'Clear Canvas'">
              <i class="material-icons" role="button">clear_all</i>
            </button>
          </div>
          <div class="btn-group mx-1">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="material-icons" role="button">{{ selectedShape }}</i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item" href="#" @click.stop="buttonClick('shape', 'remove')"
                  ><i class="material-icons" role="button">remove</i> Line</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click.stop="buttonClick('shape', 'crop_din')"
                  ><i class="material-icons" role="button">crop_din</i> Square</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click.stop="buttonClick('shape', 'panorama_fish_eye')"
                  ><i class="material-icons" role="button">panorama_fish_eye</i> Circle</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click.stop="buttonClick('shape', 'polymer')"
                  ><i class="material-icons" role="button">polymer</i> Polygon</a
                >
              </li>
            </ul>

            <template v-if="action == 'shape' && selectedShape == 'remove'">
              <button class="btn btn-secondary" @click="lineStroke = lineStroke - 1" v-tooltip-bottom="'Decrease Line Width'">
                <i class="material-icons" role="button">remove</i>
              </button>
              <button class="btn btn-secondary">
                {{ lineStroke }}
              </button>
              <button class="btn btn-secondary" @click="lineStroke = lineStroke + 1" v-tooltip-bottom="'Increase Line Width'">
                <i class="material-icons" role="button">add</i>
              </button>
            </template>
          </div>
          <div class="btn-group mx-1">
            <button class="btn btn-secondary" @click="buttonClick('text')" v-tooltip-bottom="'Insert Text'">
              <i class="material-icons" role="button">text_fields</i>
            </button>
          </div>
          <div class="btn-group mx-1">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :class="{ 'dropdown-used': action == 'icon' }"
              @click="buttonClick('icon', selectedIcon)">
              <i class="material-icons" role="button">{{ selectedIcon }}</i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item" href="#" @click.stop="buttonClick('icon', 'room')"><i class="material-icons" role="button">room</i></a>
              </li>
              <li>
                <a class="dropdown-item" href="#" @click.stop="buttonClick('icon', 'push_pin')"
                  ><i class="material-icons" role="button">push_pin</i></a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click.stop="buttonClick('icon', 'arrow_right_alt')"
                  ><i class="material-icons" role="button">arrow_right_alt</i></a
                >
              </li>
            </ul>
          </div>
          <div class="btn-group mx-1">
            <button
              class="btn btn-secondary"
              :disabled="!canUndo()"
              @click="undoPoint()"
              v-tooltip-bottom="'Undo (Only available when drawing lines and polygons)'">
              <i class="material-icons" role="button">undo</i>
            </button>
            <!---
            <button class="btn btn-secondary" @click="buttonClick('save')" data-bs-toggle="tooltip" title="Save PNG">
              <i class="material-icons" role="button">save</i>
            </button>
            <button class="btn btn-secondary" @click="buttonClick('saveSvg')" data-bs-toggle="tooltip" title="Save SVG">
              <i class="material-icons" role="button">save</i> SVG
            </button>
            -->
          </div>
          <div class="btn-group mx-1">
            <button class="btn btn-secondary" @click.stop="updateFontColor(true)" v-tooltip-bottom="'Pick Color'">
              <div>
                <div class="color-selector" role="button">
                  <label class="btn btn-light">
                    <div :style="getUpdatedBackgroundColor(fontColor)">&nbsp;</div>
                  </label>
                </div>
                <chrome-picker
                  id="editFontColor"
                  :data-edit="editFontColor"
                  v-show="editFontColor"
                  v-model="fontColor"
                  v-click-outside="hideFontSelector" />
              </div>
            </button>
            <button
              class="btn btn-secondary"
              v-for="(color, colorIndex) in usedColors"
              :key="colorIndex"
              @click.stop="fontColor = color"
              v-tooltip-bottom="'Select Color'">
              <div class="color-selector" role="button">
                <label class="btn btn-light">
                  <div :style="getUpdatedBackgroundColor(color)">&nbsp;</div>
                </label>
              </div>
            </button>
          </div>
        </div>
      </div>
      <canvas class="m-1 drawingcanvas" ref="can"></canvas>
      <canvas class="m-1 d-none" ref="can-hidden"></canvas>
      ({{ mouseX }}, {{ mouseY }})
    </div>
    <div id="right-column" class="p-2">
      <div class="p-2 form-container">
        <div class="row">
          <div class="col-12 col-xxl-7">
            <div class="row">
              <div class="col-12 col-xxxl-8">
                <div class="mb-3">
                  <label for="titleInput" class="form-label">Title</label>
                  <input type="text" class="form-control" id="titleInput" v-model="newItem.title" required />
                  <Validation :validator="vuelidate$.newItem.title" feedback="Title is required." />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="isSiteCheckbox" v-model="newItem.isSite" />
                  <label class="form-check-label" for="isSiteCheckbox"> Is Site, Cabin, Lodging? </label>
                </div>
              </div>
            </div>
            <div class="row" v-if="newItem.isSite">
              <div class="col">
                <div class="mb-3">
                  <label for="uniqueIdInput" class="form-label">Unique ID</label>
                  <input type="text" class="form-control" id="uniqueIdInput" v-model="newItem.uniqueId" trim required />
                  <Validation :validator="vuelidate$.newItem.uniqueId" feedback="Unique ID is required." />
                  <div class="form-text">
                    This id will be used for an exact match based on what a user would be searching for. For Site #51 use '51', that way, if someone
                    types 51 only that site would be displayed
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="searchTerms" class="form-label">Common Search Terms</label>
                  <Multiselect
                    id="searchTerms"
                    v-model="newItem.searchStrings"
                    mode="tags"
                    :clear-on-select="true"
                    :create-option="true"
                    :options="mapSearchTerms"
                    :multiple="true"
                    :groups="true"
                    :group-select="true"
                    :searchable="true"
                    placeholder="Select or Tag Location with Terms"
                    :close-on-select="false"
                    @create="addTag" />
                </div>
              </div>
            </div>
            <div v-if="newItem.title && actionParams">
              <div header-tag="header" class="p-1 w-100" role="tab">
                <button class="btn btn-info w-100" data-bs-toggle="collapse" data-bs-target="#accordion-1" @click="$refs.qrCode.getDeepLinks()">
                  QR Code
                </button>
              </div>
              <div class="collapse show" id="accordion-1" data-bs-parent="#my-accordion" role="tabpanel">
                <QRCode ref="qrCode" :title="`${newItem.title} QR Code`" action="searchablemap" :actionKey="mapKey" :actionParams="actionParams" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <button class="btn btn-primary m-1" type="button" @click="addUpdateItem">{{ getButtonTitle }}</button>
                  <button v-if="itemIndex >= 0" class="btn btn-primary m-1" type="button" @click="addUpdateItem(true)">Add As New</button>
                  <button class="btn btn-secondary m-1" type="button" @click="resetLocation()">Reset Location</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <input class="form-control m-2 user-filter" placeholder="Filter Locations" v-model="locationFilter" type="search" />
            <Sortable
              v-if="mapData.locations && mapData.locations.length > 0"
              :list="filteredLocations"
              item-key="key"
              tag="ul"
              class="editable-list"
              :options="sortableOptions"
              @end="(evt) => moveItems(evt, mapData.locations)">
              <template #item="{ element, index }">
                <li class="single-row list-group-item" @click="editItem(index)">
                  <div>
                    {{ element.title }}
                  </div>
                  <i class="material-icons">edit</i>
                  <i class="material-icons" @click.stop="removeItem(index)">delete</i>
                </li>
              </template>
            </Sortable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric';
import { Chrome } from 'vue-color';
import mapSearchTerms from '../json/mapSearchTerms.json';
import Vue from 'vue';
import { Sortable } from 'sortablejs-vue3';
import { required } from '@vuelidate/validators';
import Multiselect from '@vueform/multiselect';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { LoginMixin } from '../components/mixins/LoginMixin.js';
import QRCode from './inputfields/QRCode';

/**
 * define a function that can locate the controls.
 * this function will be used both for drawing and for interaction.
 */
var canvasScale = 1;
function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  const transformPoint = {
    x: fabricObject.points[this.pointIndex].x * canvasScale - fabricObject.pathOffset.x,
    y: fabricObject.points[this.pointIndex].y * canvasScale - fabricObject.pathOffset.y,
  };
  return fabric.util.transformPoint(transformPoint, fabricObject.calcTransformMatrix());
}

/**
 * define a function that will define what the control does
 * this function will be called on every mouse move after a control has been
 * clicked and is being dragged.
 * The function receive as argument the mouse event, the current trasnform object
 * and the current position in canvas coordinate
 * transform.target is a reference to the current object being transformed,
 */
function actionHandler(eventData, transform, x, y) {
  const polygon = transform.target;
  const currentControl = polygon.controls[polygon.__corner];
  const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
  const size = polygon._getTransformedDimensions(0, 0);
  const finalPointPosition = {
    x: (mouseLocalPosition.x * polygon.width) / size.x + polygon.pathOffset.x,
    y: (mouseLocalPosition.y * polygon.height) / size.y + polygon.pathOffset.y,
  };
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

/**
 * define a function that can keep the polygon in the same position when we change its
 * width/height/top/left.
 */
function anchorWrapper(anchorIndex, fn) {
  return function (eventData, transform, x, y) {
    const fabricObject = transform.target;
    const point = {
      x: fabricObject.points[anchorIndex].x * canvasScale - fabricObject.pathOffset.x,
      y: fabricObject.points[anchorIndex].y * canvasScale - fabricObject.pathOffset.y,
    };

    // update the transform border
    fabricObject._setPositionDimensions({});

    // Now newX and newY represent the point position with a range from
    // -0.5 to 0.5 for X and Y.
    const newX = point.x / fabricObject.width;
    const newY = point.y / fabricObject.height;

    // Fabric supports numeric origins for objects with a range from 0 to 1.
    // This let us use the relative position as an origin to translate the old absolutePoint.
    const absolutePoint = fabric.util.transformPoint(point, fabricObject.calcTransformMatrix());
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);

    // action performed
    return fn(eventData, transform, x, y);
  };
}

export default {
  components: {
    'chrome-picker': Chrome,
    Sortable,
    Multiselect,
    QRCode,
  },
  mixins: [LoginMixin],

  data() {
    return {
      locationFilter: '',
      filteredLocations: {},
      currentLocations: [],
      imgURL: '',
      canvas: null,
      canvasOriginalWidth: 1000,
      canvasOriginalHeight: 800,
      canvasWidth: 1000,
      canvasHeight: 800,
      imgWidth: 0,
      imgHeight: 0,
      bgImage: 0,
      canvasScale: 1,
      isPanning: false,
      fontColor: { hex: '#ff0000' },
      editFontColor: false,
      scaleFactor: 1,
      selectedIcon: 'room',
      selectedShape: 'crop_din',
      svgMap: {
        room: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>',
        push_pin:
          '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><g><rect fill="none" height="24" width="24"/></g><g><path d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z" fill-rule="evenodd"/></g></svg>',
        arrow_right_alt:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg>',
      },
      mouseX: 0,
      mouseY: 0,
      pointIndex: 0,
      activeLine: null,
      activeShape: null,
      lineArray: [],
      pointArray: [],
      drawMode: false,
      action: null,
      isDown: false,
      origX: null,
      origY: null,
      lineStroke: 2,
      usedColors: [],
      itemIndex: -1,
      newItem: {
        title: '',
        searchStrings: [],
        isSite: false,
        uniqueId: null,
      },
      needsUpdate: false,
      mapSearchTerms,
      mapData: {
        locations: [],
      },
      dbRef: null,
      mapKey: '',
      actionParams: [],
    };
  },
  validations() {
    return {
      newItem: {
        title: {
          required,
        },
      },
    };
  },
  computed: {
    getButtonTitle() {
      return this.itemIndex >= 0 ? 'Update Location' : 'Add Location';
    },
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  watch: {
    locationFilter: function () {
      this.filterLocations();
    },
  },
  async created() {
    const routePath = decodeURIComponent(this.$route.params.path);
    this.mapKey = routePath.split('/')[1];
    console.log('🚀 ~ file: MapEdit.vue ~ line 400 ~ created ~ routePath', routePath);
    this.dbRef = firebase.database().ref('resort-navigator').child('location-data').child(this.getCampgroundKey).child(routePath);
    this.mapData = { ...this.mapData, ...(await this.dbRef.once('value')).val() };
    this.getActionParams();
    this.setCurrentLocations();
    this.imgURL = this.mapData.drawable;

    let img = new Image();
    img.onload = () => {
      const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0, this.canvasOriginalWidth) - 50;
      const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0, this.canvasOriginalHeight) - 50;
      this.imgHeight = img.height;
      this.imgWidth = img.width;
      if (img.height > img.width) {
        this.canvasHeight = vh;
        this.canvasWidth = (img.width * vh) / img.height;
      } else {
        this.canvasWidth = vw;
        this.canvasHeight = (img.height * vw) / img.width;
      }

      this.canvasOriginalWidth = this.canvasWidth;
      this.canvasOriginalHeight = this.canvasHeight;
      if (this.canvas) {
        this.setCanvasBackgroundImageUrl(this.mapData.drawable);
      }
    };
    img.src = this.imgURL;

    this.usedColors = JSON.parse(window.localStorage.getItem('USED_COLORS')) || [];
  },
  mounted() {
    const ref = this.$refs.can;
    this.canvas = new fabric.Canvas(ref);

    this.setCanvasBackgroundImageUrl(this.mapData.drawable);
    //this.canvas.isDrawingMode = true;
    let vm = this;
    this.canvas.on('selection:created', function () {
      vm.isPanning = false;
    });
    this.canvas.on('mouse:wheel', function (opt) {
      const e = opt.e;
      vm.updateZoom(e.deltaY);
      e.preventDefault();
      e.stopPropagation();
    });
  },
  methods: {
    getActionParams() {
      this.newItem.key = this.newItem.key || this.getContextRef('maps').push().key;
      this.actionParams = [{ key: this.newItem.title.replaceAll(' ', ''), value: this.newItem.key }];
    },
    filterLocations: function () {
      console.log('TCL: this.locationFilter', this.locationFilter);
      if (this.locationFilter) {
        this.filteredLocations = this.currentLocations.filter((item) => {
          const ftr = this.locationFilter.toLowerCase();
          return (
            item.title.toLowerCase().includes(ftr) ||
            (item.searchStrings ? item.searchStrings.filter((x) => x.toLowerCase().includes(ftr)).length > 0 : false)
          );
        });
      } else {
        this.filteredLocations = this.currentLocations;
      }
    },
    setCurrentLocations: function () {
      this.currentLocations = this.mapData.locations
        ? this.mapData.locations.map((location, index) => {
            return { ...location, ...{ index: index } };
          })
        : [];
      this.filteredLocations = this.currentLocations;
    },
    addTag: function (newTag) {
      if (!newTag) return;
      let customTerms = this.mapSearchTerms.filter((x) => x.label == 'Custom Terms');
      if (customTerms.length > 0) {
        customTerms[0].options.push(newTag.value);
      } else {
        this.mapSearchTerms.push({ label: 'Custom Terms', options: [newTag.value] });
      }
      this.newItem.searchStrings.push(newTag.value);
      return newTag.value;
    },
    undoPoint: function () {
      let lastPoint = this.pointArray.pop();
      this.canvas.remove(lastPoint);
      let lastLine = this.lineArray.pop();
      this.canvas.remove(lastLine);
      lastLine = this.lineArray.pop();
      this.canvas.remove(lastLine);
      const points = this.activeShape.get('points');
      let lastActiveShapePoint = points.pop();
      if (!lastActiveShapePoint.x) {
        points.pop();
      }
      this.updateActiveLine(points[points.length - 1].x, points[points.length - 1].y);

      const polygon =
        this.selectedShape == 'remove'
          ? new fabric.Polyline(points, {
              stroke: this.getRgb(this.fontColor),
              fill: '',
              strokeWidth: this.lineStroke,
              hasBorders: false,
              evented: false,
              objectCaching: false,
            })
          : new fabric.Polygon(points, {
              stroke: '#333333',
              strokeWidth: 1,
              fill: '#666666',
              opacity: 0.3,
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false,
              objectCaching: false,
              id: 'tempPoly',
            });
      this.canvas.remove(this.activeShape);
      this.canvas.add(polygon);
      this.activeShape = polygon;
      this.canvas.renderAll();
    },
    canUndo: function () {
      return (
        (this.selectedShape == 'polymer' || this.selectedShape == 'remove') &&
        this.activeShape &&
        this.activeShape.get('points') &&
        this.activeShape.get('points').length > 1
      );
    },
    getUpdatedBackgroundColor: function (newFont) {
      return { 'background-color': newFont.hex || this.fontColor.hex || this.fontColor };
    },
    mouseUp: function (opt) {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      let vm = this;
      let canvas = this.canvas;
      canvas.isDragging = false;
      canvas.selection = true;
      vm.isDown = false;
      const pointer = canvas.getPointer(opt.e);
      if (vm.action == 'icon' && !canvas.getActiveObject()) {
        fabric.loadSVGFromString(vm.svgMap[vm.selectedIcon], function (objects, options) {
          let shape = fabric.util.groupSVGElements(objects, options);
          shape.set({ left: pointer.x - shape.width / 2, top: pointer.y - shape.height / 2 });
          if (shape.type == 'group') {
            var i = 0;
            while (shape.item(i)) {
              shape.item(i).fill = shape.item(i).fill ? vm.getRgb(vm.fontColor) : shape.item(i).fill;
              i++;
            }
            shape.addWithUpdate();
          } else {
            shape.fill = vm.getRgb(vm.fontColor);
          }
          canvas.add(shape).renderAll();
          vm.activeShape = shape;
        });
      } else if (vm.action == 'text' && !canvas.getActiveObject()) {
        this.canvas.add(
          new fabric.IText('Insert Text', {
            fontFamily: 'arial',
            left: pointer.x,
            top: pointer.y,
            fill: vm.getRgb(vm.fontColor),
            transparentCorners: false,
          })
        );
      }
    },
    mouseOut: function () {
      let vm = this;
      let canvas = this.canvas;
      if ((vm.selectedShape == 'remove' || vm.selectedShape == 'polymer') && this.activeLine && this.activeLine.class === 'line') {
        this.activeLine.set({
          x2: this.activeLine.x,
          y2: this.activeLine.y,
        });
        if (vm.selectedShape == 'polymer') {
          const points = this.activeShape.get('points');
          points[this.pointArray.length] = {
            x: this.activeLine.x,
            y: this.activeLine.y,
          };
          this.activeShape.set({
            points,
          });
        }
      }
      canvas.renderAll();
    },
    mouseMove: function (opt) {
      let vm = this;
      let canvas = this.canvas;
      const e = opt.e;
      const pointer = canvas.getPointer(e);
      this.mouseX = pointer.x;
      this.mouseY = pointer.y;
      if (canvas.isDragging) {
        const vpt = canvas.viewportTransform;
        vpt[4] += e.clientX - canvas.lastPosX;
        vpt[5] += e.clientY - canvas.lastPosY;
        canvas.requestRenderAll();
        canvas.lastPosX = e.clientX;
        canvas.lastPosY = e.clientY;
      } else if (vm.action == 'shape') {
        if (vm.selectedShape == 'polymer' || vm.selectedShape == 'remove') {
          if (this.activeLine && this.activeLine.class === 'line') {
            this.activeLine.set({
              x2: pointer.x,
              y2: pointer.y,
            });
            if (vm.selectedShape == 'polymer') {
              const points = this.activeShape.get('points');
              points[this.pointArray.length] = {
                x: pointer.x,
                y: pointer.y,
              };
              this.activeShape.set({
                points,
              });
            }
          }
        } else {
          if (!vm.isDown) return;
          if (vm.origX > pointer.x) {
            vm.activeShape.set({ left: Math.abs(pointer.x) });
          }
          if (vm.origY > pointer.y) {
            vm.activeShape.set({ top: Math.abs(pointer.y) });
          }
          if (vm.selectedShape == 'crop_din') {
            vm.activeShape.set({ width: Math.abs(vm.origX - pointer.x) });
            vm.activeShape.set({ height: Math.abs(vm.origY - pointer.y) });
          } else if (vm.selectedShape == 'panorama_fish_eye') {
            vm.activeShape.set({
              rx: Math.abs((vm.origX - pointer.x) / 2),
              ry: Math.abs((vm.origY - pointer.y) / 2),
            });
          }
        }
        canvas.renderAll();
      }
    },
    mouseDown: function (opt) {
      let vm = this;
      let canvas = this.canvas;
      const e = opt.e;
      if (vm.isPanning || e.altKey === true) {
        canvas.isDragging = true;
        canvas.selection = false;
        canvas.lastPosX = e.clientX;
        canvas.lastPosY = e.clientY;
      } else if (vm.action == 'shape') {
        if (vm.selectedShape == 'polymer' && (!this.canvas.getActiveObject() || !this.canvas.getActiveObject().edit)) {
          if (opt.target && vm.pointArray[0] && opt.target.id === vm.pointArray[0].id) {
            // when click on the first point
            canvas.selection = true;
            vm.generatePolygon();
          } else {
            canvas.selection = false;
            vm.addPoint(opt);
          }
        } else if (vm.selectedShape == 'remove' && (!this.canvas.getActiveObject() || !this.canvas.getActiveObject().edit)) {
          canvas.selection = false;
          vm.addLinePoint(opt);
        } else if (vm.selectedShape !== 'polymer' && vm.selectedShape !== 'remove') {
          vm.isDown = true;
          let pointer = canvas.getPointer(e);
          vm.origX = pointer.x;
          vm.origY = pointer.y;
          if (vm.selectedShape == 'crop_din') {
            vm.activeShape = new fabric.Rect({
              left: vm.origX,
              top: vm.origY,
              originX: 'left',
              originY: 'top',
              width: 0,
              height: 0,
              angle: 0,
              fill: vm.getRgb(vm.fontColor),
              transparentCorners: false,
              selectable: false,
            });
          } else if (vm.selectedShape == 'panorama_fish_eye') {
            vm.activeShape = new fabric.Ellipse({
              left: vm.origX,
              top: vm.origY,
              originX: 'left',
              originY: 'top',
              width: 0,
              height: 0,
              rx: 0,
              ry: 0,
              hasRotatingPoint: false,
              fill: vm.getRgb(vm.fontColor),
              transparentCorners: false,
              selectable: false,
            });
          }
          canvas.add(vm.activeShape);
        }
      }
    },
    updateFontColor: function (value) {
      this.editFontColor = value;
    },
    hideFontSelector: function () {
      if (!this.usedColors[0] || this.usedColors[0].hex !== this.fontColor.hex) {
        this.usedColors = this.usedColors.filter((color) => color !== this.fontColor);
        this.usedColors.unshift(this.fontColor);
        if (this.usedColors.length > 5) {
          this.usedColors.pop();
        }
        window.localStorage.setItem('USED_COLORS', JSON.stringify(this.usedColors));
      }
      this.canvas.freeDrawingBrush.color = this.getRgb(this.fontColor);
      this.editFontColor = false;
    },
    getRgb(color) {
      return color.rgba ? `rgb(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b})` : `rgb(255,0,0)`;
    },
    setCanvasBackgroundImageUrl: function (url) {
      if (url && url.length > 0) {
        let vm = this;
        fabric.Image.fromURL(url, function (img) {
          vm.bgImage = img;
          vm.scaleAndPositionImage();
        });
      } else {
        this.canvas.backgroundImage = 0;
        this.canvas.setBackgroundImage('', this.canvas.renderAll.bind(this.canvas));

        this.canvas.renderAll();
      }
    },
    scaleAndPositionImage: function () {
      this.setCanvasZoom();

      var canvasAspect = this.canvasWidth / this.canvasHeight;
      var imgAspect = this.bgImage.width / this.bgImage.height;
      var left, top;

      if (canvasAspect >= imgAspect) {
        this.scaleFactor = this.canvasWidth / this.bgImage.width;
        left = 0;
        top = -(this.bgImage.height * this.scaleFactor - this.canvasHeight) / 2;
      } else {
        this.scaleFactor = this.canvasHeight / this.bgImage.height;
        top = 0;
        left = -(this.bgImage.width * this.scaleFactor - this.canvasWidth) / 2;
      }

      this.canvas.setBackgroundImage(this.bgImage, this.canvas.renderAll.bind(this.canvas), {
        top: top,
        left: left,
        originX: 'left',
        originY: 'top',
        scaleX: this.scaleFactor,
        scaleY: this.scaleFactor,
      });
      this.canvas.renderAll();
    },
    setCanvasZoom: function () {
      this.canvasWidth = this.canvasOriginalWidth * this.canvasScale;
      this.canvasHeight = this.canvasOriginalHeight * this.canvasScale;

      this.canvas.setWidth(this.canvasWidth);
      this.canvas.setHeight(this.canvasHeight);
    },
    updateZoom: function (delta) {
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this.canvasScale = zoom;
      this.canvas.setZoom(zoom);
      if (zoom < 1) {
        const vpt = this.canvas.viewportTransform;
        vpt[4] = 0;
        vpt[5] = 0;
      }
      canvasScale = zoom;
      return zoom;
    },
    updateActiveLine(x, y) {
      this.canvas.remove(this.activeLine);

      const linePoints = [x, y, x, y];
      const lineOption = {
        strokeWidth: 2,
        fill: '#999999',
        stroke: '#999999',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      };
      const line = new fabric.Line(linePoints, lineOption);
      line.class = 'line';

      this.activeLine = line;
      this.lineArray.push(line);

      this.canvas.add(line);
    },
    addLinePoint: function (options) {
      let canvas = this.canvas;
      const vpt = canvas.viewportTransform;
      let mouseOffsetX = (options.e.layerX - vpt[4]) / canvas.getZoom();
      let mouseOffsetY = (options.e.layerY - vpt[5]) / canvas.getZoom();
      this.updateActiveLine(mouseOffsetX, mouseOffsetY);

      if (this.activeShape) {
        const pos = canvas.getPointer(options.e);
        const points = this.activeShape.get('points');
        points.push({
          x: pos.x,
          y: pos.y,
        });
        const polygon = new fabric.Polyline(points, {
          stroke: this.getRgb(this.fontColor),
          fill: '',
          strokeWidth: this.lineStroke,
          hasBorders: false,
          evented: false,
          objectCaching: false,
          moveable: false,
          hasControls: true,
          transparentCorners: false,
        });
        canvas.remove(this.activeShape);
        canvas.add(polygon);
        this.activeShape = polygon;
        canvas.renderAll();
      } else {
        const polyPoint = [
          {
            x: mouseOffsetX,
            y: mouseOffsetY,
          },
        ];
        const polygon = new fabric.Polyline(polyPoint, {
          stroke: this.getRgb(this.fontColor),
          fill: '',
          strokeWidth: this.lineStroke,
          hasBorders: false,
          evented: false,
          objectCaching: false,
          moveable: false,
          hasControls: true,
          transparentCorners: false,
        });
        this.activeShape = polygon;
        canvas.add(polygon);
      }
    },
    removePolygonItems: function () {
      let canvas = this.canvas;
      // collect points and remove them from canvas
      for (const point of this.pointArray) {
        canvas.remove(point);
      }

      // remove lines from canvas
      for (const line of this.lineArray) {
        canvas.remove(line);
      }

      // remove selected Shape and Line
      canvas.remove(this.activeShape).remove(this.activeLine);
    },
    generatePolygon: function () {
      let canvas = this.canvas;
      const points = [];
      for (const point of this.pointArray) {
        points.push({
          x: point.left,
          y: point.top,
        });
      }
      this.removePolygonItems();
      // create polygon from collected points
      const polygon = new fabric.Polygon(points, {
        id: new Date().getTime(),
        stroke: this.getRgb(this.fontColor),
        fill: this.getRgb(this.fontColor),
        objectCaching: false,
        moveable: false,
        //selectable: false
      });
      canvas.add(polygon);
      this.canvas.setActiveObject(polygon);
      this.toggleDrawPolygon();
      this.resetPolygon();
      this.editPolygon();
    },
    resetPolygon: function () {
      this.isDown = false;
      this.activeLine = null;
      this.activeShape = null;
      this.lineArray = [];
      this.pointArray = [];
    },
    toggleDrawPolygon: function () {
      if (this.drawMode) {
        // stop draw mode
        this.resetPolygon();
        this.canvas.selection = true;
        this.drawMode = false;
      } else {
        // start draw mode
        this.canvas.selection = false;
        this.drawMode = true;
      }
    },
    addPoint: function (options) {
      let canvas = this.canvas;
      const vpt = canvas.viewportTransform;
      let mouseOffsetX = (options.e.layerX - vpt[4]) / canvas.getZoom();
      let mouseOffsetY = (options.e.layerY - vpt[5]) / canvas.getZoom();
      const pointOption = {
        id: new Date().getTime(),
        radius: 5,
        fill: '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5,
        left: mouseOffsetX,
        top: mouseOffsetY,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      };
      const point = new fabric.Circle(pointOption);

      if (this.pointArray.length === 0) {
        // fill first point with red color
        point.set({
          fill: 'red',
        });
      }

      const linePoints = [mouseOffsetX, mouseOffsetY, mouseOffsetX, mouseOffsetY];
      const lineOption = {
        strokeWidth: 2,
        fill: '#999999',
        stroke: '#999999',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      };
      const line = new fabric.Line(linePoints, lineOption);
      line.class = 'line';

      if (this.activeShape) {
        const pos = canvas.getPointer(options.e);
        const points = this.activeShape.get('points');
        points.push({
          x: pos.x,
          y: pos.y,
        });
        const polygon = new fabric.Polygon(points, {
          id: 'tempPoly',
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#666666',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        });
        canvas.remove(this.activeShape);
        canvas.add(polygon);
        this.activeShape = polygon;
        canvas.renderAll();
      } else {
        const polyPoint = [
          {
            x: mouseOffsetX,
            y: mouseOffsetY,
          },
        ];
        const polygon = new fabric.Polygon(polyPoint, {
          id: 'tempPoly',
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#666666',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        });
        this.activeShape = polygon;
        canvas.add(polygon);
      }

      this.activeLine = line;
      this.pointArray.push(point);
      this.lineArray.push(line);

      canvas.add(line);
      canvas.add(point);
    },
    editPolygon: function () {
      this.clearMouseEvents();
      this.updateAllSelectable(true);
      const activeObject = this.canvas.getActiveObject() || this.activeShape;
      this.canvas.setActiveObject(activeObject);

      activeObject.edit = true;
      activeObject.objectCaching = false;

      const lastControl = activeObject.points.length - 1;
      activeObject.cornerStyle = 'circle';
      activeObject.controls = activeObject.points.reduce((acc, point, index) => {
        acc['p' + index] = new fabric.Control({
          positionHandler: polygonPositionHandler,
          actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
          actionName: 'modifyPolygon',
          pointIndex: index,
        });
        return acc;
      }, {});

      activeObject.hasBorders = false;

      this.canvas.requestRenderAll();
    },
    // eslint-disable-next-line no-unused-vars
    saveItem(isSvg) {
      const vpt = this.canvas.viewportTransform;
      const zoom = this.canvas.getZoom();
      this.canvas.setZoom(1);
      this.canvas.viewportTransform[4] = 0;
      this.canvas.viewportTransform[5] = 0;

      this.canvas.backgroundImage = null;
      this.canvas.renderAll();

      if (isSvg) {
        this.newItem.svg = this.canvas.toSVG();
      } else {
        let dataURL = this.canvas.toDataURL({
          multiplier: 1 / this.scaleFactor,
        });
        const link = document.createElement('a');
        dataURL.format = 'png';
        link.download = 'image.png';
        link.href = dataURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      this.canvas.setBackgroundImage(this.bgImage, this.canvas.renderAll.bind(this.canvas));
      this.canvas.setZoom(zoom);
      this.canvas.viewportTransform[4] = vpt[4];
      this.canvas.viewportTransform[5] = vpt[5];
    },
    updateAllSelectable(value) {
      this.canvas.getObjects().forEach((item) => {
        item.selectable = value;
      });
    },
    resetMouseEvents() {
      this.clearMouseEvents();

      const vm = this;
      this.canvas.on('mouse:move', this.mouseMove);
      this.canvas.on('mouse:down', this.mouseDown);
      this.canvas.on('mouse:up', function (opt) {
        this.setViewportTransform(this.viewportTransform);
        vm.mouseUp(opt);
      });
      this.canvas.on('mouse:out', this.mouseOut);
    },
    clearMouseEvents() {
      this.canvas.__eventListeners['mouse:move'] = [];
      this.canvas.__eventListeners['mouse:down'] = [];
      this.canvas.__eventListeners['mouse:up'] = [];
    },
    buttonClick: function (action, value) {
      this.updateAllSelectable(true);
      this.isPanning = false;
      this.canvas.isDrawingMode = false;
      this.action = action;

      this.canvas.remove(this.activeLine);
      this.updateAllSelectable(false);
      this.points = [];
      if (this.activeShape && this.activeShape.get('id') == 'tempPoly') {
        this.removePolygonItems();
        this.resetPolygon();
      }
      switch (action) {
        case 'zoom':
          this.updateZoom(value);
          break;
        case 'select':
          this.updateAllSelectable(true);
          break;
        case 'pan':
          this.isPanning = true;
          break;
        case 'draw':
          this.isDrawingMode = true;
          break;
        case 'shape':
          this.resetPolygon();
          this.selectedShape = value;
          break;
        case 'icon':
          this.selectedIcon = value;
          break;
        case 'delete':
          this.canvas.remove(this.canvas.getActiveObject());
          this.buttonClick('select');
          break;
        case 'clear':
          this.canvas.getObjects().forEach((item) => {
            this.canvas.remove(item);
          });
          break;
        case 'save':
          this.saveItem();
          break;
        case 'saveSvg':
          this.saveItem(true);
          break;
      }
      this.resetMouseEvents();
    },
    addUpdateItem: async function (isNew) {
      this.canvas.remove(this.activeLine);
      if (this.activeShape && this.activeShape.get('id') == 'tempPoly') {
        this.removePolygonItems();
        this.resetPolygon();
      }
      this.vuelidate$.$touch();
      this.saveItem(true);
      if (!this.vuelidate$.$invalid) {
        this.vuelidate$.$reset();
        var uniqueId = null;
        var title = '';
        if (isNew == true || !(this.itemIndex >= 0)) {
          this.newItem.key = this.getContextRef('maps').push().key;
          this.mapData.locations.push(this.newItem);
          if (this.newItem.isSite && !isNaN(this.newItem.uniqueId)) {
            uniqueId = String(Number(this.newItem.uniqueId) + 1);
            title = this.newItem.title.replace(this.newItem.uniqueId, uniqueId);
          }
        } else {
          this.newItem.key = this.newItem.key || this.getContextRef('maps').push().key;
          this.mapData.locations[this.itemIndex] = this.newItem;
        }
        this.setCurrentLocations();
        this.filterLocations();
        this.dbRef.set(this.mapData);
        this.resetLocation(title, uniqueId);
      }
    },
    resetLocation: function (title, uniqueId) {
      this.needsUpdate = true;
      this.newItem = {
        title: title || '',
        uniqueId: uniqueId || null,
        searchStrings: [],
        isSite: uniqueId ? true : false,
      };
      this.itemIndex = -1;
      this.resetUpdate();
    },
    removeItem(index) {
      this.mapData.locations.splice(index, 1);
      this.dbRef.set(this.mapData);
      this.setCurrentLocations();
    },
    editItem(index) {
      this.needsUpdate = true;
      this.itemIndex = index;
      this.newItem = { ...this.mapData.locations[index] };
      console.log('🚀 ~ editItem ~ this.newItem.searchStrings:', this.newItem.searchStrings);
      if (this.$refs.qrCode) this.$refs.qrCode.getDeepLinks();
      this.getActionParams();
      let vm = this;
      if (this.newItem.svg) {
        this.canvas.getObjects().forEach((item) => {
          vm.canvas.remove(item);
        });
        fabric.loadSVGFromString(this.newItem.svg, function (objects, options) {
          let shape = fabric.util.groupSVGElements(objects, options);
          //shape.set({left: 0, top: 0});
          if (shape.type == 'group') {
            shape.addWithUpdate();
          }
          vm.canvas.add(shape).renderAll();
        });
      }
      this.buttonClick('select');
      this.resetUpdate();
    },
    resetUpdate: function () {
      this.$nextTick(() => {
        this.needsUpdate = false;
      });
    },
    moveItems(evt, array) {
      const item = array.splice(evt.oldIndex, 1)[0];
      array.splice(evt.newIndex, 0, item);
    },
  },
};
</script>

<style scoped>
.dropdown-used button:first-child {
  color: #fff;
  background-color: #0c95de;
  border-color: #0b8dd2;
}
#map-edit canvas {
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: contain;
}
#map-edit .color-selector div {
  width: 20px;
  height: 20px;
}
#map-edit .color-selector .btn {
  padding: 0.25rem 0.25rem;
}
#map-edit .vc-chrome {
  width: 175px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
}

#map-edit #button-bar button.btn {
  padding: 5px;
}
#map-edit #button-bar label {
  margin: 0px;
}
#map-edit #button-bar .color-selector div {
  width: 15px;
  height: 15px;
}

#map-edit #button-bar .dropdown .btn:not(.dropdown-toggle) {
  padding: 0px 0px 0px 5px;
}

#left-column {
  z-index: 1;
  position: sticky;
  top: 0px;
  background-color: white;
}

div.two-column {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
}
.multiselect input {
  min-width: 0px;
}
.button-header {
  min-width: 1000px;
}
</style>
