<template>
  <div class="mb-3" id="file">
    <template>
      <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Search for User</legend>
    </template>
    <cool-select
      :value="value"
      :items="filteredItems"
      :placeholder="value ? '' : '-- Select User --'"
      item-value="value"
      item-text="title"
      :error-message="state == false ? 'User is required.' : null"
      :successful="state"
      @select="selectFile"
      disable-filtering-by-search
      @search="onFilter"
      @blur="onItemBlur">
      <!-- slot for each item in the menu -->
      <template #item="{ item }">
        <div>
          <strong>{{ item.title }}</strong> ({{ users[item.value].email }})
        </div>
        <div v-if="users[item.value].communityId"><strong>ID:</strong> {{ users[item.value].communityId }}</div>
      </template>
      <template #selection="{ item }">
        <div>
          <strong>{{ item.title }}</strong> ({{ users[item.value].email }})
        </div>
      </template>
    </cool-select>
    <div v-if="Object.keys(userRecord).length > 0">
      <div>User Selected: {{ userRecord.name || 'No Associated Name' }}</div>
      <div>
        Last Login: {{ userRecord.loggedIn.toLowerCase() !== 'not logged in' ? getFormattedDate(userRecord.loggedIn) : "User hasn't logged in." }}
      </div>
    </div>
  </div>
</template>

<script>
import { CoolSelect } from 'vue-cool-select';
import axios from 'axios';
export default {
  components: {
    CoolSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemList: [],
      filteredItems: [],
      users: {},
      userRecord: {},
    };
  },
  async created() {
    await this.getItemList();
  },
  methods: {
    getItemList: function () {
      const url = `${this.$apihostname}/api/users?campgroundId=${this.getCampgroundKey}&action=namedUsers&_=${Date.now()}`;
      axios.get(url).then((response) => {
        response.data.forEach((user) => {
          if (user.name.toLowerCase() !== 'anonymous user') {
            this.itemList.push({ title: user.name, value: user.uid });
            this.filteredItems.push({ title: user.name, value: user.uid });
            if (user.uid == this.value) {
              this.userRecord = user;
            }
            this.users[user.uid] = user;
          }
        });
        this.itemList.sort();
      });
    },
    onFilter: function (searchText) {
      if (searchText) {
        const lowerText = searchText.toLowerCase();
        this.filteredItems = Object.keys(this.users)
          .filter(
            (uid) =>
              this.users[uid].name.toLowerCase().includes(lowerText) ||
              this.users[uid].email.toLowerCase().includes(lowerText) ||
              (this.users[uid].communityId && this.users[uid].communityId.toLowerCase().includes(lowerText))
          )
          .map((uid) => {
            return { title: this.users[uid].name, value: this.users[uid].uid };
          });
      } else {
        this.filteredItems = this.itemList;
      }
    },
    onItemBlur: function () {
      this.filteredItems = this.itemList;
    },
    selectFile: function (value) {
      if (value.value !== this.value) {
        //Fix for clicking one disabled to another
        this.$emit('input', '-');
        this.$nextTick(() => {
          this.$emit('input', value.value);
        });
      } else {
        this.$emit('input', value.value);
      }
      this.userRecord = this.users[value.value];
    },
  },
};
</script>

<style lang="scss" scoped></style>
