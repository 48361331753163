<template>
  <div id="filter" class="collapse mt-2">
    <template v-if="textFilterVisible">
      <div class="row">
        <div class="col">
          <label class="mb-2">Filter Title & Description</label><br />
          <input v-model="filterText" type="text" placeholder="Filter Items" />
        </div>
        <div v-if="anyFilters()" class="col text-end">
          <button type="button" class="btn btn-secondary mt-4" @click="clearFilters()">Clear Filters</button>
        </div>
      </div>
    </template>
    <template v-if="dateFilterVisible">
      <div class="row">
        <div class="col">
          <DateTime
            ref="startDatePicker"
            label="Filter Start Date"
            :value="filterStartDate"
            format="MM/dd/yyyy"
            :min-date="null"
            :dateRequired="false"
            :timeRequired="false"
            :clearable="true"
            :timeRequested="false"
            :null-default="null"
            @update="(val) => (filterStartDate = val)" />
        </div>
        <div class="col">
          <DateTime
            ref="endDatePicker"
            label="Filter End Date"
            :value="filterEndDate"
            format="MM/dd/yyyy"
            :min-date="null"
            :dateRequired="false"
            :timeRequired="false"
            :clearable="true"
            :timeRequested="false"
            :null-default="null"
            @update="(val) => (filterEndDate = val)" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DateTime from '@/components/DateTime2';

export default {
  props: {
    bulkSelected: { type: Array, default: () => [] },
    textFilterVisible: { type: Boolean, default: true },
    dateFilterVisible: { type: Boolean, default: true },
  },
  components: { DateTime },
  data() {
    return {
      filterText: '',
      filterStartDate: null,
      filterEndDate: null,
      bulkCheckAll: false,
    };
  },
  watch: {
    filterText: {
      handler() {
        this.$emit('filterText', this.filterText);
      },
    },
    filterStartDate: {
      handler() {
        this.$emit('filterDate', this.filterStartDate, this.filterEndDate);
      },
    },
    filterEndDate: {
      handler() {
        this.$emit('filterDate', this.filterStartDate, this.filterEndDate);
      },
    },
  },
  methods: {
    deleteSelected() {
      this.$emit('delete');
    },
    clearFilters() {
      this.filterText = '';

      this.$refs.startDatePicker.clear();
      this.$refs.endDatePicker.clear();
    },
    anyFilters() {
      return this.filterText || this.filterStartDate || this.filterEndDate;
    },
  },
};
</script>

<style scoped></style>
