<template>
  <div>
    <editor v-model="item.value" background-color="white" textColor="black" tagName="DIV"></editor>
    <button class="btn m-1 btn-primary" type="submit" @click.prevent="updateText">Update</button>
    <button class="btn m-1 btn-secondary" type="button" @click.prevent="cancel">Cancel</button>
  </div>
</template>

<script>
import editor from '../Editor';
export default {
  props: ['name', 'value'],
  data() {
    return {
      item: {
        name: this.name,
        value: this.value,
      },
    };
  },
  methods: {
    updateText: function () {
      this.$emit('updatePrintout', this.item);
    },
    cancel: function () {
      this.item.value = this.value;
      this.updateText();
    },
  },
  components: {
    editor,
  },
};
</script>

<style lang="scss" scoped></style>
