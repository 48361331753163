<template>
  <div>
    <b-modal
      :id="`${id}-external-photos`"
      :ref="`${id}-external-photos`"
      title="Search Stock Photos"
      classes="amc-modal"
      size="xl"
      :buttons="[
        { title: 'Cancel', variant: 'btn-secondary', action: () => resetExternalPhotoModal() },
        { title: 'OK', variant: 'btn-accent', action: () => downloadImage(externalPhotos.selectedPhoto) },
      ]"
      @scroll-bottom="search(true)">
      <template #body>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search photos"
                  v-model="externalPhotos.query"
                  @keyup.enter="search"
                  style="margin-right: 12px; border-radius: 0.25rem" />
                <button class="btn btn-secondary" style="border-radius: 6px" @click="search">Search</button>
              </div>
            </div>
          </div>
          <div class="btn-group photo-options-container" role="group" aria-label="Photo options">
            <div v-for="item in externalPhotos.photos" :key="item.id" class="form-container m-1" style="width: 200px">
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  :value="item"
                  :id="`radio-${item.id}`"
                  autocomplete="off"
                  v-model="externalPhotos.selectedPhoto" />
                <label :for="`radio-${item.id}`">
                  <ExternalPhotoCredits :source="item.source" :url="item.url" class="always-top" />
                  <img
                    class="img-fluid rounded mx-auto d-block"
                    v-bind:style="externalPhotos.cssStyle"
                    :src="item.src"
                    :class="{ 'photo-options': true }" />
                </label>
              </div>
            </div>
          </div>
          <div class="row text-center" v-bind:style="{ 'padding-top': '16px' }" v-show="externalPhotos.isLoading">
            <div class="col">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer-left>
        <div class="container">
          <div class="row justify-content-between">
            <div class="col">
              <div class="row align-items-start">
                <div class="col-auto">Powered by:</div>
                <div class="col-auto px-0">
                  <a href="https://www.pexels.com/" target="_blank" style="text-decoration: none">
                    <ExternalPhotoCredits class="text-wrap" :showLabel="true" source="pexels" url="https://www.pexels.com/"></ExternalPhotoCredits>
                  </a>
                </div>
                <div class="col-auto px-2">
                  <a href="https://unsplash.com/" target="_blank" style="text-decoration: none">
                    <ExternalPhotoCredits class="text-wrap" :showLabel="true" source="unsplash" url="https://unsplash.com/"></ExternalPhotoCredits>
                  </a>
                </div>
                <div class="col-auto px-0">
                  <a href="https://pixabay.com/" target="_blank" style="text-decoration: none">
                    <ExternalPhotoCredits class="text-wrap" :showLabel="true" source="pixabay" url="https://pixabay.com/"></ExternalPhotoCredits>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BModal from '@/components/common/BModal.vue';
import { ExternalPhotosMixin } from '@/components/mixins/ExternalPhotosMixin';
import ExternalPhotoCredits from '@/components/ExternalPhotoCredits.vue';
import axios from 'axios';

export default {
  components: {
    ExternalPhotoCredits,
    BModal,
  },
  props: {
    id: { type: String, default: 'external-photos' },
    title: { type: String, default: '' },
    bucket: String,
  },
  emits: ['selected-image', 'set-attach', 'show-spinner'],
  data() {
    return {
      modal: null,
      externalPhotos: {
        cssStyle: {
          width: '150px',
          height: '179px',
          'object-fit': 'cover',
          'object-position': 'center',
        },
        eventAdded: false,
        isLoading: false,
        showModal: false,
        query: {
          type: String,
          default: this.title || '',
        },
        photos: [],
        page: 1,
        // default number of items per page
        per_page: 15,
        per_row: 5,
        selectedPhoto: null,
      },
    };
  },
  watch: {
    'externalPhotos.selectedPhoto': {
      handler() {
        console.log('SelectedPhoto' + JSON.stringify(this.externalPhotos.selectedPhoto));
      },
    },
    'externalPhotos.showModal': {
      handler() {
        console.log('showModal: ', this.externalPhotos.showModal);
      },
    },
  },
  mounted() {
    this.externalPhotos.query = this.title;
    this.modal = this.$refs[`${this.id}-external-photos`];
  },
  methods: {
    async search(next) {
      this.externalPhotos.isLoading = true;

      if (typeof next === 'boolean' && next) {
        this.externalPhotos.page += 1;
      } else {
        this.externalPhotos.page = 1;
        this.externalPhotos.photos = [];
      }

      const query = this.externalPhotos.query;
      const promises = [];
      promises.push(ExternalPhotosMixin.methods.searchPexels(query, this.externalPhotos.page));
      promises.push(ExternalPhotosMixin.methods.searchUnsplash(query, this.externalPhotos.page));
      promises.push(ExternalPhotosMixin.methods.searchPixabay(query, this.externalPhotos.page));
      Promise.all(promises).then((result) => {
        const [pexelsData, unsplashData, pixabayData] = result;
        this.externalPhotos.page = pexelsData.page;
        const pexelsMap =
          !pexelsData || !pexelsData.photos
            ? []
            : pexelsData.photos.map((photo, index) => {
                return {
                  source: 'pexels',
                  id: photo.id,
                  src: photo.src.medium,
                  selected: false,
                  downloadUrl: photo.src.large,
                  url: photo.url,
                  index,
                };
              });
        const unsplashMap =
          !unsplashData || !unsplashData.results
            ? []
            : unsplashData.results.map((photo, index) => {
                return {
                  source: 'unsplash',
                  id: photo.id,
                  src: photo.urls.small,
                  selected: false,
                  downloadUrl: photo.urls.regular,
                  url: photo.links.html,
                  index,
                };
              });
        const pixabayMap =
          !pixabayData || !pixabayData.hits
            ? []
            : pixabayData.hits.map((photo, index) => {
                return {
                  source: 'pixabay',
                  id: photo.id,
                  src: photo.previewURL,
                  selected: false,
                  downloadUrl: photo.webformatURL,
                  url: photo.pageURL,
                  index,
                };
              });
        const mergedMaps = [...pexelsMap, ...unsplashMap, ...pixabayMap];
        mergedMaps.sort((a, b) => {
          if (a.index == b.index) {
            return a.source > b.source ? -1 : 1;
          }
          return b.index - a.index;
        });
        this.externalPhotos.photos.push(...mergedMaps);
        this.externalPhotos.isLoading = false;
      });
    },
    async downloadImage(item) {
      this.$emit('show-spinner', true);
      this.$emit('set-attach');

      console.log('downloading', item);
      const drawable = item.downloadUrl;
      this.croppieAttachment = drawable;
      const url = `${this.$apihostname}/api/copyImage`;
      const response = await axios.post(url, { url: drawable, communityKey: this.communityKey || this.getCampgroundKey, bucket: this.bucket });
      console.log('response', response);
      const newDrawable = response.data.url;
      item.newDrawableUrl = newDrawable;
      item.name = this.getPhotoFileNameFromUrl(item.downloadUrl);

      this.$emit('show-spinner', false);
      this.$emit('selected-image', item, item.downloadUrl);
    },
    resetExternalPhotoModal() {
      this.externalPhotos.query = '';
      this.externalPhotos.selectedPhoto = null;
      this.externalPhotos.page = 1;
      this.externalPhotos.photos = [];
    },
    getPhotoFileNameFromUrl(url) {
      const urls = url.split('/');
      const tmpFileName = urls[urls.length - 1];
      let filename = tmpFileName.split('?')[0];

      if (filename.indexOf('.jpg') === -1 && filename.indexOf('.jpeg') === -1) {
        if (filename.endsWith('.')) {
          filename = filename.substring(0, filename.length - 2);
        }
        filename += '.jpg';
      }

      return filename;
    },

    show() {
      this.modal.show();
    },
  },
};
</script>

<style scoped>
.btn-check:checked,
.btn-check:active,
.btn-check:focus {
  box-shadow: none;
  outline: none;
  background-color: transparent;
  color: white;
  width: 20px;
  height: 20px;
}

.photo-credits {
  position: absolute;
  top: 0;
  left: 0;
}
.photo-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
