<template>
  <div class="two-column">
    <div id="center-column">
      <ul id="items">
        <li class="item" v-if="packageNames.android && packageNames.ios">
          <div>
            <div class="simple-title list-item" data-bs-toggle="collapse" data-bs-target="#vertical">
              Vertical Badges&nbsp;
              <i class="material-icons expand"></i>
            </div>
            <div class="collapse show mt-2" id="vertical">
              Add the code below to your website to display Google Play and Apple App Store badges vertically.
              <div style="white-space: initial">
                <div>
                  <a :href="getURL('android')">
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                      style="height: 60px" />
                  </a>
                </div>
                <div>
                  <a :href="getURL('ios')" style="display: inline; overflow: hidden; border-radius: 13px; width: 155px; height: 46px"
                    ><img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=155x46&amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad"
                      alt="Download on the App Store"
                      style="border-radius: 13px; width: 155px; height: 46px"
                  /></a>
                </div>
              </div>

              <pre>
<code>&lt;div&gt;
    &lt;a href='{{getURL('android')}}'&gt;
        &lt;img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style='height: 60px;'/&gt;
    &lt;/a&gt;                        
  &lt;/div&gt;
  &lt;div&gt;
    &lt;a href="{{getURL('ios')}}" style="display: inline; overflow: hidden; border-radius: 13px; width: 155px; height: 46px;"&gt;
        &lt;img
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=155x46&amp;amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad"
          alt="Download on the App Store"
          style="border-radius: 13px; width: 155px; height: 46px;"
        /&gt;
    &lt;/a&gt;
  &lt;/div&gt;
&lt;/div&gt;</code>
                </pre>
            </div>
          </div>
        </li>
        <li class="item" v-if="packageNames.android && packageNames.ios">
          <div>
            <div class="simple-title list-item" data-bs-toggle="collapse" data-bs-target="#horizontal">
              Horizontal Badges&nbsp;
              <i class="material-icons expand"></i>
            </div>
            <div class="collapse mt-2" id="horizontal">
              Add the code below to your website to display Google Play and Apple App Store badges horizontally.
              <div style="display: inline-block">
                <a :href="getURL('android')">
                  <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                    style="height: 60px" />
                </a>
                <a :href="getURL('ios')" style="display: inline; overflow: hidden; border-radius: 13px; width: 135px; height: 40px"
                  ><img
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=135x40&amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad"
                    alt="Download on the App Store"
                    style="border-radius: 13px; width: 135px; height: 40px"
                /></a>
                <!---a
                  :href="getURL('ios')"
                  style="display:inline-block;overflow:hidden;background:url(https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad) no-repeat;width:135px;height:40px;background-size:contain;vertical-align: top;margin-top: 10px;"
                /--->
              </div>
              <pre>
<code>&lt;div style='display:inline-block;'&gt;
  &lt;a href='{{getURL('android')}}'&gt;
      &lt;img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style='height: 60px;'/&gt;
  &lt;/a&gt;
  &lt;a href="{{getURL('ios')}}" style="display: inline; overflow: hidden; border-radius: 13px; width: 135px; height: 40px;"&gt;
        &lt;img
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=135x40&amp;amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad"
          alt="Download on the App Store"
          style="border-radius: 13px; width: 135px; height: 40px;"
        /&gt;
    &lt;/a&gt;
&lt;/div&gt;</code>
                </pre>
            </div>
          </div>
        </li>
        <li class="item" v-if="packageNames.oneLink">
          <div>
            <div class="simple-title list-item" data-bs-toggle="collapse" data-bs-target="#oneLink">
              One Link / QR Code&nbsp;
              <i class="material-icons expand"></i>
            </div>
            <div class="collapse mt-2" id="oneLink">
              When the link below is clicked or QR Code is scanned, it will open to the download screen for the correct store for the users phone. If
              they are opening on a PC, they will go to your website.
              <br /><br />
              These are great links to use for your social media posts.
              <br /><br />
              URL: <a :href="packageNames.oneLink">{{ packageNames.oneLink }}</a
              ><br /><br />
              <img v-if="packageNames.qrCode" :src="packageNames.qrCode" class="w-100" />
            </div>
          </div>
        </li>

        <li class="item" v-if="packageNames.phoneOnlyImage">
          <div>
            <div class="simple-title list-item" data-bs-toggle="collapse" data-bs-target="#phoneOnly">
              Screenshot in Frame Image&nbsp;
              <i class="material-icons expand"></i>
            </div>
            <div class="collapse mt-2 text-center" id="phoneOnly">
              <img :src="packageNames.phoneOnlyImage" class="w-75" />
            </div>
          </div>
        </li>

        <li class="item" v-if="packageNames.introducingMarketingImage || downloadURLs.introducing">
          <div>
            <div class="simple-title list-item" data-bs-toggle="collapse" data-bs-target="#introduction">
              Introduction Marketing Image&nbsp;
              <i class="material-icons expand"></i>
            </div>
            <div class="collapse mt-2" id="introduction">
              <img :src="packageNames.introducingMarketingImage || downloadURLs.introducing" class="marketing-piece-image" />
            </div>
          </div>
        </li>
        <li class="item" v-if="packageNames.ongoingMarketingImage || downloadURLs.downloaded">
          <div>
            <div class="simple-title list-item" data-bs-toggle="collapse" data-bs-target="#ongoing">
              Ongoing Marketing Image&nbsp;
              <i class="material-icons expand"></i>
            </div>
            <div class="collapse mt-2" id="ongoing">
              <img :src="packageNames.ongoingMarketingImage || downloadURLs.downloaded" class="marketing-piece-image" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div id="right-column" class="p-2">
      <Package v-if="action == `package`" @action="setAction" />
      <ButtonNavigation v-else buttonSection="actions" @action="setAction" />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import ButtonNavigation from './ButtonNavigation';
import Package from './forms/Package';

export default {
  name: 'Marketing',
  data() {
    return {
      action: '',
      packageNames: {
        ios: '',
        iosId: null,
        android: '',
        tableId: '',
        oneLink: '',
        qrCode: '',
        phoneOnlyImage: '',
        introducingMarketingImage: '',
        ongoingMarketingImage: '',
      },
      downloadURLs: {
        introducing: '',
        downloaded: '',
      },
    };
  },
  async created() {
    this.packageNames = {
      ...this.packageNames,
      ...((
        await firebase
          .database()
          .ref('resort-navigator')
          .child('campground-locations')
          .child(this.getCampgroundKey)
          .child('packageNames')
          .once('value')
      ).val() || (await firebase.database().ref('resort-navigator').child('location-data/system-data/config/packageNames').once('value')).val()),
    };

    if (!this.packageNames.introducingMarketingImage) {
      this.storageFileExists('introducing');
    }
    if (!this.packageNames.ongoingMarketingImage) {
      this.storageFileExists('downloaded');
    }
  },
  methods: {
    getURL: function (type) {
      if (type == 'android') {
        return `https://play.google.com/store/apps/details?id=${this.packageNames.android}`;
      } else if (type == 'ios') {
        return `https://itunes.apple.com/us/app/id${this.packageNames.iosId}`;
      }
    },
    storageFileExists: async function (type) {
      let storageRef = firebase.storage();
      storageRef =
        firebase.apps[0].options.projectId == 'camping-in-ontario'
          ? storageRef.ref('images').child('marketing')
          : storageRef.ref(this.getCampgroundKey).child('images');
      storageRef
        .child(`social_marketing_${type}.png`)
        .getDownloadURL()
        .then((url) => {
          this.downloadURLs[type] = url;
        })
        .catch(() => null);
    },
    setAction(action) {
      this.action = action;
    },
  },
  components: {
    ButtonNavigation,
    Package,
  },
};
</script>

<style>
pre {
  padding: 5px;
  width: 416px;
  margin-left: 13px;
  max-height: 600px;
  overflow: auto;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
    sans-serif;
  font-size: 13px;
  font-weight: normal;
  background-color: #eff0f1;
  color: black;
}
code {
  font-size: 9px;
  cursor: initial;
}
.marketing-piece-image {
  height: 442px;
  width: 442px;
}
</style>
