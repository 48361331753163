<template>
  <div>
    <div class="row">
      <div class="col-12 col-xxl-7">
        <div class="row">
          <div class="col-6 col-xxxl-8">
            <div class="mb-3">
              <label class="form-label">Social Media URL</label>
              <input type="text" class="form-control" v-model="item" :class="{ 'is-invalid': vuelidate$.item.$dirty && vuelidate$.item.$error }" />
              <Validation :validator="vuelidate$.item" feedback="URL is required." />
            </div>
          </div>
          <div class="col-6 col-xxxl-8">
            <div class="mb-3">
              <label class="form-label">Site</label>
              <select class="form-select" v-model="site" :class="{ 'is-invalid': vuelidate$.site.$dirty && vuelidate$.site.$error }">
                <option v-for="option in Object.values(siteOptions)" :key="option" :value="option.value">{{ option.text }}</option>
              </select>
              <Validation :validator="vuelidate$.site" feedback="Site is required." />
            </div>
          </div>
          <div class="col-12 col-xxxl-8">
            <div class="mb-3">
              <button class="btn btn-secondary" type="button" @click="addItem">{{ intButtonTitle }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <Sortable
          v-if="items && items.length > 0"
          :list="items"
          :item-key="
            (item) => {
              return item.url;
            }
          "
          tag="ul"
          class="editable-list"
          :options="sortableOptions"
          @end="(evt) => moveItems(evt, items)">
          <template #item="{ element, index }">
            <li class="single-row">
              <div>{{ siteOptions[element.site].text }}</div>
              <i class="item-remove material-icons" @click="removeItem(index)">delete</i>
            </li>
          </template>
        </Sortable>
      </div>
    </div>
  </div>
</template>

<script>
import { Sortable } from 'sortablejs-vue3';
import { required, url } from '@vuelidate/validators';
export default {
  components: {
    Sortable,
  },
  props: {
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    buttonTitle: {
      type: String,
    },
  },
  data() {
    return {
      item: '',
      site: '',
      siteOptions: {
        facebook: { text: 'Facebook', value: 'facebook' },
        instagram: { text: 'Instagram', value: 'instagram' },
        pinterest: { text: 'Pinterest', value: 'pinterest' },
        tiktok: { text: 'TikTok', value: 'tiktok' },
        twitter: { text: 'Twitter', value: 'twitter' },
        youtube: { text: 'YouTube', value: 'youtube' },
      },
      intButtonTitle: 'Add Item',
      intTitle: '',
    };
  },
  computed: {
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  validations() {
    return {
      item: {
        required,
        url,
      },
      site: {
        required,
      },
    };
  },
  created() {
    this.intButtonTitle = this.buttonTitle || this.intButtonTitle;
    this.intTitle = this.title;
    console.log('siteOptions', this.siteOptions);
    console.log('items', this.items);
  },
  methods: {
    addItem() {
      this.vuelidate$.$touch();
      if (!this.vuelidate$.$invalid) {
        this.vuelidate$.$reset();
        this.items.push({ site: this.site, url: this.item });
        this.item = '';
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    moveItems(evt, array) {
      const item = array.splice(evt.oldIndex, 1)[0];
      array.splice(evt.newIndex, 0, item);
    },
  },
};
</script>

<style scoped></style>
