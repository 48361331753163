import Vue from 'vue';
import { numeric, url, minLength } from '@vuelidate/validators';
export const NonDetailFormMixin = {
  props: ['item', 'dbContext', 'rightColumnKey', 'action'],
  data() {
    return {
      phoneNumber: null,
      locationInvalid: false,
      solidTypes: ['business-detail', 'searchablemap-detail', 'file', 'form', 'hunts', 'store-detail', 'website', 'booking', 'events'],
    };
  },
  validations() {
    return {
      newItem: {
        website: {
          url,
        },
      },
      phoneNumber: {
        numeric,
        minLength: minLength(10),
      },
    };
  },
  watch: {
    item: function () {
      this.changeNewItem(this.item.htmlDescription ? false : true);
    },
    'newItem.htmlDescription': function () {
      this.updateTextDescription();
    },
    'newItem.phone': function () {
      if (this.newItem.phone) {
        this.phoneNumber = this.newItem.phone.replace(/\D+/g, '');
      }
    },
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (!isJustShown && collapseId == 'accordion-image' && this.$refs.useImage) {
        this.$refs.useImage.saveCrop();
      }
    });
  },
  methods: {
    changeNewItem: async function (skipHTML) {
      if (this.item) {
        this.newItem = this.item;
        if (!this.item.htmlDescription && !skipHTML) {
          Vue.set(this.newItem, 'htmlDescription', this.item.description);
        }
      }
    },
    updateTextDescription: function () {
      if (this.$refs.quillDescription) {
        // const quillDescRef = this.$refs.quillDescription.$refs.editor;
        this.newItem.description = this.$refs.quillDescription
          .get()
          .replace(/\n\n\n/gu, '\n')
          .replace(/\n\n/gu, '\n')
          .trim();
        console.log('this.newItem.description', this.newItem.description);
      }
    },
    setLocationValid: function (isInvalid) {
      this.locationInvalid = isInvalid;
    },
    submitUpdate: async function () {
      if (this.$refs.useImage) {
        await this.$refs.useImage.saveCrop();
      }
      this.updateDB();
    },
    updateDB: async function (dbRef) {
      const currentDbRef = dbRef || this.getContextRef();
      this.vuelidate$.$touch();
      this.$nextTick(() => {
        let newKey = null;
        if (!this.vuelidate$.$invalid && !this.locationInvalid) {
          if (this.action == 'edit') {
            currentDbRef.off();
            currentDbRef.child(this.rightColumnKey).update(this.newItem);
          } else {
            currentDbRef.off();
            newKey = currentDbRef.push(this.newItem).key;
          }
          this.postNewKey(newKey);
        }
      });
    },
    postNewKey: function (newKey) {
      this.exitEdit('submit', newKey);
    },
    cancelUpdate: async function () {
      await this.resetItemFromDB();
      this.exitEdit('cancel', null);
    },
    resetItemFromDB: async function () {},
    exitEdit: function (action, newKey) {
      this.$emit('action', action, newKey);
    },
  },
};
