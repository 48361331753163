<template>
  <div class="p-2 form-container">
    <form @submit.prevent="submitUpdate" novalidate>
      <b-accordion id="package" :headings="['Basic Information', 'Images']">
        <template #body="{ heading }">
          <div v-if="heading === 'Basic Information'">
            <div class="mb-3">
              <label class="form-label">iOS Package</label>
              <input class="form-control" v-model="newItem.ios" trim />
            </div>
            <div class="mb-3">
              <label class="form-label">iOS ID</label>
              <input class="form-control" v-model.number="newItem.iosId" trim />
            </div>
            <div class="mb-3">
              <label class="form-label">Android Package</label>
              <input class="form-control" v-model="newItem.android" trim />
            </div>
            <div class="mb-3">
              <label class="form-label">Big Query Table Id</label>
              <input class="form-control" v-model="newItem.tableId" trim />
            </div>
            <div class="mb-3">
              <label class="form-label">OneLink URL</label>
              <input
                class="form-control"
                v-model="newItem.oneLink"
                :class="{ 'is-invalid': newItem.oneLink && vuelidate$.newItem.oneLink.$dirty && vuelidate$.newItem.oneLink.$error }"
                trim />
              <Validation :validator="vuelidate$.newItem.oneLink" map="url" />
            </div>
          </div>

          <div v-if="heading === 'Images'">
            <Attachment
              id="qrCode"
              label="QR Code Image"
              :width="150"
              :height="150"
              imageBucket="marketing"
              :attachments="attachmentQRCode"
              acceptedFiles="image/png,image/jpeg"
              ref="qrCode" />
            <Attachment
              id="phoneOnlyImage"
              label="Phone Image"
              :width="150"
              :height="150"
              imageBucket="marketing"
              :attachments="attachmentPhone"
              acceptedFiles="image/png,image/jpeg"
              ref="phoneOnlyImage" />
            <Attachment
              id="introducingMarketingImage"
              label="Introducing Marketing Image"
              :width="150"
              :height="150"
              imageBucket="marketing"
              :attachments="attachmentIntroducing"
              acceptedFiles="image/png,image/jpeg"
              ref="introducingMarketingImage" />
            <Attachment
              id="ongoingMarketingImage"
              label="Ongoing Marketing Image"
              :width="150"
              :height="150"
              imageBucket="marketing"
              :attachments="attachmentOngoing"
              acceptedFiles="image/png,image/jpeg"
              ref="ongoingMarketingImage" />
          </div>
        </template>
      </b-accordion>

      <Validation :validator="vuelidate$.newItem" map="form" />

      <button class="btn m-1 btn-primary" type="submit">Submit</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Attachment from '../Attachment';
import { url } from '@vuelidate/validators';
import BAccordion from '../common/BAccordion.vue';

export default {
  data() {
    return {
      dbRef: null,
      newItem: {
        ios: '',
        iosId: null,
        android: '',
        tableId: '',
        oneLink: '',
        qrCode: '',
        phoneOnlyImage: '',
        introducingMarketingImage: '',
        ongoingMarketingImage: '',
      },
      attachmentQRCode: [],
      attachmentPhone: [],
      attachmentIntroducing: [],
      attachmentOngoing: [],
    };
  },
  validations() {
    return {
      newItem: {
        oneLink: {
          url,
        },
      },
    };
  },
  watch: {
    attachmentQRCode: {
      handler: function (val) {
        this.newItem.qrCode = val[0] && val[0].downloadURL ? val[0].downloadURL : this.newItem.qrCode || null;
      },
      deep: true,
    },
    attachmentPhone: {
      handler: function (val) {
        this.newItem.phoneOnlyImage = val[0] && val[0].downloadURL ? val[0].downloadURL : this.newItem.phoneOnlyImage || null;
      },
      deep: true,
    },
    attachmentIntroducing: {
      handler: function (val) {
        this.newItem.introducingMarketingImage = val[0] && val[0].downloadURL ? val[0].downloadURL : this.newItem.introducingMarketingImage || null;
      },
      deep: true,
    },
    attachmentOngoing: {
      handler: function (val) {
        this.newItem.ongoingMarketingImage = val[0] && val[0].downloadURL ? val[0].downloadURL : this.newItem.ongoingMarketingImage || null;
      },
      deep: true,
    },
  },
  created: async function () {
    this.dbRef = firebase.database().ref('resort-navigator').child('campground-locations').child(this.getCampgroundKey).child('packageNames');
    this.newItem = (await this.dbRef.once('value')).val() || this.newItem;
    this.attachmentQRCode = this.newItem.qrCode ? [this.newItem.qrCode] : [];
    this.attachmentPhone = this.newItem.phoneOnlyImage ? [this.newItem.phoneOnlyImage] : [];
    this.attachmentIntroducing = this.newItem.introducingMarketingImage ? [this.newItem.introducingMarketingImage] : [];
    this.attachmentOngoing = this.newItem.ongoingMarketingImage ? [this.newItem.ongoingMarketingImage] : [];
  },
  methods: {
    updatePhoneOnlyImage: function (imageURL) {
      this.newItem.phoneOnlyImage = imageURL;
    },
    updateQRCode: function (imageURL) {
      this.newItem.qrCode = imageURL;
    },
    updateIntroducingMarketingImage: function (imageURL) {
      this.newItem.introducingMarketingImage = imageURL;
    },
    updateOngoingMarketingImage: function (imageURL) {
      this.newItem.ongoingMarketingImage = imageURL;
    },
    submitUpdate: async function () {
      this.vuelidate$.$touch();
      this.$nextTick(async () => {
        if (!this.vuelidate$.$invalid) {
          console.log('🚀 ~ file: Package.vue ~ line 177 ~ this.$nextTick ~ this.newItem', this.newItem);
          this.dbRef.update(this.newItem);
          this.cancelUpdate();
        }
      });
    },
    cancelUpdate: function () {
      this.$emit('action', '');
    },
  },
  components: {
    Attachment,
    BAccordion,
  },
};
</script>

<style lang="scss" scoped></style>
