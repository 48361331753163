<template>
  <b-modal
    :id="id"
    ref="modal"
    title="Select Menu Type"
    size="lg"
    classes="amc-modal"
    :buttons="[
      { title: 'Cancel', variant: 'btn-secondary' },
      //{ title: 'OK', variant: 'btn-accent' },
    ]">
    <template #body>
      <div class="row">
        <div class="col">
          <div class="input-group mb-1">
            <input v-model="filterText" type="text" class="form-control" placeholder="Filter" @keyup="filterButtons" />
          </div>
        </div>
      </div>
      <div v-for="(tray, index) in filteredMenuTypes" :id="tray.id" :key="index" class="buttontray-container m-1">
        <h6 class="traytitle">{{ tray.trayName }}</h6>

        <div class="buttontray" v-if="tray.buttons.length > 0">
          <DetailButton
            v-for="trayButton in tray.buttons"
            :key="trayButton.id"
            title="trayButton.shortDescription"
            :button-text="trayButton.buttonName"
            :button-icon="trayButton.iconName"
            :button-color="trayButton.color || '#1da9f3'"
            @click.native="clickButton(trayButton)" />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import BModal from '@/components/common/BModal.vue';
import DetailButton from '@/components/DetailButton.vue';

export default {
  components: { BModal, DetailButton },
  props: {
    mainMenuTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    id: {
      type: String,
      default: 'menu-type-modal',
    },
  },
  data() {
    return {
      filterText: '',
      filteredMenuTypes: this.mainMenuTypes,
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    clickButton(trayButton) {
      this.$emit('updateType', trayButton.id);
      this.$refs.modal.hide();
    },
    filterButtons() {
      const filterText = this.filterText.toLowerCase();
      this.filteredMenuTypes = this.mainMenuTypes.reduce((acc, tray) => {
        const filteredTray = { ...tray };
        const trayTitle = filteredTray.trayName.toLowerCase();
        filteredTray.buttons = filteredTray.buttons.filter((button) => {
          const buttonText = button.buttonName.toLowerCase();
          return buttonText.indexOf(filterText) > -1;
        });
        if (trayTitle.indexOf(filterText) > -1 || filteredTray.buttons.length > 0) {
          acc.push(filteredTray);
        }
        return acc;
      }, []);
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.buttontray {
  background-color: inherit;
}

h6.traytitle {
  background-color: #1f3449;
  text-transform: uppercase;
  padding: 4px;
}

.buttontray {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(88px, 1fr));
  grid-gap: 4px;
  background-color: inherit;
}

.detail-button {
  padding: 4px;
  color: white;
  min-height: 90px;
  min-width: 82px;
  /* max-width: 92px; */
  text-align: center;
  cursor: pointer;
  line-height: 18px;
  align-content: start;
}
</style>
