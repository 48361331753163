<template>
  <div class="p-2 form-container">
    <form @submit.prevent="submitUpdate" novalidate>
      <b-accordion
        id="community-setup"
        :headings="['Community Information', 'App Design', 'Social Media', 'QR Code']"
        :hidden="[!newItem.isBusinessOnly ? '' : 'Social Media', !newItem.isBusinessOnly && action !== `add-community` ? '' : 'QR Code']"
        @clicked="
          (heading) => {
            if (heading === 'QR Code') $refs.qrCode.getDeepLinks();
            if (heading === 'App Design' && $refs.useImage) $refs.useImage.refreshCropper();
          }
        ">
        <template #body="{ heading }">
          <div v-if="heading === 'Community Information'">
            <LocationSelector
              :dataObject="newItem"
              type="community"
              @updateLocation="updateLocation"
              :submitted="vuelidate$.$dirty"
              @locationInvalid="setLocationValid" />
            <div class="mb-3">
              <label class="form-label">Phone Number</label>
              <input
                type="text"
                class="form-control"
                v-model="newItem.phone"
                :class="{ 'is-invalid': vuelidate$.phoneNumber.$dirty && vuelidate$.phoneNumber.$error }"
                trim />
              <Validation :validator="vuelidate$.phoneNumber" map="phone" />
            </div>

            <div class="mb-3">
              <label class="form-label">Website URL</label>
              <input
                type="text"
                class="form-control"
                v-model="newItem.website"
                :class="{ 'is-invalid': newItem.website && vuelidate$.newItem.website.$dirty && vuelidate$.newItem.website.$error }"
                trim />
              <Validation :validator="vuelidate$.newItem.website" map="url" />
            </div>

            <div class="mb-3">
              <label class="form-label">Timezone</label>
              <select
                class="form-select"
                v-model="newItem.timeZone"
                :class="{ 'is-invalid': vuelidate$.newItem.timeZone.$dirty && vuelidate$.newItem.timeZone.$error }">
                <option value="null" disabled hidden>--- Select One ---</option>
                <option v-for="option in timeZones" :value="option" :key="option">{{ option }}</option>
              </select>
              <Validation :validator="vuelidate$.newItem.timeZone" feedback="Timezone is required." />
            </div>

            <div class="mb-3" v-if="isResortAdmin">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="newItem.listed" id="listedCheckbox" />
                <label class="form-check-label" for="listedCheckbox">Is Community in Main Listing</label>
              </div>
            </div>
            <div class="mb-3" v-if="isResortAdmin && newItem.listed">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="newItem.isBusinessOnly" id="businessCheckbox" />
                <label class="form-check-label" for="businessCheckbox">Is Business Listing Only</label>
              </div>
            </div>
            <div class="mb-3" v-if="isResortAdmin && newItem.listed">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="newItem.inProgress" id="progressCheckbox" />
                <label class="form-check-label" for="progressCheckbox">In Progress For Full App</label>
              </div>
            </div>
            <div class="mb-3" v-if="newItem.isBusinessOnly">
              <label class="form-label">Description</label>
              <editor v-model="newItem.description"></editor>
            </div>

            <div class="mb-3" v-if="newItem.isBusinessOnly">
              <label class="form-label">Reservation URL</label>
              <input
                type="text"
                class="form-control"
                v-model="newItem.reservationURL"
                :class="{
                  'is-invalid': newItem.reservationURL && vuelidate$.newItem.reservationURL.$dirty && vuelidate$.newItem.reservationURL.$error,
                }"
                trim />
              <Validation :validator="vuelidate$.newItem.reservationURL" map="url" />
            </div>
          </div>

          <div v-if="heading === 'App Design'">
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">Background Color</label>
                  <div class="color-selector">
                    <label class="btn btn-light" @click.stop="editBackground = true">
                      <div :style="getUpdatedBackgroundColor">&nbsp;</div>
                    </label>
                  </div>
                  <chrome-picker
                    id="editBackground"
                    :data-edit="editBackground"
                    v-show="editBackground"
                    v-model="colorBackground"
                    v-click-outside="onColorClose" />
                  <small class="form-text text-muted">Color used for app background</small>
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">Primary Color</label>
                  <div class="color-selector">
                    <label class="btn btn-light" @click.stop="editPrimary = true">
                      <div :style="`background-color:${newItem.colorPrimary}`">&nbsp;</div>
                    </label>
                  </div>
                  <chrome-picker id="editPrimary" v-show="editPrimary" v-model="colorPrimary" v-click-outside="onColorClose" />
                  <small class="form-text text-muted">Color used for header bar</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">Accent Color</label>
                  <div class="color-selector">
                    <label class="btn btn-light" @click.stop="editAccent = true">
                      <div :style="`background-color:${newItem.colorAccent}`">&nbsp;</div>
                    </label>
                  </div>
                  <chrome-picker id="editAccent" v-show="editAccent" v-model="colorAccent" v-click-outside="onColorClose" />
                  <small class="form-text text-muted">Color used for tab selectors and buttons</small>
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">Text Highlight Color</label>
                  <div class="color-selector">
                    <label class="btn btn-light" @click.stop="editHighlightedText = true">
                      <div :style="`background-color:${newItem.colorHighlightedText}`">&nbsp;</div>
                    </label>
                  </div>
                  <chrome-picker
                    id="editHighlightedText"
                    v-show="editHighlightedText"
                    v-model="colorHighlightedText"
                    v-click-outside="onColorClose" />
                  <small class="form-text text-muted">Color used for URL links and unread messages</small>
                </div>
              </div>
            </div>
            <!-- <div class="mb-3">
              <label class="form-label" for="icon">Select Icon Style</label>
              <cool-select
                v-model="newItem.iconSet"
                :items="iconSets"
                :placeholder="newItem.iconSet ? '' : 'Select Icon'"
                disable-search
                :inputStyles="getUpdatedBackgroundColor">
                <template #item="{ item: icon }">
                  <div :style="getUpdatedBackgroundColor">
                    <img :src="`/iconsets/${icon}/${icon}_calendar.png`" class="select-icon p-2" />
                  </div>
                </template>
                <template #selection="{ item: icon }">
                  <div :style="getUpdatedBackgroundColor">
                    <img :src="`/iconsets/${icon}/${icon}_calendar.png`" class="select-icon p-1" />
                  </div>
                </template>
              </cool-select>
            </div> -->
            <div v-if="newItem.iconSet.startsWith('svg')">
              <IconSet
                v-model="newItem.iconSet"
                :colorIconShape="iconShapeColorType == 'random' ? '#173972' : newItem.colorIconShape"
                :colorIconBackground="iconBackgroundColorType == 'random' ? '#8F8F10' : newItem.colorIconBackground"
                :icon="baseIcon" />
              <div class="row" v-if="newItem.iconSet.startsWith('svg')">
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Icon Color</label>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="iconShapeColorType"
                        name="icon-shape-radio"
                        value="transparent"
                        id="transparentRadio" />
                      <label class="form-check-label" for="transparentRadio">
                        {{ newItem.iconSet !== 'svg_plain' ? 'Background Color' : 'White' }}
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="iconShapeColorType"
                        name="icon-shape-radio"
                        value="random"
                        id="randomRadio" />
                      <label class="form-check-label" for="randomRadio">Random Color</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="iconShapeColorType"
                        name="icon-shape-radio"
                        value="specific"
                        id="specificRadio" />
                      <label class="form-check-label" for="specificRadio">Specific/Selected Color</label>
                      <div class="color-selector" v-if="iconShapeColorType == 'specific'">
                        <label class="btn btn-light" @click.stop="editIcon = true">
                          <div :style="`background-color:${newItem.colorIconShape}`">&nbsp;</div>
                        </label>
                      </div>
                      <chrome-picker id="editIcon" v-show="editIcon" v-model="colorIconShape" v-click-outside="onColorClose" />
                    </div>
                    <small class="form-text text-muted">Color used for icon</small>
                  </div>
                </div>
                <div class="col" v-if="newItem.iconSet !== 'svg_plain'">
                  <div class="mb-3">
                    <label class="form-label">Icon Background Color</label>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="iconBackgroundColorType"
                        name="icon-background-radio"
                        value="white"
                        id="whiteRadio" />
                      <label class="form-check-label" for="whiteRadio">White</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="iconBackgroundColorType"
                        name="icon-background-radio"
                        value="random"
                        id="randomRadio" />
                      <label class="form-check-label" for="randomRadio">Random Color</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="iconBackgroundColorType"
                        name="icon-background-radio"
                        value="specific"
                        id="specificRadio" />
                      <label class="form-check-label" for="specificRadio">Specific/Selected Color</label>
                      <div class="color-selector" v-if="iconBackgroundColorType == 'specific'">
                        <label class="btn btn-light" @click.stop="editIconBackground = true">
                          <div :style="`background-color:${newItem.colorIconBackground}`">&nbsp;</div>
                        </label>
                      </div>
                      <chrome-picker
                        id="editIconBackground"
                        v-show="editIconBackground"
                        v-model="colorIconBackground"
                        v-click-outside="onColorClose" />
                    </div>
                    <small class="form-text text-muted">Color used for icon's background color</small>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="newItem.listed || (getCampgroundKey && getCampgroundKey.startsWith('-'))">
              <div v-if="newItem.placeId && (!photos || photos.length == 0)">
                <button id="btn-crop" class="btn m-1 btn-primary" type="button" @click.prevent="getGooglePhotos()">
                  Select Image From Google Places
                </button>
              </div>
              <Attachment
                id="header"
                label="Home Screen Image"
                :title="newItem.title"
                :attachments="[newItem.drawable]"
                :useCroppie="true"
                @updateCroppieImage="updateHeaderImage"
                ref="useImage"
                :googlePhotos="photos" />
              <Validation :validator="vuelidate$.newItem.drawable" feedback="Home screen image is required." />

              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="display-name-checkbox" v-model="newItem.displayName" />
                <label class="form-check-label" for="display-name-checkbox">Display name on home screen</label>
              </div>
            </template>
            <div v-else-if="newItem.drawable">
              Header image is no longer completed for standard apps in the Community Setup. Please click the home button then click the "Header"
              button on the right of the screen
            </div>
            <!---Attachment
          v-show="!newItem.isBusinessOnly"
          id="drawer"
          label="Drawer Logo Image"
          :width="150"
          :height="150"
          :attachments="[newItem.drawerLogo]"
          :useCroppie="true"
          @updateCroppieImage="updateDrawerImage"
          ref="useImage2"
        /--->
          </div>

          <div v-if="heading === 'Social Media'">
            <SocialMediaList :items="newItem.socialMedia" />
          </div>

          <div v-if="heading === 'QR Code'">
            <QRCode ref="qrCode" :title="`${newItem.title} QR Code`" action="openCommunity" :actionKey="getCampgroundKey" />
          </div>
        </template>
      </b-accordion>

      <Validation :validator="vuelidate$.newItem" map="form" />

      <button class="btn m-1 btn-primary" type="submit">Submit</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>

    <div hide ref="photos" />
    <b-modal
      id="update-colors-modal"
      ref="updateColorsModal"
      size="lg"
      title="Select Menu Type"
      :buttons="[
        { title: 'Cancel', variant: 'btn-secondary' },
        { title: 'OK', variant: 'btn-accent', action: () => completeColorChange() },
      ]">
      <template #body>
        <ul :style="getBackgroundColorStyle" class="grid-container">
          <div v-for="(icon, itemKey) in iconsByContexts" :key="icon.objectPath">
            <div
              v-if="itemKey == 0 || iconsByContexts[itemKey].objectTitle !== iconsByContexts[itemKey - 1].objectTitle"
              class="menu-divider"
              :key="`${icon.objectPath}-title`">
              {{ iconsByContexts[itemKey].objectTitle }}
            </div>
            <li class="grid-icon-clickable text-center">
              <GridMenuItems
                :item="icon"
                :itemId="itemKey"
                :hideEditButtons="true"
                :iconSet="icon.iconData ? icon.iconData.iconSet : null"
                :updatedColorIconShape="icon.iconData ? icon.iconData.colorIconShape : null"
                :updatedColorIconBackground="icon.iconData ? icon.iconData.colorIconBackground : null" />
              <button class="btn m-1 btn-secondary" @click.prevent="updateRandomIconColorsForIndex(icon, itemKey)">Generate New Color(s)</button>
            </li>
          </div>
        </ul>
      </template>
    </b-modal>
    <b-modal
      id="new-icon-modal"
      ref="newIconModal"
      size="md"
      title="Convert Icons"
      :buttons="[
        { title: 'Cancel', variant: 'btn-secondary' },
        { title: 'Continue', variant: 'btn-accent', action: () => updateNewIcons() },
      ]">
      <template #body>
        We will convert your current icons to their closest match. Switching to new icons is irreversible. Do you want to continue?
      </template>
    </b-modal>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import editor from '../Editor';
import LocationSelector from '../LocationSelector';
import SocialMediaList from '../listitems/SocialMediaList';
import Attachment from '../Attachment';
import tzLookup from 'tz-lookup';
import IconSet from '../inputfields/IconSet';
import GridMenuItems from '../../components/listitems/GridMenuItems.vue';
import BModal from '../common/BModal.vue';
import BAccordion from '../common/BAccordion.vue';

import axios from 'axios';
import moment from 'moment-timezone';
import { Chrome } from 'vue-color';
import Vue from 'vue';
import { required, numeric, url, minLength, requiredIf } from '@vuelidate/validators';
import QRCode from '../inputfields/QRCode';
import { onBeforeRouteLeave } from 'vue-router';

export default {
  components: {
    editor,
    LocationSelector,
    'chrome-picker': Chrome,
    Attachment,
    SocialMediaList,
    IconSet,
    GridMenuItems,
    QRCode,
    BModal,
    BAccordion,
  },
  setup() {
    onBeforeRouteLeave((to, from) => {
      if (!window.localStorage.getItem('CAMPGROUND_KEY') && window.localStorage.getItem('PREV_CAMPGROUND_KEY')) {
        window.localStorage.setItem('CAMPGROUND_KEY', window.localStorage.getItem('PREV_CAMPGROUND_KEY'));
      }
    });
  },
  data() {
    const americaTimeZones = moment.tz.names().filter((x) => x.startsWith(`America`));

    return {
      action: '',
      isDev: process.env.NODE_ENV == 'development',
      dbUpdate: {},
      iconsByContexts: [],
      updateColors: false,
      newItem: {
        active: true,
        description: '',
        displayName: false,
        name: null,
        title: null,
        address: null,
        city: null,
        latitude: null,
        longitude: null,
        phone: null,
        placeId: null,
        state: null,
        website: null,
        zip: null,
        timeZone: null,
        isBusinessOnly: false,
        listed: false,
        colorBackground: '#ffffff',
        colorPrimary: '#ffffff',
        colorAccent: '#ffffff',
        colorHighlightedText: '#ffffff',
        colorIconShape: '',
        colorIconBackground: '',
        colorIconShapeRandom: false,
        colorIconBackgroundRandom: false,
        drawable: null,
        socialMedia: [],
        regulationsURL: '',
        reservationURL: null,
        iconSet: '',
      },
      iconShapeColorType: 'transparent',
      iconBackgroundColorType: 'white',
      //timeZones: ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"],
      timeZones: americaTimeZones,
      isResortAdmin: false,
      editBackground: false,
      editPrimary: false,
      editAccent: false,
      editHighlightedText: false,
      editIcon: false,
      editIconBackground: false,
      colorBackground: '#ffffff',
      colorPrimary: '#ffffff',
      colorAccent: '#ffffff',
      colorHighlightedText: '#ffffff',
      colorIconShape: '',
      colorIconBackground: '',
      iconSets: ['handdrawn', 'parks', 'parks_color', 'round', 'round_color', 'traditional', 'vintage'],
      phoneNumber: null,
      locationInvalid: false,
      photos: [],
      colorIconShapeRandomOrig: false,
      colorIconBackgroundRandomOrig: false,
      baseIcon: {
        search: {
          terms: [],
        },
        styles: ['solid'],
        label: 'Calendar',
        svg: {
          solid: {
            raw: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"/></svg>',
          },
        },
      },
    };
  },
  validations() {
    return {
      newItem: {
        website: {
          url,
        },
        reservationURL: {
          url,
        },
        timeZone: {
          required,
        },
        drawable: {
          required: requiredIf(function () {
            return !this.newItem.isBusinessOnly && this.newItem.listed;
          }),
        },
      },
      phoneNumber: {
        numeric,
        minLength: minLength(10),
      },
    };
  },
  watch: {
    // useNewIcons: function (value) {
    //   if (!value || this.action === `add-community`) return;
    //   this.$refs.newIconModal.show();
    // },
    iconShapeColorType: function () {
      this.newItem.colorIconShape = this.iconShapeColorType == 'specific' ? this.newItem.colorIconShape : null;
      this.newItem.colorIconShapeRandom = this.iconShapeColorType == 'random';
      if (this.iconShapeColorType == 'transparent') {
        this.newItem.colorIconShape = this.newItem.iconSet == 'svg_plain' ? '#ffffff' : this.getBackgroundColor;
      }
    },
    iconBackgroundColorType: function () {
      this.newItem.colorIconBackground = this.iconBackgroundColorType == 'specific' ? this.newItem.colorIconBackground : null;
      this.newItem.colorIconBackgroundRandom = this.iconBackgroundColorType == 'random';
      if (this.iconBackgroundColorType == 'white') {
        this.newItem.colorIconBackground = '#ffffff';
      }
    },
    colorBackground: function () {
      this.newItem.colorBackground = this.colorBackground.hex || this.newItem.colorBackground;
    },
    colorPrimary: function () {
      this.newItem.colorPrimary = this.colorPrimary.hex || this.newItem.colorPrimary;
    },
    colorAccent: function () {
      this.newItem.colorAccent = this.colorAccent.hex || this.newItem.colorAccent;
    },
    colorHighlightedText: function () {
      this.newItem.colorHighlightedText = this.colorHighlightedText.hex || this.newItem.colorHighlightedText;
    },
    colorIconBackground: function () {
      this.newItem.colorIconBackground = this.colorIconBackground.hex || this.newItem.colorIconBackground;
    },
    colorIconShape: function () {
      this.newItem.colorIconShape = this.colorIconShape.hex || this.newItem.colorIconShape;
    },
    newItem: {
      handler: function () {
        this.$emit('update-style', this.newItem);
      },
      deep: true,
    },
    'newItem.phone': function () {
      if (this.newItem.phone) {
        this.phoneNumber = this.newItem.phone.replace(/\D+/g, '');
      }
    },
    'newItem.timeZone': function () {
      if (this.newItem.timeZone === 'null') this.newItem.timeZone = null;
      console.log('timeZone', this.newItem.timeZone, typeof this.newItem.timeZone);
    },
  },
  created: async function () {
    this.action = this.$route.params.action;
    console.log('this.action', this.action);
    if (this.action === `add-community`) {
      // this.useNewIcons = true;
      this.newItem.iconSet = 'svg_round';
    }
    this.isResortAdmin =
      window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
    if (this.action == `community-setup`) {
      const dbRef = firebase.database().ref('resort-navigator').child('campground-locations').child(this.getCampgroundKey);
      const itemSS = await dbRef.once('value');
      this.newItem = { ...this.newItem, ...itemSS.val() };
      this.newItem.title = this.newItem.name;
      if (this.newItem.colorIconShapeRandom) {
        this.iconShapeColorType = 'random';
      } else if (this.newItem.colorIconShape) {
        this.iconShapeColorType = 'specific';
      }
      if (this.newItem.colorIconBackgroundRandom) {
        this.iconBackgroundColorType = 'random';
      } else if (this.newItem.colorIconBackground) {
        this.iconBackgroundColorType = 'specific';
      }
      this.colorIconShapeRandomOrig = this.newItem.colorIconShapeRandom;
      this.colorIconBackgroundRandomOrig = this.newItem.colorIconBackgroundRandom;
      this.newItem.colorIconShape = this.newItem.colorIconShape || this.getBackgroundColor;
      this.newItem.colorIconBackground = this.newItem.colorIconBackground || '#ffffff';
    } else {
      this.newItem.colorBackground = this.getBackgroundColor;
      this.newItem.colorPrimary = this.getPrimaryColor;
      this.newItem.colorAccent = this.getAccentColor;
      this.newItem.colorHighlightedText = this.getHighlightedTextColor;
      this.newItem.colorIconShape = this.colorIconShape || this.getBackgroundColor;
      this.newItem.colorIconBackground = this.colorIconBackground || '#ffffff';
    }
    this.colorBackground = this.newItem.colorBackground || this.colorBackground;
    this.colorPrimary = this.newItem.colorPrimary || this.colorPrimary;
    this.colorAccent = this.newItem.colorAccent || this.colorAccent;
    this.colorHighlightedText = this.newItem.colorHighlightedText || this.colorAccent;
    this.colorIconShape = this.newItem.colorIconShape || this.colorIconShape || this.getBackgroundColor;
    this.colorIconBackground = this.newItem.colorIconBackground || this.colorIconBackground || '#ffffff';
  },
  computed: {
    getUpdatedBackgroundColor: function () {
      return { 'background-color': this.newItem.colorBackground };
    },
  },
  methods: {
    updateNewIcons(value) {
      try {
        if (value) {
          const useRandomColors = this.newItem.iconSet.includes('_color');
          const priorIconSet = this.newItem.iconSet;
          this.newItem.iconSet = 'svg_' + (this.newItem.iconSet == 'handdrawn' ? 'plain' : this.newItem.iconSet.replace('_color', ''));
          if (useRandomColors) {
            this.newItem.colorIconShapeRandom = true;
            this.newItem.colorIconBackgroundRandom = true;
            this.iconBackgroundColorType = 'random';
            this.iconShapeColorType = 'random';
          }
          firebase
            .database()
            .ref('resort-navigator')
            .child('campground-locations')
            .child(this.getCampgroundKey)
            .child('iconSet')
            .set(this.newItem.iconSet);
          this.$emit('update-style', this.newItem);
          this.mapLegacyIconsToSVG(priorIconSet);
        } // else {
        //   this.useNewIcons = false;
        // }
      } catch (err) {
        console.log('err', err);
      }
    },
    updateDrawerImage: function (imageURL) {
      this.newItem.drawerLogo = imageURL;
      this.newItem.localDrawerLogo = imageURL;
    },
    updateHeaderImage: function (imageURL) {
      this.newItem.drawable = imageURL;
      this.newItem.localDrawable = imageURL;
      this.$emit('update-style', this.newItem);
    },
    getGooglePhotos() {
      // eslint-disable-next-line no-undef
      const placesService = new google.maps.places.PlacesService(this.$refs.photos);
      placesService.getDetails({ placeId: this.newItem.placeId }, (results) => {
        this.photos = results.photos;
      });
    },
    updateLocation: function (locationData) {
      this.$delete(locationData, 'hours');
      this.$delete(locationData, 'distance');
      this.$delete(locationData, 'location');
      this.newItem = { ...this.newItem, ...locationData };
      this.newItem.name = locationData.title;
      this.newItem.latitude = Number(this.newItem.latitude) || null;
      this.newItem.longitude = Number(this.newItem.longitude) || null;
      this.newItem.timeZone = tzLookup(locationData.latitude, locationData.longitude);
      if (this.newItem.photos && this.newItem.photos.length > 0) {
        this.photos = locationData.photos;
      }
      if (this.action === `add-community`) {
        window.localStorage.setItem('CAMPGROUND_KEY', this.getNewId());
      }
      this.$delete(this.newItem, 'photos');
    },

    onColorClose: function () {
      this.editBackground = false;
      this.editPrimary = false;
      this.editAccent = false;
      this.editHighlightedText = false;
      this.editIcon = false;
      this.editIconBackground = false;
    },
    setLocationValid: function (isInvalid) {
      this.locationInvalid = isInvalid;
    },
    getNewId: function () {
      return this.newItem.title.replace(/'/gu, '').replace(/\W+/gu, '-').toLowerCase();
    },
    submitUpdate: async function () {
      this.vuelidate$.$touch();
      this.$nextTick(async () => {
        if (!this.vuelidate$.$invalid && !this.locationInvalid) {
          if (this.$refs.useImage) await this.$refs.useImage.saveCrop();
          /*if (!this.newItem.isBusinessOnly) {
            console.log('🚀 ~ file: CommunitySetup.vue ~ line 604 ~ .then ~ this.newItem.iconSet', this.newItem.iconSet);
            await this.$refs.useImage2.croppieUseImage();
          }
          */
          let campgroundKey = this.action != `add-community` ? this.getCampgroundKey : this.getNewId();
          if (this.$fbProject == 'campinginontario' || this.$fbProject == 'campon') {
            this.newItem.packageNames = {
              android: 'com.armstrongsoft.campinginontario',
              ios: 'com.armstrongsoft.CampingInOntario',
              iosId: 1513447410,
              shared: true,
              tableId: 'camping-in-ontario.analytics_240745196',
            };
          }
          let submitItem = { ...this.newItem };
          submitItem.colorIconShape = this.iconShapeColorType == 'specific' ? this.newItem.colorIconShape : null;
          submitItem.colorIconShapeRandom = this.iconShapeColorType == 'random';
          submitItem.colorIconBackground = this.iconBackgroundColorType == 'specific' ? this.newItem.colorIconBackground : null;
          submitItem.colorIconBackgroundRandom = this.iconBackgroundColorType == 'random';
          firebase.database().ref('resort-navigator').child('campground-locations').child(campgroundKey).set(submitItem);
          this.setCampgroundLocationData(campgroundKey, submitItem);
          if (
            this.colorIconShapeRandomOrig !== this.newItem.colorIconShapeRandom ||
            this.colorIconBackgroundRandomOrig !== this.newItem.colorIconBackgroundRandom
          ) {
            this.updateAllIconColors();
          } else {
            this.cancelUpdate();
          }
          //window.location.href = '/';
        }
      });
    },
    completeColorChange() {
      this.updateAllIconsByContext();
      this.cancelUpdate();
    },
    updateAllIconsByContext: function () {
      const dbUpdates = this.iconsByContexts.reduce((acc, item) => {
        acc[item.objectPath] = this.removeObjectDetails(item);
        return acc;
      }, {});
      firebase.database().ref('resort-navigator').child('location-data').child(this.getCampgroundKey).update(dbUpdates);
    },
    cancelUpdate: function () {
      this.$router.push({ name: 'home' });
    },
    async getIconsByContexts() {
      if (!this.iconsByContexts || this.iconsByContexts.length == 0) {
        const dbRef = firebase.database().ref('resort-navigator').child('location-data').child(this.getCampgroundKey);
        const data = (await dbRef.once('value')).val();
        const ignoredKeys = [
          'ads',
          'audience',
          'bookings',
          'business-detail',
          'config',
          'contactinfo-emergency',
          'contactinfo-offices',
          'custom-forms',
          'event-printout',
          'events-local-archive',
          'events-local-recurrences',
          'events-local',
          'events-resort',
          'events-resort-archive',
          'events-resort-recurrences',
          'hunts',
          'locations',
          'main-menu',
          'maps-local',
          'maps-resort',
          'messages',
          'messages-config',
          'recreation-local',
          'recreation-local-categories',
          'recreation-resort',
          'reservations-lodging',
          'reservations-misc',
          'rewards',
          'services-local',
          'services-local-categories',
          'services-resort',
          // 'staff-menu',
          'store',
        ];

        this.iconsByContexts = this.getIconsFromObject('main-menu', data['main-menu'], 'Main Menu');
        // if (data['staff-menu'])
        //   this.iconsByContexts = this.iconsByContexts.concat(this.getIconsFromObject('staff-menu', data['staff-menu'], 'Staff Menu'));
        if (data['recreation-local-categories'] && data['recreation-local-categories']['recreation-categories'])
          this.iconsByContexts = this.iconsByContexts.concat(
            this.getIconsFromObject(
              'recreation-local-categories/recreation-categories',
              data['recreation-local-categories']['recreation-categories'],
              'Recreation Local'
            )
          );
        if (data['services-local-categories'] && data['services-local-categories']['services-categories'])
          this.iconsByContexts = this.iconsByContexts.concat(
            this.getIconsFromObject(
              'services-local-categories/services-categories',
              data['services-local-categories']['services-categories'],
              'Services Local'
            )
          );
        this.iconsByContexts = this.iconsByContexts.concat(
          Object.keys(data)
            .filter((dbContext) => {
              if (ignoredKeys.includes(dbContext)) return false;
              const section = data[dbContext];
              const firstItem = Array.isArray(section) ? section.filter((x) => x !== null)[0] : section[Object.keys(section)[0]];
              return firstItem.icon && firstItem.uniqueId;
            })
            .reduce((acc, menuContext) => {
              return acc.concat(this.getIconsFromObject(menuContext, data[menuContext], menuContext));
            }, [])
        );
      }
      this.iconsByContexts = this.iconsByContexts.filter((x) => x);
    },
    async mapLegacyIconsToSVG(origIconSet) {
      await this.getIconsByContexts();
      const { faIcons, customIcons } = (await axios.get(`/json/faIcons.json`)).data;
      this.iconsByContexts = this.iconsByContexts.map((x) => ({
        ...x,
        ...{ iconData: this.getIconSVG(x.icon, origIconSet, faIcons, customIcons) },
      }));
      this.updateAllIconsByContext();
    },
    getIconSVG(icon, iconSet, faIcons, customIcons) {
      const mappedTitle = this.getMappedTitle(icon, iconSet);
      const currentObject = { ...(faIcons[mappedTitle] || customIcons[mappedTitle]) };
      return { svg: currentObject.svg[currentObject.styles[0]].raw, title: mappedTitle };
    },
    async updateAllIconColors() {
      await this.getIconsByContexts();
      let displayUpdateColors = false;
      if (this.colorIconShapeRandomOrig !== this.newItem.colorIconShapeRandom) {
        if (!this.newItem.colorIconShapeRandom) {
          this.dbUpdate = this.iconsByContexts.reduce((acc, item) => {
            acc[`${item.objectPath}/iconData/colorIconShape`] = null;
            return acc;
          }, this.dbUpdate);
        } else {
          displayUpdateColors = true;
        }
      }
      if (this.colorIconBackgroundRandomOrig !== this.newItem.colorIconBackgroundRandom) {
        if (!this.newItem.colorIconBackgroundRandom) {
          this.dbUpdate = this.iconsByContexts.reduce((acc, item) => {
            acc[`${item.objectPath}/iconData/colorIconBackground`] = null;
            return acc;
          }, this.dbUpdate);
        } else {
          displayUpdateColors = true;
        }
      }
      if (displayUpdateColors) {
        this.iconsByContexts = this.iconsByContexts.map((x) => this.updateRandomIconColors(x));
        this.$refs.updateColorsModal.show();
      } else {
        const dbUpdate = Object.keys(this.dbUpdate).reduce((acc, item) => {
          acc[item] = this.removeObjectDetails(this.dbUpdate[item]);
          return acc;
        }, {});
        const dbRef = firebase.database().ref('resort-navigator').child('location-data').child(this.getCampgroundKey);
        dbRef.update(dbUpdate);
        this.cancelUpdate();
      }
    },
    removeObjectDetails(item) {
      return { ...item, ...{ objectPath: null, objectTitle: null } };
    },
    updateRandomIconColorsForIndex(x, index) {
      this.iconsByContexts[index] = this.updateRandomIconColors(x);
    },

    updateRandomIconColors: function (x) {
      x.iconData = x.iconData || {};
      const tempItem = this.generateRandomIconColors(
        x.iconData,
        this.newItem.colorIconShapeRandom,
        this.newItem.colorIconBackgroundRandom,
        this.newItem.iconSet
      );
      x.iconData.colorIconBackground = tempItem.colorIconBackground || null;
      x.iconData.colorIconShape = tempItem.colorIconShape || null;
      return x;
    },
    getIconsFromObject: function (dbContext, currentObject, objectTitle) {
      return Array.isArray(currentObject)
        ? currentObject.map((x, index) => ({ ...x, objectPath: `${dbContext}/${index}`, objectTitle: objectTitle }))
        : Object.keys(currentObject).map((x) => ({ ...currentObject[x], objectPath: `${dbContext}/${x}`, objectTitle: objectTitle }));
    },
  },
};
</script>

<style lang="scss" scoped>
.color-selector div {
  width: 20px;
  height: 20px;
}
.color-selector .btn {
  padding: 0.25rem 0.25rem;
}
.vc-chrome {
  width: 175px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.menu-divider {
  grid-column: 1/-1;
  background-color: #183449;
  color: white;
  text-align: center;
  font-size: xxx-large;
}
</style>
