<template>
  <component v-bind:is="type"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'detail',
  components: {
    events: defineAsyncComponent(() => import('../components/forms/Event.vue')),
    eventsArchived: defineAsyncComponent(() => import('../components/forms/Event.vue')),
    business: defineAsyncComponent(() => import('../components/forms/Business.vue')),
    reservations: defineAsyncComponent(() => import('../components/forms/Reservation.vue')),
    rewards: defineAsyncComponent(() => import('../components/forms/Rewards.vue')),
    forms: defineAsyncComponent(() => import('../components/forms/CustomForms.vue')),
    hunts: defineAsyncComponent(() => import('../components/forms/Hunts.vue')),
    bookings: defineAsyncComponent(() => import('../components/forms/Booking.vue')),
    store: defineAsyncComponent(() => import('../components/forms/StoreItem.vue')),
    searchablemap: defineAsyncComponent(() => import('../components/forms/SearchableMap.vue')),
  },
  data() {
    return {
      type: this.$route.params.type,
    };
  },
};
</script>

<style scoped>
#tabs {
  display: flex;
  width: 100%;
}

.pointer,
#tabs div,
#menu-items > li:hover,
.item label {
  cursor: pointer;
}
.tab.selected {
  margin-bottom: 4px;
}
.tab {
  color: white;
  text-align: center;
  float: left;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-basis: 100%;
}

#items {
  list-style: none;
  color: white;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

#items li {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 10px;
  border-bottom: 1px solid black;
  padding: 8px 16px;
}

#grid-container {
  min-height: 500px;
  list-style-type: none;
  margin: 0px;
  padding: 10px 0px;
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  grid-gap: 20px;
}
</style>
