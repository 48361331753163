<template>
  <div>
    <h1 id="analytics-title" class="w-100 text-center" v-html="getReportHeader()" />
    <div id="analytics-page">
      <div style="flex: 1">
        <div v-if="!loaded" class="text-xl text-center">
          <h1 class="text-secondary" style="margin-top: 200px">
            Loading Data
            <div class="spinner-grow spinner-grow-sm" role="status" style="width: 1rem; height: 1rem">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm" role="status" style="width: 1rem; height: 1rem">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm" role="status" style="width: 1rem; height: 1rem">
              <span class="visually-hidden">Loading...</span>
            </div>
          </h1>
        </div>
        <Bar
          v-else-if="events[selectedEvent].chart && loaded"
          id="chart-container"
          :data="chartData[selectedEvent]"
          :options="options[selectedEvent]" />
        <div v-else>
          <EasyDataTable
            :headers="tableFields"
            :items="tableData"
            :rows-items="rowsPerPage"
            :rows-per-page="pageRows"
            header-text-direction="center"
            body-text-direction="center"
            :body-row-class-name="bodyRowClassNameFunction"
            alternating
            hide-footer
            @update-sort="moveTotalToBottom" />
        </div>
      </div>
      <div id="right-column" class="p-2">
        <div class="p-2 form-container">
          <form novalidate>
            <template>
              <div>{{ isSharedApp ? sharedAppName : '' }} App Reports</div>
              <button
                type="button"
                class="btn btn-info mt-1 p-1 w-100"
                data-bs-toggle="collapse"
                href="#accordion-1"
                @click="selectEvent('downloads')">
                {{ events['downloads'].name }}
              </button>
              <button
                type="button"
                class="btn btn-info mt-2 p-1 w-100"
                data-bs-toggle="collapse"
                href="#accordion-2"
                @click="selectEvent('splash_screen')">
                {{ events['splash_screen'].name }}
              </button>
              <button
                type="button"
                class="btn btn-info mt-2 p-1 w-100"
                data-bs-toggle="collapse"
                href="#accordion-2"
                @click="selectEvent('campground_opens')"
                v-if="isResortAdmin && isSharedApp">
                {{ events['campground_opens'].name }}
              </button>
              <button
                type="button"
                class="btn btn-info mt-2 p-1 w-100"
                data-bs-toggle="collapse"
                href="#accordion-2"
                @click="selectEvent('menu_item_click_admin')"
                v-if="isResortAdmin && isSharedApp">
                {{ events['menu_item_click_admin'].name }}
              </button>
              <button
                type="button"
                class="btn btn-info mt-2 p-1 w-100"
                data-bs-toggle="collapse"
                href="#accordion-2"
                @click="selectEvent('banner_ad_listing')"
                v-if="isResortAdmin && isSharedApp">
                {{ events['banner_ad_listing'].name }}
              </button>
              <div v-if="isSharedApp" class="mt-2">{{ getCampgroundName }} Reports</div>
              <button type="button" class="btn btn-info mt-2 p-1 w-100" @click="selectEvent('location_favorites')" v-if="isSharedApp">
                {{ events['location_favorites'].name }}
              </button>
              <button type="button" class="btn btn-info mt-1 p-1 w-100" @click="selectEvent('location_opens')" v-if="isSharedApp">
                {{ events['location_opens'].name }}
              </button>
              <button type="button" class="btn btn-info mt-2 p-1 w-100" @click="selectEvent('banner_ad')">
                {{ events['banner_ad'].name }}
              </button>
              <button type="button" class="btn btn-info mt-2 p-1 w-100" @click="selectEvent('button_clicks')">
                {{ events['button_clicks'].name }}
              </button>
              <button type="button" class="btn btn-info mt-2 p-1 w-100" @click="selectEvent('menu_item_click')">
                {{ events['menu_item_click'].name }}
              </button>
              <button type="button" class="btn btn-info mt-2 p-1 w-100" @click="selectEvent('business_favorites')">
                {{ events['business_favorites'].name }}
              </button>
              <!--b-button block href="#" class="mt-2 p-1 w-100" variant="info" @click="selectEvent('notification_open')">{{events['notification_open'].name}}</b-button-->

              <div class="row" v-if="events[selectedEvent].dateRequired">
                <div class="col">
                  <div class="mb-3">
                    <!-- <label class="form-label">Start Date</label>
                    <input class="form-control" v-model="formattedStartDate" type="date" /> -->
                    <DateTime
                      v-model="formattedStartDate"
                      label="Start Date"
                      :min-date="null"
                      :max-date="formattedEndDate"
                      :time-requested="false"
                      :clearable="false"
                      @update="
                        (timestamp) => {
                          formattedStartDate = this.formatTime(timestamp);
                        }
                      " />
                  </div>
                </div>
              </div>
              <div class="row" v-if="events[selectedEvent].dateRequired">
                <div class="col">
                  <div class="mb-3">
                    <!-- <label class="form-label">End Date</label>
                    {{ formattedEndDate }}
                    <input class="form-control" v-model="formattedEndDate" type="date" /> -->
                    <DateTime
                      v-model="formattedEndDate"
                      label="End Date"
                      :min-date="formattedStartDate"
                      :time-requested="false"
                      :clearable="false"
                      @update="
                        (timestamp) => {
                          formattedEndDate = this.formatTime(timestamp);
                        }
                      " />
                  </div>
                </div>
              </div>
              <button v-if="loaded && tableData" class="btn m-1 mt-2 btn-primary" type="submit" @click.prevent="exportCSV">Export Data</button>
              <button class="btn m-1 mt-2 btn-primary" type="submit" @click.prevent="printPage">Print</button>
              <button class="btn m-1 btn-secondary" type="button" @click.prevent="cancelPrintout">Exit</button>
            </template>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import DateTime from './DateTime2';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'Analytics',
  components: { Bar, DateTime },
  data() {
    const endDate = new Date().getTime();
    //const startDate = Date.parse("10/17/2019");

    const oneDayInMillis = 24 * 60 * 60 * 1000;
    const startDate = endDate - 30 * oneDayInMillis;
    const formattedStartDate = moment.tz(startDate, this.getTimeZone).format('YYYY-MM-DD');
    const formattedEndDate = moment.tz(endDate, this.getTimeZone).format('YYYY-MM-DD');
    const packageNameData = JSON.parse(window.localStorage.getItem('CAMPGROUND_PACKAGE_NAMES'));
    const sharedAppName =
      packageNameData.sharedAppName ||
      (this.$fbProject === 'livecobblestone'
        ? 'Live Cobblestone'
        : this.$fbProject === 'campinginontario' || this.$fbProject === 'campingon'
        ? 'CampOn'
        : '');
    return {
      events: {
        downloads: { name: 'Downloads', chart: true, sharedData: true },
        splash_screen: {
          name: 'Splash Screens',
          eventArray: 'splash_screen,splash_screen_click,splash_screen_clicked',
          dateRequired: true,
          sharedData: true,
        },
        banner_ad_listing: { name: 'Listing Banner Ads', eventArray: 'banner_ad,banner_ad_click,banner_ad_clicked', dateRequired: true },
        campground_opens: { name: 'Campground Opens', eventArray: 'open_location,type', dateRequired: true, sharedOnly: true, sharedData: true },
        menu_item_click_admin: {
          name: 'Home Screen Item Click All',
          eventArray: 'menu_item_click',
          dateRequired: true,
          sharedOnly: true,
          sharedData: true,
        },
        location_favorites: { name: 'Location Favorites', chart: true, eventArray: 'favorite_location,unfavorite_location', sharedOnly: true },
        location_opens: { name: 'Location Opens', chart: true, eventArray: 'open_location', dateRequired: true, sharedOnly: true },
        banner_ad: { name: 'Banner Ads', eventArray: 'banner_ad,banner_ad_click,banner_ad_clicked', dateRequired: true },
        button_clicks: {
          name: 'Detail Page Data',
          eventArray: 'open_business,phone_click,reservation_click,map_click,menu_click,website_click,special_click,button_click',
          dateRequired: true,
        },
        phone_click: { name: 'Phone Clicked', dateRequired: true },
        reservation_click: { name: 'Reservation Clicked', dateRequired: true },
        map_click: { name: 'Map Clicked', dateRequired: true },
        menu_click: { name: 'Menu Clicked', dateRequired: true },
        website_click: { name: 'Website Clicked', dateRequired: true },
        menu_item_click: { name: 'Home Screen Item Click', dateRequired: true },
        business_favorites: { name: 'Business Favorites', dateRequired: true },
        notification_open: { name: 'Notification Opened', dateRequired: true },
      },
      isResortAdmin:
        window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false,
      isSharedApp: packageNameData.shared || false,
      sharedAppName,
      selectedEvent: 'downloads',
      startDate,
      endDate,
      formattedStartDate,
      formattedEndDate,
      loaded: false,
      chartData: {},
      tableData: null,
      tableFields: null,
      tableTotals: null,
      options: {},
      rowsPerPage: [],
      pageRows: 0,
      loadedData: {},
      currentQuery: 'downloads',
    };
  },

  watch: {
    formattedStartDate: function () {
      this.selectEvent();
    },
    formattedEndDate: function () {
      this.selectEvent();
    },
  },
  async mounted() {
    await this.processDownloadsData();
  },
  methods: {
    getReportHeader: function () {
      let name = this.isSharedApp && this.events[this.selectedEvent].sharedData ? this.sharedAppName : this.getCampgroundName;
      return `${name}<br/>${this.events[this.selectedEvent].name}`;
    },

    selectEvent: async function (event) {
      const date = this.getDateString();
      this.currentQuery = `${event}-${date}`;
      this.selectedEvent = event || this.selectedEvent;

      if (this.selectedEvent == 'downloads') {
        if (!this.chartData.downloads) this.processDownloadsData();
      } else if (this.selectedEvent !== 'downloads') {
        if (this.loadedData[this.selectedEvent] && this.loadedData[this.selectedEvent][date]) {
          const data = this.loadedData[this.selectedEvent][date];
          this.tableFields = data.tableFields;
          this.tableData = data.tableData;
          this.tableTotals = data.tableTotals;
        } else {
          let eventData = this.events[this.selectedEvent];
          this.loaded = false;
          var payload = {
            report: this.selectedEvent,
            reportData: eventData && eventData.eventArray ? eventData.eventArray : this.selectedEvent,
            startDate: this.formattedStartDate,
            endDate: this.formattedEndDate,
            campgroundId: this.getCampgroundKey,
          };
          const response = await this.getAnalyticsData(payload);
          if (this.currentQuery !== `${event}-${date}`) {
            console.log('Cancelled query response for', event);
            return;
          }
          if (eventData.chart) {
            if (this.selectedEvent == 'location_opens') {
              this.processLocationOpenData(response);
            } else if (this.selectedEvent == 'location_favorites') {
              this.processLocationFavoritesData(response);
            }
          } else {
            //Convert old header fields to new header fields format.
            this.tableFields = response.data.fields.map((item) => {
              return { text: this.titleCase(item.key), value: item.key, sortable: true };
            });
            this.tableData = response.data.data;
            this.tableTotals = response.data.totals;

            if (this.selectedEvent == 'button_clicks') {
              let newFields = ['name', 'open_business', 'phone_click', 'map_click', 'website_click', 'other_button_clicks', 'total_clicks'];
              this.tableFields = this.tableFields.filter((x) => newFields.includes(x.value));
              var otherButtonTotal = 0;
              response.data.data.map((row) => {
                row['other_button_clicks'] = Object.keys(row).reduce((acc, key) => {
                  if (newFields.includes(key)) {
                    return acc;
                  }
                  otherButtonTotal += row[key];
                  return acc + row[key];
                }, 0);
              });
              this.tableTotals['other_button_clicks'] = otherButtonTotal;
              //thClass: 'text-center', tdClass: 'text-center',
              this.tableFields.push({ text: 'Other Button Clicks', value: 'other_button_clicks', sortable: true });
              this.tableFields.push({ text: 'Total Clicks', value: 'total_clicks', sortable: true });
            } else if (this.selectedEvent == 'campground_opens') {
              this.tableFields.push({ text: 'Type', value: 'type', sortable: true });
            } else if (this.selectedEvent == 'business_favorites') {
              this.tableFields.push({ text: 'Favorites', value: 'business_favorites', sortable: true });
            }

            if (this.tableFields.length > 0) this.tableFields[0].fixed = true;
            this.tableData.push(this.tableTotals);
            if (this.tableData.length > 0) this.pageRows = this.tableData.length;

            if (!this.loadedData[this.selectedEvent]) this.loadedData[this.selectedEvent] = {};
            this.loadedData[this.selectedEvent][date] = {
              tableFields: this.tableFields,
              tableData: this.tableData,
              tableTotals: this.tableTotals,
            };
          }

          this.loaded = true;
        }
      }
    },
    async processDownloadsData() {
      this.loaded = false;
      const response = await this.getAnalyticsData(null, `campgroundId=${this.getCampgroundKey}`);

      this.chartData.downloads = {
        labels: response.data.labels,
        datasets: [
          {
            label: '# of First Opens iOS',
            backgroundColor: '#007AFF',
            data: response.data.firstOpensiOS,
            borderWidth: 1,
          },
          {
            label: '# of First Opens Android',
            backgroundColor: '#A4C639',
            data: response.data.firstOpensAndroid,
            borderWidth: 1,
          },
        ],
      };

      this.options.downloads = {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
            callbacks: {
              afterTitle: function () {
                window.total = 0;
              },
              beforeFooter: function (tooltipItem) {
                let total = 0;
                tooltipItem.forEach((element) => {
                  total += element.raw;
                });

                return 'TOTAL: ' + total;
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },

          y: {
            stacked: true,
          },
        },
      };
      this.loaded = true;
    },
    processLocationOpenData: function (response) {
      let processedData = {
        labels: [],
        data: [],
      };
      processedData.labels = response.data.data.map((date) => {
        processedData.data.push(date.total);
        return moment.tz(date.name, 'YYYYMMDD', this.getTimeZone).format('MM/DD/YYYY');
      });

      this.chartData[this.selectedEvent] = {
        labels: processedData.labels,
        datasets: [
          {
            label: 'Location Opens',
            backgroundColor: '#007AFF',
            data: processedData.data,
            borderWidth: 1,
          },
        ],
      };
      this.options[this.selectedEvent] = {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              window.total += value;
              return `Opens: ${value.toLocaleString()}`;
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      };
    },
    processLocationFavoritesData: function (response) {
      let processedData = {
        labels: [],
        favorites: [],
        unfavorites: [],
        totals: [],
      };
      let total = 0;
      processedData.labels = response.data.data.map((date) => {
        total += (date.favorite_location || 0) - (date.unfavorite_location || 0);
        processedData.totals.push(total);
        processedData.favorites.push(date.favorite_location || 0);
        processedData.unfavorites.push(date.unfavorite_location || 0);
        return moment.tz(date.name, 'YYYYMMDD', this.getTimeZone).format('MM/DD/YYYY');
      });

      this.chartData[this.selectedEvent] = {
        labels: processedData.labels,
        datasets: [
          {
            label: 'Favorites',
            backgroundColor: '#007AFF',
            data: processedData.totals,
            borderWidth: 1,
            favoriteData: processedData.favorites,
            unfavoriteData: processedData.unfavorites,
          },
        ],
      };
      this.options[this.selectedEvent] = {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const currentFavorites = dataset.favoriteData[tooltipItem.index];
              const currentUnfavorites = dataset.unfavoriteData[tooltipItem.index];
              return `Favorites Added: ${currentFavorites.toLocaleString()}\n
                Favorites Removed: ${currentUnfavorites.toLocaleString()}`;
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },

          y: {
            stacked: true,
          },
        },
      };
    },
    printPage: function () {
      window.print();
    },
    cancelPrintout: function () {
      window.close();
    },
    getAnalyticsData: async function (payload, query) {
      let url = `${this.$apihostname}/api/analytics`;
      if (query) {
        url += `?${query}&_=${Date.now()}`;
        return await axios.get(url);
      }
      return await axios.post(url, payload);
    },
    titleCase(s) {
      return s
        .toLowerCase()
        .split(/_| /)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    removeColumnsForExport() {
      const arrData = [
        ...this.tableData.map((x) => {
          delete x['other_button_clicks'];
          if (this.selectedEvent === 'splash_screen') {
            x.splash_screen_click = x.splash_screen_click + x.splash_screen_clicked;
            delete x['splash_screen_clicked'];
          } else if (this.selectedEvent === 'banner_ad') {
            x.banner_ad_click = x.banner_ad_click + x.banner_ad_clicked;
            delete x['banner_ad_clicked'];
          }
          return x;
        }),
      ];
      return arrData;
    },
    exportCSV() {
      const arrData = this.removeColumnsForExport();
      const titleCaseHeaders = (Object.keys(arrData?.[0]) || []).map((x) => this.titleCase(x)); // First char after each -/_
      arrData.unshift(titleCaseHeaders);

      this.csvExport(arrData);
    },
    bodyRowClassNameFunction(item, rowNumber) {
      if (item.name == 'Total') return 'total-row';
      return '';
    },
    moveTotalToBottom() {
      this.$nextTick(() => {
        const tableBody = document.querySelector('.vue3-easy-data-table__body');
        console.log('tableBody', tableBody);

        const totalRow = document.querySelector('.total-row');
        console.log('totalRow', totalRow);

        tableBody.appendChild(totalRow);
        console.log('Moved to bottom of TBody');
      });
    },
    formatTime(timestamp) {
      return moment.tz(timestamp, this.getTimeZone).format('YYYY-MM-DD');
    },
    getDateString() {
      return `${moment(this.formattedStartDate).format('YYYY-MM-DD')}.${moment(this.formattedEndDate).format('YYYY-MM-DD')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#analytics-page {
  display: grid;
  grid-template-columns: 1fr 300px;
}

@media print {
  .table.b-table > thead > tr > [aria-sort] {
    background: none !important;
  }
  #analytics-page {
    display: grid;
    grid-template-columns: 1fr;
  }
  #right-column {
    display: none;
  }
}
</style>
