import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';

export const LoginMixin = {
  methods: {
    goHome: function () {
      if (this.$route.name != 'home' && !this.$route.meta.isRemote) this.$router.push({ name: 'home' });
    },
    authorizeUser: async function (uid, initialLogin) {
      const communityAccess = await firebase.database().ref('user-authorization').child('users').child(uid).once('value');
      const authorizationIds = this.getCampgroundAuthorizationsFromSnapshot(communityAccess);
      const isAuthorized = this.checkAccessAndAdmin(authorizationIds);
      if (!isAuthorized) {
        const remoteInfo = (await firebase.database().ref('resort-navigator/remote-management/uid').child(uid).once('value')).val() || {};
        const detailPages = Object.keys(remoteInfo)
          .filter((detailPageId) => remoteInfo[detailPageId].editInfo)
          .reduce((acc, detailPageId) => {
            acc[detailPageId] = remoteInfo[detailPageId];
            return acc;
          }, {});
        if (Object.keys(detailPages).length > 0) {
          window.localStorage.setItem('REMOTE_DETAIL_PAGE_ACCESS', JSON.stringify(detailPages));
          let lastEdited = window.localStorage.getItem('REMOTE_DETAIL_LAST_EDITED');
          if (!lastEdited || !detailPages[lastEdited]) {
            lastEdited = Object.keys(detailPages)[0];
            window.localStorage.setItem('REMOTE_DETAIL_LAST_EDITED', lastEdited);
          }
          this.$router.push({
            name: 'remoteUpdateDetail',
            params: { detailId: lastEdited, remoteInfo: remoteInfo[lastEdited] },
          });
        } else {
          this.$message.create({
            title: 'User Does Not Have Access',
            body: 'The authenticated user does not have access to modify this app.',
            buttons: [this.$message.button('OK')],
          });
          this.$router.push({ name: 'Login' });
          return;
        }
      }

      if (!window.localStorage.getItem('MENU_SECTION')) {
        window.localStorage.setItem('MENU_SECTION', 'main-menu');
      }
      const editorDefault = communityAccess.hasChild('editorDefault') ? communityAccess.child('editorDefault').val() : null;
      await this.getCampgroundLocationData(uid, authorizationIds, editorDefault);
      if (window.$zoho) {
        window.$zoho.salesiq.floatbutton.visible('hide');
      }
      if (initialLogin) {
        this.goHome();
      }
    },
    updateAuthorizationData: function (campgroundSnapshot, campgroundAuthorizationData, authorizationIds, dbPathContext) {
      if (campgroundSnapshot.val()) {
        campgroundAuthorizationData.campgroundList.unshift(campgroundSnapshot.key);
        campgroundAuthorizationData.campgrounds[campgroundSnapshot.key] = campgroundSnapshot.val();
        if (authorizationIds.allAdminAuthorizationIds.indexOf(dbPathContext) >= 0) {
          campgroundAuthorizationData.adminCampgrounds.unshift(campgroundSnapshot.key);
        }
      }
    },
    setDollars() {
      console.log('🚀 ~ file: LoginMixin.js ~ line 52 ~ .then ~ setDollars');
      firebase
        .database()
        .ref('resort-navigator')
        .child('amc-dollars/config/enabled')
        .once('value')
        .then((snapshot) => {
          console.log('🚀 ~ file: LoginMixin.js ~ line 52 ~ .then ~ snapshot', snapshot.val());
          window.localStorage.setItem('DOLLARS_ENABLED', snapshot.val() || false);
        })
        .catch(() => {
          console.log('🚀 ~ file: LoginMixin.js ~ line 52 ~ .then ~ snapshot catch');
          window.localStorage.setItem('DOLLARS_ENABLED', false);
        });
    },
    getCampgroundAuthorizationsFromSnapshot: function (snapshot) {
      var allAuthorizationIds = [];
      var allAdminAuthorizationIds = [];
      var allResortSystemIds = [];
      var canSendNotifications = [];
      snapshot.forEach(function (element) {
        if (element.child('isResortSystem').val()) {
          allResortSystemIds.unshift(element.key);
        }
        if (element.child('isEditor').val() || element.child('isAdmin').val()) {
          allAuthorizationIds.unshift(element.key);
          if (element.child('isAdmin').val()) {
            canSendNotifications.unshift(element.key);
            allAdminAuthorizationIds.unshift(element.key);
          } else if (element.child('canNotify').val()) {
            canSendNotifications.unshift(element.key);
          }
        }
      });
      window.localStorage.setItem('ADMIN_LOCATIONS', JSON.stringify(allAdminAuthorizationIds));
      window.localStorage.setItem('EDIT_LOCATIONS', JSON.stringify(allAuthorizationIds));
      window.localStorage.setItem('CAN_NOTIFY_LOCATIONS', JSON.stringify(canSendNotifications));

      const getAppList = firebase.functions().httpsCallable('getAppList');
      getAppList().then((apps) => {
        const appList = JSON.stringify(apps.data.map((app) => app.packageName.replace('com.armstrongsoft.', '').toLowerCase()));
        window.localStorage.setItem('APP_LIST', appList);
      });

      return {
        allAuthorizationIds,
        allAdminAuthorizationIds,
        allResortSystemIds,
      };
    },
    checkAccessAndAdmin: function (authorizationIds) {
      if (authorizationIds.allAuthorizationIds.length == 0) {
        return false;
      }
      window.localStorage.setItem('RESORT_SYSTEM_ADMIN', false);
      for (var index = 0; index < authorizationIds.allAuthorizationIds.length; index += 1) {
        var authorizationId = authorizationIds.allAuthorizationIds[index];
        if (authorizationIds.allResortSystemIds.indexOf(authorizationId) >= 0) {
          window.localStorage.setItem('RESORT_SYSTEM_ADMIN', true);
        }
      }
      return true;
    },
    getCampgroundLocationData: async function (uid, authorizationIds, editorDefault) {
      const dbRef = firebase.database().ref('resort-navigator').child('campground-locations');
      const campgroundList = (await dbRef.once('value')).val();
      const listAllCampgrounds = Object.keys(campgroundList || {});
      window.localStorage.setItem('ALL_LOCATIONS', JSON.stringify(listAllCampgrounds));
      let campgroundLocationKey = window.localStorage.getItem('CAMPGROUND_KEY');
      if (!campgroundLocationKey && editorDefault != null) {
        campgroundLocationKey = editorDefault;
      } else if (!campgroundLocationKey || !listAllCampgrounds.includes(campgroundLocationKey)) {
        campgroundLocationKey =
          authorizationIds.allAuthorizationIds.reduce((acc, communityId) => {
            return listAllCampgrounds.includes(communityId) ? communityId : acc;
          }, null) || authorizationIds.allAuthorizationIds[0];
        if (authorizationIds.allResortSystemIds.indexOf(campgroundLocationKey) >= 0) {
          campgroundLocationKey = listAllCampgrounds[0];
        }
      }
      console.log('🚀 ~ file: LoginMixin.js ~ line 126 ~ getCampgroundLocationData:function ~ campgroundLocationKey', campgroundLocationKey);
      if (campgroundLocationKey) {
        this.setCampgroundLocationData(campgroundLocationKey, campgroundList[campgroundLocationKey]);
      }
      this.setDollars();
    },
  },
};
