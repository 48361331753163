<template>
  <Teleport v-if="targetElement" :to="to" :disabled="disabled">
    <Transition name="fade">
      <div v-if="showOverlay" class="overlay" :style="{ 'background-color': overlayColor }">
        <slot name="body">
          <div class="spinner-border" style="width: 1.5rem; height: 1.5rem" :style="{ color: textColor }" />
          <div class="overlay-content" :style="{ color: textColor }">
            {{ text }}
            {{ color }}
            <span class="dot-1"> .</span>
            <span class="dot-2"> .</span>
            <span class="dot-3"> .</span>
          </div>
        </slot>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
export default {
  props: {
    showProp: { type: Boolean, default: false },
    //Teleport customization
    to: { type: String, default: 'body' },
    disabled: { type: Boolean, default: false },
    //Overlay customization
    text: { type: String, default: 'Uploading images' },
    overlayColor: { type: String, default: 'rgba(0, 0, 0, 0.75)' },
    textColor: { type: String, default: 'white' },
    //Timeout customization
    timeout: { type: Number, default: -1 },
    delay: { type: Number, default: 500 },
  },
  watch: {
    showProp: function (val) {
      if (val) this.show();
      else this.hide();
    },
  },
  data() {
    return {
      showOverlay: false,
      targetElement: null,
    };
  },
  mounted() {
    this.targetElement = document.querySelector(this.to);
    if (this.targetElement == null) {
      console.error(`Element ${this.to} not found.`);
    }
  },
  methods: {
    show: function () {
      this.showOverlay = true;
      console.log(`Showing overlay on ${this.to}.`);

      if (this.timeout != -1) {
        setTimeout(() => {
          this.hide();
        }, this.timeout);
      }
    },
    hide: function () {
      setTimeout(() => {
        this.showOverlay = false;
        console.log(`Hiding overlay on ${this.to}.`);
      }, this.delay);
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}
.overlay-content {
  font-size: 20px;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.dot-1,
.dot-2,
.dot-3 {
  animation: blink 1s infinite;
}
.dot-1 {
  animation-delay: 0.2s;
}
.dot-2 {
  animation-delay: 0.4s;
}
.dot-3 {
  animation-delay: 0.6s;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
