<template>
  <div class="container px-0">
    <div v-if="source === 'pexels'">
      <div class="container">
        <div class="row">
          <div class="col-auto px-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32">
              <path d="M2 0h28a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" fill="#05A081"></path>
              <path
                d="M13 21h3.863v-3.752h1.167a3.124 3.124 0 1 0 0-6.248H13v10zm5.863 2H11V9h7.03a5.124 5.124 0 0 1 .833 10.18V23z"
                fill="#fff"></path>
            </svg>
          </div>
          <div class="col-auto px-1" v-if="showLabel">Pexels</div>
        </div>
      </div>
    </div>
    <div v-if="source === 'unsplash'">
      <div class="container">
        <div class="row">
          <div class="col-auto px-0">
            <svg
              width="32"
              height="32"
              class="hic6U"
              viewBox="0 0 32 32"
              version="1.1"
              aria-labelledby="unsplash-home"
              aria-hidden="false"
              v-bind:style="{
                'border-radius': '0.24rem',
                padding: '4px',
                'background-color': 'rgba(255,255,255,0.6)',
                width: '32px',
                height: '32px',
              }">
              <desc lang="en">Unsplash logo</desc>
              <title id="unsplash-home">Unsplash Home</title>
              <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
            </svg>
          </div>
          <div class="col-auto px-1" v-if="showLabel">Unsplash</div>
        </div>
      </div>
    </div>
    <div v-if="source === 'pixabay'">
      <div class="container">
        <div class="row">
          <div class="col-auto px-0">
            <svg height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:style="{ width: '32px', height: '32px' }">
              <rect width="48" height="48" rx="4" fill="#191B26" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.6525 24.8892L37.2639 30.7912H42.3448L34.8157 21.5238L41.7076 12.6792H36.9286L32.7029 18.2093L28.9132 12.6792H24.1006L30.4391 21.5238L23.5472 30.7912H28.5107L32.6525 24.8892ZM14.3852 12.6881C11.8443 12.7519 9.73311 13.6319 8.05141 15.3278C6.36972 17.0238 5.4972 19.1531 5.43396 21.7155V38.0377H8.99046V30.7836H14.3852C16.9277 30.7181 19.0457 29.8314 20.739 28.1237C22.4324 26.4159 23.3115 24.28 23.3765 21.7158C23.3116 19.1533 22.4324 17.0241 20.739 15.3281C19.0457 13.6321 16.9278 12.7523 14.3852 12.6884V12.6881ZM8.99046 27.1964V21.7155C9.02796 20.1664 9.55241 18.8819 10.5639 17.8617C11.5754 16.8416 12.8492 16.3127 14.3852 16.2749C15.9403 16.3127 17.2258 16.8416 18.2415 17.8617C19.2571 18.8818 19.7833 20.1664 19.8199 21.7155C19.7833 23.2839 19.2571 24.5802 18.2415 25.6046C17.2258 26.629 15.9403 27.1596 14.3852 27.1964H8.99046Z"
                fill="white" />
            </svg>
          </div>
          <div class="col-auto px-1" v-if="showLabel">Pixabay</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: String,
    url: String,
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
