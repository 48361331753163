<template>
  <div class="container">
    <quill-editor
      ref="editor"
      v-model:content="internalValue"
      contentType="html"
      theme="snow"
      :toolbar="toolbar"
      @text-change="update"
      @ready="initialize"></quill-editor>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {
    QuillEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    needsUpdate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: 'white',
    },
    tagName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      internalValue: '',
      startedEditing: false,
      toolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ['clean'],
      ],
    };
  },
  watch: {
    value: function () {
      if (!this.startedEditing || this.needsUpdate) {
        this.set(this.value == '' ? '<p></p>' : this.value);
      }
    },
  },
  mounted() {
    this.internalValue = this.value;
    this.$el.style.setProperty('--editor-background-color', this.backgroundColor || this.getBackgroundColor);
    this.$el.style.setProperty('--editor-text-color', this.textColor);
  },
  methods: {
    initialize(quill) {
      if (this.disabled) quill.enable(false);
    },
    update() {
      this.startedEditing = true;
      this.$emit('input', this.internalValue);
    },
    get() {
      return this.internalValue;
    },
    set(text) {
      this.internalValue = text;
    },
  },
};
</script>

<style>
.container {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: transparent !important;
}
.ql-container.ql-snow {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: var(--editor-background-color);
}
.ql-toolbar.ql-snow {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: white;
}
.ql-editor {
  min-height: 100px;
  color: var(--editor-text-color);
}
.is-invalid .ql-toolbar.ql-snow,
.is-invalid .ql-container.ql-snow {
  border-color: #dc3545;
}
.is-valid .ql-toolbar.ql-snow,
.is-valid .ql-container.ql-snow {
  border-color: #28a745;
}
</style>
