<template>
  <div class="invalid-feedback" :class="{ 'd-block': isInvalid, classes }">{{ message }}</div>
</template>

<script>
export default {
  props: {
    classes: { type: String, default: 'mb-3' },
    validator: { type: Object, default: null },
    feedback: { type: String, default: null },
    map: { type: String, default: null },
  },
  data() {
    return {
      message: 'Input is required.',
      messageMap: [
        { key: 'required', value: 'Input is required.' },
        { key: 'email', value: 'You must provide a valid email address.' },
        { key: 'phone', value: 'Phone Number needs to have ten digits.' },
        { key: 'url', value: 'Valid URL(e.g. http://www.google.com) is required.' },
        { key: 'form', value: 'One or more required fields have not been completed. Please check all tabs for your missing field.' },
      ],
    };
  },
  computed: {
    isInvalid() {
      //Check if validator is a boolean
      if (typeof this.validator === 'boolean') return this.validator;
      if (!this.validator) return false;
      return this.validator.$dirty && this.validator.$error;
    },
  },
  created() {
    if (this.map) this.message = this.messageMap.find((m) => m.key === this.map).value;
    else if (this.feedback) this.message = this.feedback;
    else console.log('Map or feedback are both empty, defaulting to "Input is required."');
  },
};
</script>

<style scoped></style>
