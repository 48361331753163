<template>
  <div></div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

export default {
  created() {
    const dbRef = firebase.database().ref('resort-navigator').child('remote-management').child('uid').child(firebase.auth().currentUser.uid);
    dbRef.once('value').then((uidSnap) => {
      const remoteInfo = uidSnap.val();
      if (Object.keys(remoteInfo).length == 1) {
        console.log('🚀 ~ file: RemoteListing.vue ~ line 19 ~ dbRef.once ~ Object.keys(remoteInfo)[0]', Object.keys(remoteInfo)[0]);
        this.$router.push({
          name: 'remoteUpdateDetail',
          params: { detailId: Object.keys(remoteInfo)[0], remoteInfo: remoteInfo[Object.keys(remoteInfo)[0]] },
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
