import ClipboardJS from 'clipboard';

export const CopyText = {
  copyText(text) {
    const id = `copyText-${new Date().getTime()}`;

    const button = document.createElement('button');
    button.id = id;
    button.style = 'display: none;';
    document.body.appendChild(button);

    const clipboard = new ClipboardJS('#' + id, {
      text: () => text,
      action: () => 'copy',
      container: document.body,
    });

    clipboard.on('success', function () {
      console.info('Copied to clipboard:', text);
    });
    clipboard.on('error', function () {
      console.info('Error copying to clipboard:', text);
    });

    button.click();
    document.body.removeChild(button);
  },
};
