<template>
  <div :style="getButtonColor" class="text-center detail-button" @click="performClick">
    <div v-if="svg" v-html="svg" class=" svg-inline--fa fa-w-14 " />
    <img v-else-if="notMaterial.includes(buttonIcon)" :src="`/iconsets/material/ic_${buttonIcon}.png`" />
    <i v-else class="material-icons">
      {{ buttonIcon }}
    </i>
    <div class="button-text">
      {{ buttonText }}
    </div>
  </div>
</template>

<script>
import { notMaterial } from '../json/materialIcons.json';
export default {
  props: ['buttonText', 'buttonIcon', 'svg', 'buttonColor', 'action', 'actionKey'],
  data() {
    return {
      notMaterial: notMaterial,
    };
  },
  computed: {
    getButtonColor: function() {
      if (this.buttonColor) {
        return `background-color:${this.buttonColor}`;
      }
      return this.getAccentColorStyle;
    },
  },
  methods: {
    performClick: function() {
      if (['website-webview', 'website', 'file', 'file-webview'].includes(this.action)) {
        window.open(this.actionKey, '_blank');
      }
    },
  },
};
</script>

<style>
.detail-button {
  padding: 4px;
  color: white;
  min-height: 90px;
  min-width: 82px;
  text-align: center;
  cursor: pointer;
  line-height: 18px;
  align-content: start;
}

.detail-button i {
  font-size: xx-large;
  width: 30px;
  margin: 4px auto;
}

.detail-button img {
  width: 30px;
  margin: 4px auto;
}

.buttonRow.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.buttonRow.row .detail-button {
  margin: 4px;
}

.buttonRow .col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.detail-button .svg-inline--fa svg {
  height: 30px;
  width: 30px;
  margin: 4px;
  vertical-align: inherit;
}
</style>
