<template>
  <div class="p-2 form-container">
    <form @submit.prevent="submitUpdate" novalidate>
      <div header-tag="header" class="p-1 w-100" role="tab">
        <sl-alert v-if="isSystemWide" variant="warning" open closable class="mb-3">
          <sl-icon slot="icon" name="exclamation-triangle"></sl-icon>
          <strong>This is a systemwide message sent across all communities</strong>
        </sl-alert>
        <sl-alert v-else-if="isResortAdmin && hasSystemConfig" variant="warning" open closable class="mb-3">
          <sl-icon slot="icon" name="exclamation-triangle"></sl-icon>
          <strong>This is NOT a systemwide message and will only be sent to users for this specific community</strong>
          <br />
          <button class="btn m-1 btn-secondary" type="button" @click.stop="sendSystemwide">Send Message Across All Communities</button>
        </sl-alert>
      </div>

      <b-accordion id="events" :headings="['Required Information', 'Dates and Scheduling', 'Additional Information']">
        <template #body="{ heading }">
          <div v-if="heading == 'Required Information'">
            <div class="mb-3">
              <label for="title" class="form-label">Title</label>
              <input
                type="text"
                class="form-control"
                id="title"
                v-model="newItem.title"
                :class="{ 'is-invalid': vuelidate$.newItem.title.$error && vuelidate$.newItem.title.$dirty }" />
              <Validation :validator="vuelidate$.newItem.title" feedback="Title is required." />
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <textarea
                class="form-control description"
                id="description"
                rows="3"
                v-model="newItem.description"
                :style="getBackgroundColorStyle"
                :class="{ 'is-invalid': vuelidate$.newItem.description.$error && vuelidate$.newItem.description.$dirty }"></textarea>
              <Validation :validator="vuelidate$.newItem.description" feedback="Description is required." />
            </div>
            <div v-if="!isDetailPage">
              <label class="form-label">Notification Type</label>

              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="audience" id="group" value="group" v-model="audience" />
                  <label class="form-check-label" for="group">Group Notification</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="audience" id="personal" value="personal" v-model="audience" />
                  <label class="form-check-label" for="personal">Personal Notification</label>
                </div>
              </div>
              <div class="row" v-if="audience == 'group' || !allowPersonal">
                <div class="col">
                  <div class="mb-3">
                    <label for="category" class="form-label">Type</label>
                    <select
                      class="form-select"
                      id="category"
                      v-model="newItem.category"
                      :class="{ 'is-invalid': vuelidate$.newItem.category.$error && vuelidate$.newItem.category.$dirty }"
                      :disabled="isEditing">
                      <option :value="null" disabled>-- Select Type --</option>
                      <option v-for="option in messageCategory" :value="option.value" :key="option.value">{{ option.text }}</option>
                    </select>
                    <Validation :validator="vuelidate$.newItem.category" feedback="Type is required." />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="userGroup" class="form-label">User Group</label>
                    <select
                      class="form-select"
                      id="userGroup"
                      v-model="newItem.userGroup"
                      :class="{ 'is-invalid': vuelidate$.newItem.userGroup.$error && vuelidate$.newItem.userGroup.$dirty }"
                      :disabled="isEditing">
                      <option :value="null" disabled>-- Select User Group --</option>
                      <option
                        v-if="
                          ![
                            'eat',
                            'shop',
                            'play',
                            'stay',
                            'visit-the-parkland',
                            'live-work',
                            'services',
                            'venues',
                            'discover',
                            'saint-theodore',
                            'st-theodore-school',
                            'events',
                            'system-data',
                          ].includes(campgroundKey)
                        "
                        value="currentGuest">
                        Current Guest/Local Users
                      </option>
                      <option value="all">All Users</option>
                      <option v-for="option in messageUserGroups" :value="option.value" :key="option.value">{{ option.text }}</option>
                    </select>
                    <Validation :validator="vuelidate$.newItem.userGroup" feedback="User group is required." />
                  </div>
                </div>
              </div>
              <UserList
                v-else-if="audience == 'personal'"
                v-model="newItem.toUser"
                :state="vuelidate$.newItem.toUser.$dirty ? !vuelidate$.newItem.toUser.$error : null" />
            </div>
          </div>

          <div v-if="heading === 'Dates and Scheduling'">
            <div class="row" v-if="(deliveryType !== 'instant' || action !== 'edit') && audience !== 'personal'">
              <div class="col">
                <div class="mb-3">
                  <label for="deliveryType" class="form-label">Delivery Type</label>
                  <select class="form-select" id="deliveryType" v-model="deliveryType">
                    <option value="instant" v-if="action !== 'edit'">Send Now</option>
                    <option value="future">Single Scheduled Notification</option>
                    <option value="recurring">Recurring Notification</option>
                  </select>
                </div>
              </div>
              <div class="col" id="recurrence-days" v-if="deliveryType == 'recurring'">
                <label class="mb-1" for="recurrence-days" title="Days that notification will be delivered">Delivery Days</label>
                <table>
                  <tbody>
                    <tr style="text-align: center">
                      <td class="ms-1"><label for="event-recur-0" class="modal-checkbox-label">M</label></td>
                      <td class="ms-1"><label for="event-recur-1" class="modal-checkbox-label">Tu</label></td>
                      <td class="ms-1"><label for="event-recur-2" class="modal-checkbox-label">W</label></td>
                      <td class="ms-1"><label for="event-recur-3" class="modal-checkbox-label">Th</label></td>
                      <td class="ms-1"><label for="event-recur-4" class="modal-checkbox-label">F</label></td>
                      <td class="ms-1"><label for="event-recur-5" class="modal-checkbox-label">Sa</label></td>
                      <td class="ms-1"><label for="event-recur-6" class="modal-checkbox-label">Su</label></td>
                    </tr>
                    <tr>
                      <td v-for="day in [0, 1, 2, 3, 4, 5, 6]" v-bind:key="day">
                        <input
                          class="form-check-input m-1"
                          type="checkbox"
                          v-if="recurrenceInfo.validDays"
                          :id="`event-recur-${day}`"
                          v-model="recurrenceInfo.validDays[day]"
                          :name="`event-recur-${day}`"
                          :disabled="newItem.recurrenceId && !editRecurrence" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-if="deliveryType === 'future' && isFromEvent === true">
              <div class="col">
                <span v-if="eventOccurrences.length > 0" class="badge bg-secondary">
                  Found {{ eventOccurrences.length }} scheduled event notification(s).
                </span>
                <div class="mb-3">
                  <label class="form-label">Deliver Before Event</label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="deliverMinute"
                      value="deliverMinute"
                      v-model="customTimeType"
                      :disabled="disableMinuteRadio"
                      @change="setCustomTime(30, 0, 0)" />
                    <label class="form-check-label" for="deliverMinute">Send thirty minutes prior</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="deliverHour"
                      value="deliverHour"
                      v-model="customTimeType"
                      :disabled="disableHourRadio"
                      @change="setCustomTime(0, 1, 0)" />
                    <label class="form-check-label" for="deliverHour">Send one hour prior</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="deliverDay"
                      value="deliverDay"
                      v-model="customTimeType"
                      :disabled="disableDayRadio"
                      @change="setCustomTime(0, 0, 1)" />
                    <label class="form-check-label" for="deliverDay">Send one day prior</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="deliverWeek"
                      value="deliverWeek"
                      v-model="customTimeType"
                      :disabled="disableWeekRadio"
                      @change="setCustomTime(0, 0, 7)" />
                    <label class="form-check-label" for="deliverWeek">Send one week prior</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="deliverCustom"
                      value="deliverCustom"
                      v-model="customTimeType"
                      @change="clearDateTime()" />
                    <label class="form-check-label" for="deliverCustom">Custom time prior</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="deliveryType !== 'instant'">
              <div class="col">
                <DateTime
                  ref="deliveryDateTime"
                  label="Delivery Time"
                  description="Time when first notification will be delivered"
                  placeholder="Select Date ..."
                  :value="newItem.deliveryTime"
                  :minDate="currentMillis"
                  format="MM/dd/yyyy hh:mm a"
                  errorMessage="Delivery Time must be in the future"
                  :isInvalid="vuelidate$.newItem.deliveryTime?.$error && deliveryType !== 'instant'"
                  @timeInvalid="setTimeValid"
                  :timeRequested="true"
                  :disabled="disableDateTime"
                  :null-default="null"
                  @update="(val) => (newItem.deliveryTime = val)">
                  <template #validation>
                    <Validation
                      :validator="
                        vuelidate$.newItem.deliveryTime.$dirty &&
                        vuelidate$.newItem.deliveryTime.$error &&
                        newItem.deliveryTime &&
                        newItem.deliveryTime < currentMillis
                      "
                      feedback="End time must be in the future" />
                    <Validation
                      :validator="vuelidate$.newItem.deliveryTime.$dirty && vuelidate$.newItem.deliveryTime.$error"
                      feedback="Date and time are required" />
                  </template>
                </DateTime>
                <Validation :validator="timeInvalid" feedback="Delivery Time is required." />
              </div>
              <div class="col" v-if="deliveryType == 'recurring'">
                <DateTime
                  ref="lastDayPicker"
                  label="Last Day of Recurrence"
                  description="The last day for this notification to be sent. This day will be included if it is part of the recurrence."
                  :value="recurrenceInfo.recurrenceEndDate"
                  :minDate="newItem.endDate"
                  errorMessage="Last Day of Recurrence must be after the Initial End Date"
                  :submitted="vuelidate$.$dirty"
                  @timeInvalid="setTimeValid"
                  :dateRequired="true"
                  :timeRequested="false"
                  @update="(val) => (recurrenceInfo.recurrenceEndDate = val)" />
              </div>
            </div>
            <div class="row">
              <div class="col" v-if="deliveryType !== 'recurring'">
                <DateTime
                  ref="endTimePicker"
                  label="End Time"
                  description="This is the time that the message will no longer be visible to users. If left blank it will
                stay listed until removed."
                  v-model="newItem.endTime"
                  :minDate="currentMillis"
                  format="MM/dd/yyyy hh:mm a"
                  :submitted="vuelidate$.$dirty"
                  :isInvalid="false"
                  :disabled="isFromEvent == true"
                  @date-state="(val) => (isDateValid = val)"
                  @time-state="(val) => (isTimeValid = val)"
                  @update="(val) => (newItem.endTime = val)">
                  <!-- (vuelidate$.newItem.endTime.$dirty || vuelidate$.newItem.endTime.$error) && !endTimeValidation() && !dateTimeStateValidation() -->
                  <template #validation>
                    <!-- <Validation
                      :validator="
                        (!vuelidate$.newItem.endTime.$dirty || !vuelidate$.newItem.endTime.$error) &&
                        newItem.endTime &&
                        newItem.endTime < currentMillis
                      "
                      feedback="End time must be in the future" />
                    <Validation
                      :validator="(!vuelidate$.newItem.endTime.$dirty || !vuelidate$.newItem.endTime.$error) && !dateTimeStateValidation()"
                      feedback="Date and time are required" /> -->
                  </template>
                </DateTime>
              </div>
              <div class="col" v-else>
                <div class="mb-3">
                  <label for="visibilityDuration" class="form-label">Visibility Duration</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="visibilityDuration"
                    v-model.number="recurrenceInfo.recurrenceDuration"
                    trim />
                  <div class="form-text">
                    The number of hours after delivery that this notification will be visible to users. If left empty it will roll off when the next
                    one is sent.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="heading === 'Additional Information'">
            <div class="mb-3" v-if="deliveryType !== 'instant' || action !== 'edit'">
              <input class="form-check-input" type="checkbox" id="sendNotification" v-model="newItem.sendNotification" />
              <label class="form-check-label" for="sendNotification">Send Notification</label>
            </div>
            <div class="mb-3">
              <input class="form-check-input" type="checkbox" id="includeImage" v-model="includeImage" />
              <label class="form-check-label" for="includeImage">Include Image</label>
            </div>
            <div class="row ms-1" v-if="includeImage">
              <div class="col">
                <Attachment
                  id="messages"
                  :attachments="attachments"
                  :title="newItem.title"
                  bucket="messages"
                  label="Upload Image"
                  :uploadMultiple="false"
                  acceptedFiles="image/png,image/jpeg" />
              </div>
            </div>
            <template v-if="allowNotificationButtons">
              <div class="row">
                <div class="col">
                  <DetailButtonList ref="actionButtons" :items="newItem.detailButtons" :isRemote="isSystemWide || this.isDetailPage" />
                </div>
              </div>
            </template>
          </div>
        </template>
      </b-accordion>

      <Validation :validator="vuelidate$" map="form" />

      <button class="btn m-1 btn-primary" type="submit" :disabled="isArchived && action === 'edit'">Submit</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import DateTime from '../DateTime2';
import UserList from '../inputfields/UserList';
import { required, url, requiredIf } from '@vuelidate/validators';
import { NonDetailFormMixin } from '../mixins/NonDetailFormMixin.js';
import DetailButtonList from '@/components/listitems/DetailButtonList.vue';
import Attachment from '@/components/Attachment.vue';
import moment from 'moment-timezone';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import BAccordion from '@/components/common/BAccordion.vue';

export default {
  components: {
    DetailButtonList,
    DateTime,
    UserList,
    Attachment,
    BAccordion,
  },
  mixins: [NonDetailFormMixin],
  props: ['item', 'dbContext', 'rightColumnKey', 'action', 'isArchived'],
  data() {
    return {
      campgroundKey: '',
      dbRef: '',
      newItem: {
        title: '',
        description: '',
        userGroup: null,
        category: null,
        url: null,
        sendNotification: true,
        isGiveaway: false,
        drawable: null,
        linkURL: false,
        formKey: null,
        storeItemKey: null,
        menuItemKey: null,
        fileUrl: null,
        toUser: null,
        detailButtons: [],
        scheduledEvent: '',
        endTime: null,
      },
      isResortAdmin: false,
      isSystemWide: false,
      origItem: {},
      audience: 'group',
      deliveryType: 'instant',
      messageCategory: [],
      messageUserGroups: [],
      locations: [],
      timeInvalid: false,
      endTimeInvalid: false,
      attachments: [],
      currentMillis: Date.now(),
      recurrenceInfo: {
        recurrence: 'none',
        validDays: [false, false, false, false, false, false, false],
        recurrenceEndDate: null,
        recurrenceDuration: null,
      },
      includeUrl: false,
      includeImage: false,
      includeFile: false,
      includeForm: false,
      includeStoreItem: false,
      includeMenuItem: false,
      allowPersonal: false,
      allowNotificationButtons: false,
      isDetailPage: true,
      customDay: 0,
      customHour: 0,
      customMinute: 0,
      isFromEvent: false,
      customTimeType: 'deliverMinute',
      disableDateTime: false,
      eventOccurrences: [],
      scheduledEvents: [],
      disableMinuteRadio: false,
      disableHourRadio: false,
      disableDayRadio: false,
      disableWeekRadio: false,
      hasSystemConfig: false,
      isDateValid: true,
      isTimeValid: true,
    };
  },
  computed: {
    isEditing: function () {
      return this.action == 'edit' && this.dbContext !== 'messages-scheduled' ? true : false;
    },
  },
  watch: {
    item: function () {
      this.changeNewItem();
    },
    audience: function () {
      this.deliveryType = this.audience == 'personal' ? 'instant' : this.deliveryType;
    },
    attachments: {
      handler: function () {
        if (this.attachments[0].downloadURL) {
          this.newItem.drawable = this.attachments[0].downloadURL;
        }
      },
      deep: true,
    },
    'newItem.title': function () {
      this.item.title = this.newItem.title;
    },
    'newItem.description': function () {
      this.item.description = this.newItem.description;
    },
  },
  validations() {
    return {
      newItem: {
        title: {
          required,
        },
        description: {
          required,
        },
        category: {
          required: requiredIf(function () {
            return (this.audience == 'group' || !this.allowPersonal) && !this.isDetailPage;
          }),
        },
        userGroup: {
          required: requiredIf(function () {
            return (this.audience == 'group' || !this.allowPersonal) && !this.isDetailPage;
          }),
        },
        formKey: {
          required: requiredIf(function () {
            return this.includeForm;
          }),
        },
        storeItemKey: {
          required: requiredIf(function () {
            return this.includeStoreItem;
          }),
        },
        menuItemKey: {
          required: requiredIf(function () {
            return this.includeMenuItem;
          }),
        },
        fileUrl: {
          required: requiredIf(function () {
            return this.includeFile;
          }),
        },
        url: {
          url,
        },
        toUser: {
          required: requiredIf(function () {
            return this.audience == 'personal';
          }),
        },
        endTime: {
          required: requiredIf(function () {
            return true; //this.endTimeValidation() && this.dateTimeStateValidation();
          }),
        },
        deliveryTime: {
          required: requiredIf(function () {
            return this.deliveryType !== 'instant';
          }),
        },
      },
      audience: {
        required: requiredIf(function () {
          return this.allowPersonal && !this.isDetailPage;
        }),
      },
    };
  },
  created() {
    this.isResortAdmin =
      window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
    this.isDetailPage = this.$route.params.uuid ? true : false;
    this.isSystemWide = this.$route.params.context === 'systemwide-notification';
    this.campgroundKey = this.isSystemWide ? 'system-data' : this.getCampgroundKey;
    this.dbRef = firebase.database().ref('resort-navigator').child('location-data').child(this.campgroundKey);

    let features = JSON.parse(window.localStorage.getItem('FEATURE_AVAILABILITY', ''));
    if (features) {
      this.allowPersonal = features.allowPersonal || false;
      this.allowNotificationButtons = features.allowNotificationButtons || false;
    }
  },
  mounted() {
    this.changeNewItem();
    this.getMessageTypes();
    this.getAttachmentList();
    if (this.dbContext == 'messages-scheduled') {
      this.deliveryType = this.newItem.nextScheduledRun ? 'recurring' : 'future';
      this.recurrenceInfo = this.newItem.recurrenceInfo || this.recurrenceInfo;
      this.$nextTick(() => {
        if (this.$refs.lastDayPicker && this.recurrenceInfo?.recurrenceEndDate == 0) {
          this.$refs.lastDayPicker.clear();
        }
      });
    }
    if (this.action == 'edit' || (this.item && this.item.category)) {
      this.audience = 'group';
    }

    this.loadFromEvent();
  },
  methods: {
    endTimeValidation() {
      return !this.newItem.endTime || this.newItem.endTime < this.currentMillis;
      //      return newItem.endTime ? (newItem.endTime == 0 ? false : this.newItem.endTime < this.currentMillis) : false
    },
    dateTimeStateValidation() {
      if (this.isDateValid == true && this.isTimeValid == false) return false;
      if (this.isDateValid == false && this.isTimeValid == true) return false;

      if (this.isDateValid == false && this.isTimeValid == false) return true;
      if (this.isDateValid == true && this.isTimeValid == true) return true;
    },
    cancelUpdate: function () {
      console.log('this.action', this.action);
      if (this.action == 'add-button') this.$emit('action', 'cancel');
      else this.$emit('action');
    },
    //Load the information from an event.
    loadFromEvent() {
      const event = JSON.parse(window.localStorage.getItem('event-notification'));
      const item = event ? event?.newItem : null;
      window.localStorage.removeItem('event-notification');
      //const item = this.$store.getters.params.newItem;

      if (item && item.isFromEvent === true) {
        console.log('Loaded event item: ', item);
        this.isFromEvent = true;

        this.newItem.title = item.title;
        this.newItem.description = item.description;
        this.newItem.userGroup = item.userGroup;
        this.newItem.location = item.location;
        this.newItem.effectiveTime = item.startDate;
        this.newItem.endTime = item.endDate;
        this.newItem.eventId = item.eventId;
        console.log('startDate', item.startDate, 'endDate', item.endDate);
        console.log('event item', this.newItem);

        this.customTimeType = 'deliverMinute';
        this.disableDateTime = true;
        const realNow = moment.tz(this.getTimeZone);
        const startHr = moment.tz(this.newItem.effectiveTime - 360000, this.getTimeZone);
        const diff = realNow.diff(startHr, 'minutes');
        if (diff >= -60) {
          this.deliveryType = 'instant';
        } else {
          this.deliveryType = 'future';
          this.$nextTick(() => {
            this.setCustomTime(30, 0, 0);
            this.$forceUpdate();
          });
        }

        this.loadOccurrences();
      }
    },
    // Populate eventOccurrences array with data from Firebase, where newItem.eventId matches the eventId in the Firebase data.
    loadOccurrences() {
      this.getLocationRef(this.campgroundKey)
        .child('messages-scheduled')
        .orderByChild('eventId')
        .equalTo(this.newItem.eventId)
        .once('value')
        .then((snapshot) => {
          snapshot.forEach((childSnapshot) => {
            this.eventOccurrences.push(childSnapshot.val());
            this.scheduledEvents.push(childSnapshot.val().scheduledEvent);
          });
        })
        .then(() => {
          console.log('events', this.scheduledEvents);
          this.updateRadio();
        });
    },
    setCustomTime(minute, hour, day) {
      if (minute === 30) this.newItem.scheduledEvent = 0;
      if (hour === 1) this.newItem.scheduledEvent = 1;
      if (day === 1) this.newItem.scheduledEvent = 2;
      if (day === 7) this.newItem.scheduledEvent = 3;
      this.newItem.deliveryTime = this.newItem.effectiveTime - (day * 86400000 + hour * 3600000 + minute * 60000);
      this.$refs.deliveryDateTime.set(this.newItem.deliveryTime);
      this.disableDateTime = true;
      this.$nextTick(() => this.$forceUpdate());
    },
    clearDateTime() {
      this.disableDateTime = false;
      this.newItem.deliveryTime = null;
      this.newItem.scheduledEvent = null;
      this.$refs.deliveryDateTime.clear();
    },
    getLocationData: function () {
      this.dbRef
        .child('locations')
        .once('value')
        .then((res) => {
          this.locations = res.val();
        });
    },
    sendSystemwide: function () {
      this.$router.push({
        name: 'listingNoMenu',
        params: { context: 'systemwide-notification' },
      });
    },
    getMessageTypes: function () {
      this.getLocationRef('system-data')
        .child('messages-config/topics')
        .once('value')
        .then((snapshotTopics) => {
          if (snapshotTopics.val()) {
            this.hasSystemConfig = true;
          }
        });

      this.getLocationRef(this.campgroundKey)
        .child('messages-config/topics')
        .once('value')
        .then((snapshotLocations) => {
          snapshotLocations.forEach((topicElement) => {
            if (topicElement.child('type').val() == 'user-group') {
              this.messageUserGroups.push({
                value: topicElement.key,
                text: topicElement.child('title').val(),
              });
            } else if (topicElement.child('type').val() == 'category') {
              this.messageCategory.push({
                value: topicElement.key,
                text: topicElement.child('title').val(),
                isGiveaway: topicElement.child('isGiveaway').val() ? true : false,
                defaultScannable: topicElement.child('defaultScannable').val() ? true : false,
              });
            }
          });
          this.setCategoryGroupType();
        });
    },
    setCategoryGroupType() {
      if (this.action !== 'edit') {
        const category =
          this.messageCategory.find((obj) => obj.text === 'General') ||
          this.messageCategory.find((obj) => obj.text === 'Messages') ||
          this.messageCategory.find((obj) => obj.text === 'Notifications');
        const activitiesCategory =
          this.messageCategory.find((obj) => obj.text === 'Activities') ||
          this.messageCategory.find((obj) => obj.text === 'Activity Alerts') ||
          this.messageCategory.find((obj) => obj.text === 'Events');

        this.newItem.category = this.isDetailPage ? null : this.isFromEvent ? activitiesCategory?.value || category?.value : category?.value;

        this.newItem.userGroup = this.isSystemWide ? 'all' : this.isFromEvent ? 'currentGuest' : null;
      }
    },
    // Updates radio button disable states if 0, 1, 2, or 3 are in scheduledEvents array.
    updateRadio() {
      console.log('Checking for scheduled events. Updating radios...', this.scheduledEvents);
      const options = ['deliverMinute', 'deliverHour', 'deliverDay', 'deliverWeek', 'deliverCustom'];
      if (this.scheduledEvents?.includes(0)) {
        const index = options.indexOf('deliverMinute');
        options.splice(index, 1);
        this.disableMinuteRadio = true;
      }
      if (this.scheduledEvents?.includes(1)) {
        const index = options.indexOf('deliverHour');
        options.splice(index, 1);
        this.disableHourRadio = true;
      }
      if (this.scheduledEvents?.includes(2)) {
        const index = options.indexOf('deliverDay');
        options.splice(index, 1);
        this.disableDayRadio = true;
      }
      if (this.scheduledEvents?.includes(3)) {
        const index = options.indexOf('deliverWeek');
        options.splice(index, 1);
        this.disableWeekRadio = true;
      }
      this.customTimeType = options[0];
      console.log(this.customTimeType);
      console.log('options after all', options);
    },
    changeNewItem: function () {
      if (this.item) {
        this.newItem = { ...{ detailButtons: [] }, ...this.item };
        this.origItem = { ...this.newItem };
      }
      this.$nextTick(() => {
        if (this.$refs.endTimePicker) {
          if (this.newItem.endTime && this.newItem.endTime !== 0) {
            this.$refs.endTimePicker.set(this.newItem.endTime);
          } else {
            this.$refs.endTimePicker.clear();
          }
        }
      });
    },
    updateAction: function (data) {
      this.newItem.action = data.action;
      this.newItem.actionKey = data.actionKey;
      this.newItem.actionParams = data.actionParams;
      this.newItem.actionCommunityKey = data.actionCommunityKey;
    },
    getAttachmentList() {
      if (this.newItem.drawable) {
        let attachment = {};
        attachment._id = this.newItem.drawable;
        attachment.title = '';
        attachment.type = 'file';
        attachment.isLoading = true;
        attachment.progress = null;
        attachment.downloadURL = this.newItem.drawable;
        this.attachments = [...this.attachments, attachment];
      }
    },
    setTimeValid: function (isInvalid) {
      this.timeInvalid = isInvalid;
    },
    setEndTimeValid: function (isInvalid) {
      if (this.newItem.endTime && this.newItem.endTime !== 0) {
        this.endTimeInvalid = isInvalid;
      }
    },
    submitUpdate: async function () {
      this.$refs.actionButtons.checkAdd();
      this.newItem.endTime++;
      this.newItem.endTime--;
      this.vuelidate$.$touch();

      // if (this.deliveryType == 'instant') {
      //   if (this.newItem.endTime && this.newItem.endTime < Date.now()) return;
      //   if (!this.dateTimeStateValidation()) return;
      // }

      this.$nextTick(async () => {
        console.log('TCL: !this.vuelidate$.$invalid', !this.vuelidate$.$invalid);
        console.log("TCL: (this.deliveryType == 'instant' || !this.timeInvalid)", this.deliveryType == 'instant' || !this.timeInvalid);
        console.log('TCL: !this.endTimeInvalid', !this.endTimeInvalid);
        if (!this.vuelidate$.$invalid && (this.deliveryType == 'instant' || !this.timeInvalid) && !this.endTimeInvalid) {
          const submitMillis = Date.now();
          this.newItem.effectiveTime = this.newItem.effectiveTime || submitMillis;
          console.log('this.newItem.endTime', this.newItem.endTime, submitMillis, 'diff', this.newItem.endTime - submitMillis);
          this.newItem.endTime = this.newItem.endTime || 0;
          if (this.action == 'add' || this.action == 'add-button') {
            this.newItem.creator = this.isDetailPage ? this.$store.getters.uuid : firebase.auth().currentUser.uid;
            this.newItem.createdTime = submitMillis;
            this.newItem.needsSentViaCF = this.newItem.sendNotification;
            this.newItem.isGiveaway =
              (this.messageCategory[this.newItem.category] && this.messageCategory[this.newItem.category].isGiveaway) || false;
            this.newItem.deliveryTime = this.deliveryType == 'instant' ? submitMillis : this.newItem.deliveryTime;
          }
          if (this.deliveryType == 'instant') {
            this.updateDB(this.dbRef.child('messages'));
          } else {
            this.newItem.effectiveTime = this.newItem.effectiveTime || this.newItem.deliveryTime;
            if (this.deliveryType == 'recurring') {
              this.newItem.nextScheduledRun = this.newItem.deliveryTime;
              this.recurrenceInfo.recurrenceEndDate = this.recurrenceInfo.recurrenceEndDate || 0;
              this.newItem = { ...this.newItem, ...{ recurrenceInfo: this.recurrenceInfo } };
            }
            const scheduledRef = this.dbRef.child('messages-scheduled');
            this.updateDB(scheduledRef);
          }
          if (this.isFromEvent) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 300);
          }
        } else {
          console.log('this.vuelidate$', this.vuelidate$);
        }
      });
    },
    updateDB: async function (dbRef) {
      var currentDbRef = dbRef || this.getContextRef();
      let newKey = null;
      if (this.isDetailPage) {
        const remoteBusinessUpdate = firebase.functions().httpsCallable('remoteBusinessUpdate');
        await remoteBusinessUpdate({
          uuid: this.$route.params.uuid,
          action: `${this.action}Message`,
          newItem: this.newItem,
          editKey: this.rightColumnKey,
          deliveryType: this.deliveryType || 'test',
        });
        this.$emit('action', 'reload');
      } else if (this.action == 'edit') {
        currentDbRef.child(this.rightColumnKey).update(this.newItem);
      } else {
        if (this.audience == 'personal') {
          const userWrittenRef = firebase.database().ref('/resort-navigator/user-written').child(this.campgroundKey);
          this.newItem.fromUser = firebase.auth().currentUser.uid;
          const toUserDbRef = userWrittenRef.child('messages').child(this.newItem.fromUser).child(this.newItem.toUser);
          let threadId = toUserDbRef.push().key;
          newKey = toUserDbRef.child(threadId).push(this.newItem).key;
          let threadInfo = {
            creator: this.newItem.creator,
            title: this.newItem.title,
            toUser: this.newItem.toUser,
            fromUser: this.newItem.fromUser,
          };
          const toUserThreadDbRef = userWrittenRef.child('message-threads').child(this.newItem.toUser);
          const fromUserThreadDbRef = userWrittenRef.child('message-threads').child(this.newItem.fromUser);
          toUserThreadDbRef.child(threadId).set(threadInfo);
          if (this.newItem.toUser !== this.newItem.fromUser) {
            fromUserThreadDbRef.child(threadId).set(threadInfo);
          }
        } else {
          newKey = currentDbRef.push(this.newItem).key;
        }
      }
      this.postNewKey(newKey);
    },
    resetItemFromDB: async function () {
      if (this.action == 'edit') {
        this.newItem = { ...this.origItem };
      }
    },
  },
};
</script>

<style>
.description,
.description.form-control:focus {
  color: white;
}
</style>
