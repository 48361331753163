<template>
  <div class="mb-3" :class="{ 'is-invalid': !state }">
    <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
      {{ label }}
    </legend>
    <select v-model="mapKey" class="form-select" @change="resetLocations">
      <option :value="null" :disabled="!isNullable">-- {{ label }} --</option>
      <option v-for="item in itemList" :key="item.value" :value="item.value">{{ item.text }}</option>
    </select>
    <Validation :validator="!state" :feedback="`${label} is required.`" />
    <div v-if="mapKey && locationList.length" class="mb-3 mt-3">
      <label class="form-label">Select Location</label>
      <Multiselect
        v-model="locationValue"
        :options="locationList"
        mode="tags"
        value="value"
        placeholder="Select location(s)"
        track-by="text"
        label="text"
        :close-on-select="true"
        :clear-on-select="false"
        hide-selected
        taggable
        :searchable="true"
        @input="locationChanged"
        @tag="addTag" />
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    isNullable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: true,
    },
    dbContext: {
      type: String,
      default: null,
    },
    keyName: {
      type: String,
      default: '',
    },
    orderBy: {
      type: String,
      default: null,
    },
    community: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemList: [],
      locationList: [],
      mapKey: this.value ? this.value.mapKey : null,
      locationValue: this.value ? this.value.locations : [],
      communityKey: null,
      locationObject: {},
    };
  },
  watch: {
    dbContext: function () {
      this.getItemList();
    },
    community: function () {
      this.getItemList();
    },
    mapKey: function () {
      this.getLocationList();
    },
    value: {
      handler: function (newItem, oldItem) {
        if (this.isObjectEmpty(oldItem) && !this.isObjectEmpty(newItem)) {
          this.mapKey = this.value ? this.value.mapKey : {};
          this.locationValue = (this.value?.locations ? this.value.locations : []).map((x) => {
            return x.value;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.value) {
      if (this.value.mapKey) {
        this.mapKey = this.value.mapKey;
      }
      if (this.value.locations) {
        this.locationValue = this.value.locations.map((x) => x.value);
      }
    }
    this.getItemList();
    this.getLocationList();
  },
  methods: {
    isObjectEmpty(objectName) {
      return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
    },
    resetLocations() {
      this.locationValue = [];
      this.$emit('input', { mapKey: this.mapKey });
      if (this.mapKey) {
        this.getLocationList();
      }
    },
    async getItemList() {
      this.communityKey = this.community || this.getCampgroundKey;
      this.itemList = [];
      let dbRef = this.getContextRef(this.dbContext, this.communityKey);
      if (this.orderBy) {
        dbRef = dbRef.orderByChild(this.orderBy);
      }
      const items = await dbRef.once('value');
      this.itemList = [];
      items.forEach((element) => {
        const itemSelection = {
          value: !this.keyName ? element.key : element.child(this.keyName).val(),
          text: element.child('title').val() || element.child('name').val(),
        };
        this.itemList.push(itemSelection);
      });
      if (this.itemList.length == 1 && !this.isNullable) {
        this.mapKey = this.itemList[0].value;
        this.getLocationList();
      }
    },
    async getLocationList() {
      this.locationList = [];
      if (!this.mapKey) return;
      const forms = await this.getContextRef(`${this.dbContext}/${this.mapKey}/locations`).orderByChild('title').once('value');
      this.locationList = [];
      this.locationObject = {};
      forms.forEach((element) => {
        const itemSelection = {
          value: element.child('key').val(),
          text: element.child('title').val(),
        };
        this.locationObject[element.child('key').val()] = element.child('title').val();
        this.locationList.push(itemSelection);
      });
    },
    addTag: function (newTag) {
      this.locationValue.push({ text: newTag });
      this.emitValue();
    },
    locationChanged: function (value) {
      const tags = value
        .filter((x) => x.value !== null)
        .map((x) => {
          return { text: this.locationObject[x], value: x };
        });
      this.emitValue(tags);
    },
    emitValue: function (tags) {
      this.$emit('input', { mapKey: this.mapKey, locations: tags });
    },
    displayInvalidAlert: function (msg) {
      this.$message.create({
        title: 'Invalid Choice',
        body: msg,
        classes: 'amc-modal',
        buttons: [this.$message.button('OK')],
      });
    },
  },
};
</script>

<style lang="scss">
.multiselect input,
.multiselect__input {
  min-width: 0px;
}
.add-item {
  font-size: small;
  float: right;
}
</style>
