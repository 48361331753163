<template>
  <div>
    <button v-if="locationData.importId && !locationData.placeId && !linkingPlace" class="btn m-1 btn-primary" type="button" @click="linkPlace()">
      Link with Google Places
    </button>
    <div class="mb-3" v-else-if="locationData.importId && locationData.placeId && !linkingPlace">
      <button class="btn m-1 btn-primary" type="button" @click="linkPlace()">Link with New Google Place</button>
      <small class="form-text text-muted">
        Currently linked to Google Place:
        <a :href="getPlacesLink" target="_blank"> {{ locationData.title }} </a>
      </small>
    </div>
    <div v-show="!locationData.importId || linkingPlace" id="search" class="position-relative">
      <span class="position-absolute h-100">
        <i class="material-icons position-relative">search</i>
      </span>
      <input
        ref="autocomplete"
        :placeholder="placeholder"
        class="search-location form-control"
        :onfocus="onFocusValue"
        type="text"
        style="text-indent: 20px"
        @keydown.enter.prevent="" />
    </div>
    <div class="mb-3" v-if="displayTitle">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        v-if="this.type == 'event'"
        v-model="locationData.location"
        :class="{ 'is-invalid': titleState }"
        trim
        @keyup="updateLocation()" />
      <input
        type="text"
        class="form-control"
        v-else
        v-model="locationData.title"
        :class="{ 'is-invalid': titleState }"
        trim
        @keyup="updateLocation()" />
      <Validation :validator="vuelidate$.locationData.title" feedback="Title is required." />
    </div>
    <div v-if="!hideComponents">
      <div class="mb-3">
        <label class="form-label">Address</label>
        <input type="text" class="form-control" v-model="locationData.address" trim @keyup="updateLocation()" />
      </div>
      <div class="row">
        <div class="col">
          <input type="text" class="form-control" v-model="locationData.city" placeholder="City" trim @keyup="updateLocation()" />
        </div>
        <div class="col">
          <input type="text" class="form-control" v-model="locationData.state" placeholder="State" trim @keyup="updateLocation()" />
        </div>
        <div class="col">
          <input type="text" class="form-control" v-model="locationData.zip" placeholder="Zip" trim @keyup="updateLocation()" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Latitude</label>
            <input
              type="number"
              class="form-control no-spinners"
              v-model.number="locationData.latitude"
              :class="{
                'is-invalid': vuelidate$.locationData.latitude && vuelidate$.locationData.latitude.$dirty && vuelidate$.locationData.latitude.$error,
              }"
              trim
              @keyup="updateLocation()" />
            <Validation :validator="vuelidate$.locationData.latitude" feedback="Latitude is required to map accurately." />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label class="form-label">Longitude</label>
            <input
              type="number"
              class="form-control no-spinners"
              v-model.number="locationData.longitude"
              :class="{
                'is-invalid':
                  vuelidate$.locationData.longitude && vuelidate$.locationData.longitude.$dirty && vuelidate$.locationData.longitude.$error,
              }"
              trim
              @keyup="updateLocation()" />
            <Validation :validator="vuelidate$.locationData.longitude" feedback="Longitude is required to map accurately." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, decimal } from '@vuelidate/validators';
export default {
  props: {
    dataObject: Object,
    type: String,
    submitted: Boolean,
    titleRequired: Boolean,
    gpsRequired: Boolean,
    hideComponents: {
      type: Boolean,
      default: false,
    },
    displayTitle: {
      type: Boolean,
      default: true,
    },
    prefill: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search for Location',
    },
  },
  data() {
    return {
      locationData: {
        location: '',
        title: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        latitude: null,
        longitude: null,
        placeId: '',
        importId: '',
        phone: '',
        website: '',
        distance: null,
        always: false,
        hours: {
          Sun: [{ close: '?', open: '?' }],
          Mon: [{ close: '?', open: '?' }],
          Tue: [{ close: '?', open: '?' }],
          Wed: [{ close: '?', open: '?' }],
          Thu: [{ close: '?', open: '?' }],
          Fri: [{ close: '?', open: '?' }],
          Sat: [{ close: '?', open: '?' }],
        },
      },
      componentForm: {
        street_number: {
          comp: 'short_name',
          variable: 'address',
        },
        route: {
          comp: 'long_name',
          variable: 'address',
        },
        locality: {
          comp: 'long_name',
          variable: 'city',
        },
        administrative_area_level_1: {
          comp: 'short_name',
          variable: 'state',
        },
        postal_code: {
          comp: 'short_name',
          variable: 'zip',
        },
      },
      geoLocation: {},
      linkingPlace: false,
    };
  },
  computed: {
    titleState: function () {
      if (this.vuelidate$.locationData.location) {
        return this.vuelidate$.locationData.location.$dirty ? !this.vuelidate$.locationData.location.$error : null;
      } else if (this.vuelidate$.locationData.title) {
        return this.vuelidate$.locationData.title.$dirty ? !this.vuelidate$.locationData.title.$error : null;
      }
      return null;
    },
    onFocusValue: function () {
      return this.linkingPlace ? '' : "value = ''";
    },
    getPlacesLink: function () {
      return `https://www.google.com/maps/search/?api=1&query=${this.locationData.address}&query_place_id=${this.locationData.placeId}`;
    },
  },
  watch: {
    dataObject: {
      handler: function () {
        this.updateObject();
      },
      deep: true,
    },
    submitted: function () {
      this.validateLocation();
    },
  },
  validations() {
    const locationData = {};
    if (this.gpsRequired !== false) {
      locationData.latitude = {
        required,
        decimal,
      };
      locationData.longitude = {
        required,
        decimal,
      };
    }
    if (this.titleRequired !== false) {
      if (this.type == 'event') {
        locationData.location = { required };
      } else {
        locationData.title = { required };
      }
    }
    return {
      locationData,
    };
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete);
    this.geoLocation = {
      lat: parseFloat(this.getLatitude),
      lng: parseFloat(this.getLongitude),
    };
    if (this.getLatitude) {
      // eslint-disable-next-line no-undef
      const circle = new google.maps.Circle({
        center: this.geoLocation,
        radius: 10000,
      });
      this.autocomplete.setBounds(circle.getBounds());
    }
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.locationData.placeId = place.place_id;
      if (!this.linkingPlace || this.prefill) {
        if (this.type == 'event') {
          this.locationData.location = place.name;
        } else {
          this.locationData.title = place.name;
        }

        this.locationData.phone = place.formatted_phone_number ? place.formatted_phone_number : null;
        this.locationData.website = place.website ? place.website : null;
        this.locationData.latitude = this.type !== `business` ? place.geometry.location.lat() : String(place.geometry.location.lat());
        this.locationData.longitude = this.type !== `business` ? place.geometry.location.lng() : String(place.geometry.location.lng());
        this.setAddress(place);
        if (['create', 'business', 'community'].includes(this.type)) {
          this.locationData.photos = place.photos;
        }
        if (this.type === 'create') {
          this.locationData.types = place.types;
        } else {
          this.setDistance();
          this.setHours(place);
        }
      }
      this.linkingPlace = false;
      this.updateLocation();
    });
    this.validateLocation();
    if (this.prefill) {
      this.linkPlace();
    }
  },
  created() {
    this.updateObject();
  },
  methods: {
    validateLocation: function () {
      if (this.submitted == true) {
        this.vuelidate$.$touch();
        this.$emit('locationInvalid', this.vuelidate$.$invalid);
      }
    },
    linkPlace: function () {
      this.linkingPlace = true;
      this.$nextTick(() => {
        this.$refs.autocomplete.value = this.locationData.title || this.locationData.location;
        this.$refs.autocomplete.focus();
      });
    },
    updateObject: function () {
      console.log('locationData', this.locationData);
      console.log('dataObject', this.dataObject);

      if (!this.dataObject) {
        return;
      }

      let fields = ['address', 'city', 'state', 'zip', 'latitude', 'longitude', 'placeId'];
      if (this.type == 'event') {
        fields.push('location');
      } else if (this.type == 'business') {
        fields.push('phone');
        fields.push('website');
        fields.push('distance');
        fields.push('hours');
        fields.push('title');
        fields.push('importId');
      } else if (this.type == 'location') {
        fields.push('title');
      } else if (this.type == 'community') {
        fields.push('title');
        fields.push('phone');
        fields.push('website');
      } else if (this.type == 'create') {
        fields.push('title');
        fields.push('phone');
        fields.push('website');
        fields.push('types');
      }
      fields.forEach((field) => {
        this.locationData[field] = this.dataObject[field] || this.dataObject[field] === 0 ? this.dataObject[field] : null;
      });
    },
    updateLocation: function () {
      this.$emit('updateLocation', this.locationData);
      this.validateLocation();
    },
    setAddress: function (place) {
      let fullStreetAddress = '';
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (this.componentForm[addressType]) {
          const val = place.address_components[i][this.componentForm[addressType].comp];
          if (addressType == 'street_number') {
            fullStreetAddress = val + ' ' + fullStreetAddress;
          } else if (addressType == 'route') {
            fullStreetAddress = fullStreetAddress + val;
          } else {
            this.locationData[this.componentForm[addressType].variable] = val;
          }
        }
      }

      this.locationData.address = fullStreetAddress;
    },
    setDistance: function () {
      if (this.geoLocation.lat) {
        // eslint-disable-next-line no-undef
        var directionsService = new google.maps.DirectionsService();
        directionsService.route(
          {
            origin: this.geoLocation,
            destination: { placeId: this.locationData.placeId },
            travelMode: 'DRIVING',
            // eslint-disable-next-line no-undef
            unitSystem: google.maps.UnitSystem.IMPERIAL,
          },
          (response, status) => {
            if (status === 'OK') {
              var distance = response.routes[0].legs[0].distance.text;
              if (distance.indexOf(' ft') > -1) {
                this.locationData.distance = 0;
              } else {
                var spaceIndex = distance.indexOf(' ');
                this.locationData.distance = parseFloat(distance.slice(0, spaceIndex), 10);
              }
            } else {
              this.locationData.distance = this.getDistanceDirect;
            }
            this.updateLocation();
          }
        );
      }
    },
    getDistanceDirect: function () {
      var p = 0.017453292519943295; // Math.PI / 180
      var c = Math.cos;
      var a =
        0.5 -
        c((this.locationData.latitude - this.geoLocation.lat) * p) / 2 +
        (c(this.geoLocation.lat * p) * c(this.locationData.latitude * p) * (1 - c((this.locationData.longitude - this.geoLocation.lng) * p))) / 2;
      return Math.round(127420 * Math.asin(Math.sqrt(a))) / 10; // 2 * R; R = 6371 km
    },
    setHours: function (place) {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

      const hoursStruct = {
        Sun: [{ close: '?', open: '?' }],
        Mon: [{ close: '?', open: '?' }],
        Tue: [{ close: '?', open: '?' }],
        Wed: [{ close: '?', open: '?' }],
        Thu: [{ close: '?', open: '?' }],
        Fri: [{ close: '?', open: '?' }],
        Sat: [{ close: '?', open: '?' }],
      };
      if (typeof place.opening_hours !== 'undefined' && typeof place.opening_hours.periods !== 'undefined') {
        const hours = place.opening_hours.periods;
        if (hours.length == 1 && !hours[0].close) {
          this.locationData.always = true;
        } else {
          let prevDay = -1;
          for (var i = 0; i < hours.length; i++) {
            var currentDay = hours[i];
            while (currentDay.open.day > prevDay + 1) {
              this.$delete(hoursStruct, days[prevDay + 1]);
              prevDay++;
            }
            const currentHourSet = {
              open: this.getTime(currentDay.open),
              close: this.getTime(currentDay.close),
            };
            if (prevDay == currentDay.open.day) {
              hoursStruct[days[currentDay.open.day]].push(currentHourSet);
            } else {
              hoursStruct[days[currentDay.open.day]][0] = currentHourSet;
            }
            prevDay = currentDay.open.day;
          }
          while (7 > prevDay + 1) {
            this.$delete(hoursStruct, days[prevDay + 1]);
            prevDay++;
          }
          this.locationData.always = false;
        }
        this.locationData.hours = hoursStruct;
      }
      this.updateLocation();
    },
    getTime: function (timeStruct) {
      if (typeof timeStruct !== 'undefined') {
        let time = timeStruct.hours;
        let ampm = time !== 24 && time >= 12 ? 'PM' : 'AM';
        time = time > 12 ? time - 12 : time;
        time = time == 0 ? 12 : time;
        const minutes = timeStruct.minutes;
        if (minutes > 0) {
          time = minutes < 10 ? `${time}:0${minutes}` : `${time}:${minutes}`;
        }
        return `${time} ${ampm}`;
      } else {
        return '?';
      }
    },
  },
};
</script>

<style scoped>
#search > span {
  left: 0px;
}

i {
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  color: #6c757d;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinners {
  -moz-appearance: textfield;
}
</style>
