<template>
  <div class="form-group">
    <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="iconType" id="standardIcon" :value="false" v-model="iconData.isCustom" />
        <label class="form-check-label" for="standardIcon"> Standard Icon </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="iconType" id="customIcon" :value="true" v-model="iconData.isCustom" />
        <label class="form-check-label" for="customIcon"> Custom Icon </label>
      </div>
    </div>
    <template v-if="iconData.isCustom">
      <div class="mb-3">
        <div class="mb-3">
          <input
            class="form-control"
            type="file"
            id="formFile"
            placeholder="Select an .svg, or drop it here..."
            accept="image/svg+xml, .svg"
            v-on:change="setSVG"
            ref="fileInput" />
          <!-- <label class="form-label" for="formFile">Select an .svg, or drop it here...</label> -->
        </div>
        <small style="color: red" v-if="this.isInvalidFile">Please only upload valid .svg files.</small>
        <div class="d-flex">
          <div
            :style="{ width: '100px', height: '100px', color: colorIconShape }"
            :class="{ 'use-default-color': !iconData.noTint }"
            v-html="iconData.svg" />
        </div>
        <div id="formFileHelp" class="form-text">Please test this in both iOS and Android as sometimes SVGs have issues.</div>
      </div>
      <div class="mb-3" v-if="!iconOnly">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="noTintCheckbox" v-model="iconData.noTint" />
          <label class="form-check-label" for="noTintCheckbox"> Use colors from icon </label>
        </div>
      </div>
    </template>
    <cool-select
      v-else-if="!spinner"
      id="icon-list"
      :disabled="disabled"
      v-model="iconSelected"
      :items="paginated"
      placeholder="🔍 Search/Select Icon"
      :inputStyles="getInputStyles"
      disable-filtering-by-search
      @focus="onOpen"
      @blur="onClose"
      @search="onIconSearchTimer"
      @select="updateValue">
      <!-- slot for each item in the menu -->
      <template #item="{ item: icon }">
        <div :style="getIconStyle" class="text-center p-2 position-relative icon-wrapper">
          <div v-if="!iconOnly" v-html="getSVG(iconData.iconSet)" class="icon-bg" />
          <div v-html="iconsSearched[icon]?.svg[iconsSearched[icon].styles[0]].raw" class="fa-size svg-inline--fa fa-w-14" />
        </div>
      </template>
      <!-- slot for the selected item (in the text field) -->
      <template #selection="{ item: icon }">
        <div :style="getIconStyle" class="text-center p-2 icon-selected position-relative icon-wrapper">
          <div v-if="!iconOnly" v-html="getSVG(iconData.iconSet)" class="icon-bg" />
          <div v-html="iconData.svg" class="fa-size svg-inline--fa fa-w-14" />
          <div :id="icon" />
        </div>
      </template>
      <template #after-items>
        <div ref="load" class="loader" v-show="hasNextPage" :style="getInputStyles">
          <button class="btn m-1 btn-primary" type="submit" @click.prevent="limit += limitIncrement">Click to load more icons.</button>
        </div>
      </template>
      <!--template #input-end>
        <span v-if="vuelidate$.newItem.icon.$error" style="margin-right: 8px;"
          ><img
            style="width: calc(.75em + .375rem); height: calc(.75em + .375rem) ;"
            src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'><path stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/><circle r='.5'/><circle cx='3' r='.5'/><circle cy='3' r='.5'/><circle cx='3' cy='3' r='.5'/></svg>"
        /></span>
      </template-->
      <template v-if="isAISearched" #after-items-fixed>
        <button class="btn btn-secondary" style="width: 48%; margin: 4px" @click.prevent="resetIcons()">Reset Icons</button>
        <button class="btn btn-primary" style="width: 48%; margin: 4px" @click.prevent="getIconsAI()">Search Icons</button>
      </template>
    </cool-select>
    <div v-if="spinner && iconData.isCustom == false" id="spinner-container" :style="getInputStyles">
      <div style="display: flex">
        <!-- Bootstrap spinner -->
        <div class="spinner-border" role="status" style="width: 20px; height: 20px; margin-right: 8px" />
        <div>Finding the perfect icons...</div>
        <div style="display: flex; flex-grow: 2" />
        <i
          class="material-icons"
          style="color: #cc3d3d; cursor: pointer"
          @click.prevent="
            isCancelledAI = true;
            spinner = false;
          "
          >close</i
        >
      </div>
    </div>

    <div v-if="!iconOnly && ((iconSelected && Object.keys(iconSelected).length) || iconData.isCustom)">
      <div class="mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="customizeColorCheckbox" v-model="overrideDefaultColors" />
          <label class="form-check-label" for="customizeColorCheckbox"> Customize Icon Color </label>
        </div>
      </div>
      <div class="row g-3" v-if="overrideDefaultColors">
        <div class="col">
          <div class="mb-3">
            <label for="iconColor" class="form-label">Icon Color</label>
            <div class="color-selector">
              <button type="button" class="btn btn-light" @click.stop="editIcon = true">
                <div :style="`background-color:${colorIconShape}`">&nbsp;</div>
              </button>
            </div>
            <div id="iconColorHelp" class="form-text">Color used for icon</div>
            <chrome-picker id="editIcon" v-show="editIcon" v-model="colorIconShape" v-click-outside="onColorClose" />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="iconBackgroundColor" class="form-label">Icon Background Color</label>
            <div class="color-selector">
              <button type="button" class="btn btn-light" @click.stop="editIconBackground = true">
                <div :style="`background-color:${colorIconBackground}`">&nbsp;</div>
              </button>
            </div>
            <div id="iconBackgroundColorHelp" class="form-text">Color used for icons background color</div>
            <chrome-picker id="editIconBackground" v-show="editIconBackground" v-model="colorIconBackground" v-click-outside="onColorClose" />
          </div>
        </div>
      </div>
      <button v-if="getColorIconShapeRandom || getColorIconBackgroundRandom" class="btn m-1 btn-secondary" @click.prevent="updateRandomIconColors()">
        Generate New Color(s)
      </button>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="overrideIconSetCheckbox" v-model="overrideIconSet" />
        <label class="form-check-label" for="overrideIconSetCheckbox"> Override Icon Set </label>
      </div>
      <IconSet
        v-if="overrideIconSet"
        v-model="iconData.iconSet"
        :colorIconShape="colorIconShape"
        :colorIconBackground="colorIconBackground"
        :icon="this.iconsSearched[iconSelected] ? this.iconsSearched[iconSelected] : this.iconData" />
    </div>
  </div>
</template>

<script>
import IconSet from './IconSet';
import { CoolSelect } from 'vue-cool-select';
import { Chrome } from 'vue-color';
import axios from 'axios';
import OpenAIMixin from '../mixins/OpenAIMixin';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { fas as free } from '@fortawesome/free-solid-svg-icons';
import { fas as pro } from '@fortawesome/pro-solid-svg-icons';
import { fab as brands } from '@fortawesome/free-brands-svg-icons';

export default {
  components: {
    CoolSelect,
    'chrome-picker': Chrome,
    IconSet,
  },
  mixins: [OpenAIMixin],
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
    iconOnly: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
    },
    transparentBackground: {
      type: Boolean,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      iconsSearched: {}, //Changed from allIconsObject, as the meaning has since changed.
      iconsTitles: [], //Changed from filteredIcons, since the functionality has changed and icons are no longer filtered from iconsSearched.
      customIcons: [],
      customIconsArray: [],
      focusOn: true, // <- set this to false
      icons: [],
      hoverPanel: false,
      search: '',
      iconSelected: {},
      iconSelectedTitle: '',
      beforeSelect: '',
      selected: '',
      colorIconShape: this.getIconColor,
      colorIconBackground: '#FFFFFF',
      editIcon: false,
      editIconBackground: false,
      overrideDefaultColors: false,
      overrideIconSet: false,
      iconData: {},
      observer: null,
      limitIncrement: 50,
      limit: 50,
      svgFile: null,
      isInvalidFile: false,
      assistant: null,
      aiSearchTimeoutId: null,
      userSearchTimeoutId: null,
      spinner: false,
      isAISearched: false,
      interval: null,
      thread: null,
      run: null,
      preventAISearch: false,
      isCancelledAI: false,
      iconDisplay: {},
    };
  },
  computed: {
    paginated() {
      return this.iconsTitles.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.iconsTitles.length;
    },
    getIconStyle: function () {
      if (this.iconOnly) {
        return `background-color: ${this.getAccentColor}; color: #FFFFFF`;
      }
      return `background-color: ${this.getBackgroundColor}; color: ${this.colorIconShape}`;
    },
    getInputStyles: function () {
      if (this.transparentBackground) {
        return { 'background-color': 'transparent', border: '0' };
      }

      if (this.iconOnly) {
        return { 'background-color': this.getAccentColor };
      }

      return { 'background-color': this.getBackgroundColor };
    },
  },
  watch: {
    overrideDefaultColors: function () {
      if (!this.overrideDefaultColors) {
        this.iconData.colorIconShape = null;
        this.iconData.colorIconBackground = null;
      }
    },
    colorIconBackground: function () {
      this.colorIconBackground = this.colorIconBackground.hex || this.colorIconBackground;
      this.iconData.colorIconBackground = this.colorIconBackground;
      this.updateValue();
    },
    colorIconShape: function () {
      this.colorIconShape = this.colorIconShape.hex || this.colorIconShape;
      this.iconData.colorIconShape = this.colorIconShape;
      this.updateValue();
    },
    overrideIconSet: function () {
      this.updateValue();
    },
    iconSelected: function () {
      if (!this.iconData.isCustom) this.iconData.title = this.iconSelected;
      if (!this.iconSelected) {
        //this.iconData.svg = null;
        this.iconsTitles = this.icons;
      }
    },
    iconData: {
      handler: function () {
        this.updateValue();
        if (!this.iconData.title) {
          this.resetIcons();
        }
      },
      deep: true,
    },
    itemId: function () {
      this.setupIconObject();
    },
    value: function () {
      if (this.value.title && this.iconSelected !== this.value.title) {
        this.setupIconObject();
      }
    },
    title: function () {
      if (this.aiSearchTimeoutId) {
        clearTimeout(this.aiSearchTimeoutId);
      }
      this.aiSearchTimeoutId = setTimeout(() => {
        if (this.title?.length > 0 && !this.iconSelected) {
          this.isAISearched = true;
          this.getIconsAI();
        }
      }, 1000);
    },
  },
  async created() {
    this.isResortAdmin =
      window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;

    library.add(free, pro, brands);

    const { customIcons } = (await axios.get(`/json/faIcons.json`)).data;
    this.customIcons = customIcons;
    this.customIconsArray = [...Object.keys(customIcons).map((x) => x)];
    this.customIconsArray.sort();
    this.setupIconObject();
  },
  async mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    this.assistant = await this.getAssistant(this.assistantMap('icon-selector'));

    this.searchCustomIcons('');
  },
  methods: {
    setSVG: function (event) {
      this.svgFile = event.target.files[0];
      console.log('setSVG');
      let success = true;
      //Returns file as a string if it is an svg.
      const fileLoaded = (result) => {
        if (result) {
          //If this.iconData.svg contains an <image> tag, set to null.
          if (result.includes('<image')) {
            this.$message.create({
              title: 'Error',
              body: 'This svg is incompatible. Only upload svgs without <image> tags.',
              classes: 'amc-modal',
              buttons: [this.$message.button('Dismiss')],
            });
            success = false;
            this.svgFile = null;
            this.iconData.svg = null;
          } else {
            this.iconData.svg = result.replace(/(width|height)="[^"]*"/g, '');
            const viewBoxMatch = this.iconData.svg.match(/viewBox="([^"]*)"/);
            //Check for viewbox attribute value if it exists.
            if (viewBoxMatch) {
              //Get viewbox attribute value.
              const viewBox = viewBoxMatch[1];
              //Extract variables from text with regex, checking for either , or space or both as a delimiter.
              const [x, y, width, height] = viewBox.split(/[, ]+/);
              //Replace matched viewbox with new viewbox values.
              this.iconData.svg = this.iconData.svg.replace(/viewBox="([^"]*)"/, `viewBox="${x}, ${y}, ${width}, ${height}"`);
            }
            this.iconData.isCustom = true;
            this.isInvalidFile = false;
            this.iconSelected = this.iconData;
            this.iconData.styles = ['solid'];

            this.colorIconShape = this.iconData.colorIconShape
              ? this.iconData.colorIconShape
              : this.iconData.iconSet == 'svg_plain'
              ? '#ffffff'
              : this.getIconColor;
            this.colorIconBackground = this.iconData.colorIconBackground || this.getIconBackgroundColor;
          }
        }
      };

      if (success) {
        this.loadFileAsText(this.svgFile, 'image/svg+xml', fileLoaded, (f) => {
          this.isInvalidFile = true;
          console.log('Invalid .svg type in IconList.', f);
        });
      }
    },
    setupIconObject: function () {
      this.iconsTitles = this.filterCustomIcons(this.value.title);
      let tempItem = { ...this.value };
      if (Object.keys(tempItem).length == 0) {
        tempItem = this.generateRandomIconColors(this.iconData, this.getColorIconShapeRandom, this.getColorIconBackgroundRandom, this.getIconSet());
      }
      this.overrideDefaultColors = Boolean(tempItem.colorIconShape || tempItem.colorIconBackground);
      this.overrideIconSet = Boolean(tempItem.iconSet);
      this.iconData = {
        ...{ iconSet: this.getIconSet(), title: '', isCustom: false, svg: null, noTint: false },
        ...tempItem,
      };
      this.iconSelected = tempItem.title || null;

      this.colorIconShape = this.iconData.colorIconShape
        ? this.iconData.colorIconShape
        : this.iconData.iconSet == 'svg_plain'
        ? '#ffffff'
        : this.getIconColor;
      this.colorIconBackground = this.iconData.colorIconBackground || this.getIconBackgroundColor;
    },
    updateRandomIconColors: function () {
      const tempItem = this.generateRandomIconColors(
        this.iconData,
        this.getColorIconShapeRandom,
        this.getColorIconBackgroundRandom,
        this.getIconSet()
      );
      this.colorIconBackground = tempItem.colorIconBackground;
      this.colorIconShape = tempItem.colorIconShape;
    },
    updateValue: function () {
      let newIconData = { ...this.iconData };
      if (!this.overrideDefaultColors) {
        newIconData.colorIconShape = null;
        newIconData.colorIconBackground = null;
      }
      if (!this.overrideIconSet) {
        newIconData.iconSet = null;
      }
      if (!newIconData.isCustom && newIconData.isCustom === false) {
        //const iconObject = this.iconsSearched[newIconData.title];
        const iconObject = this.getIconSVG(newIconData.title);
        if (iconObject) {
          const iconStyle = iconObject.styles && iconObject.styles.length > 0 ? iconObject.styles[0] : 'solid';
          newIconData.svg = iconObject.svg[iconStyle].raw;
          if (newIconData.svg != null) this.iconData.svg = newIconData.svg;

          if (this.iconsTitles.includes(newIconData.title) === false) {
            this.iconsTitles.push(newIconData.title);
            this.iconsSearched[newIconData.title] = iconObject;
          }
        }
      }
      this.$emit('input', newIconData);
      console.log('new icon', newIconData);
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += this.limitIncrement;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    //Called by vue-cool-select on search. This helps prevent constant searching after every keypress.
    //Searches for icons in both customIcons and Font Awesome.
    async onIconSearchTimer(searchText) {
      if (this.userSearchTimeoutId) {
        clearTimeout(this.userSearchTimeoutId);
      }
      this.userSearchTimeoutId = setTimeout(async () => {
        if (searchText.length > 0 && !this.iconSelected) {
          this.iconsSearched = {};
          this.iconsTitles = [];

          this.isAISearched = false;
          await this.searchCustomIcons(searchText);
          await this.searchFontAwesome(searchText);

          console.log(`Found ${this.iconsTitles.length} icons`, this.iconsTitles);
        }
      }, 250);
    },
    async resetIcons() {
      this.isAISearched = false;
      this.iconsSearched = {};
      this.iconsTitles = [];
      await this.searchCustomIcons('');
    },

    searchCustomIcons(searchText) {
      const results = this.filterCustomIcons(searchText);

      this.iconsTitles.push(...results);
      results.forEach((i) => {
        if (this.customIcons[i] && !this.iconsSearched[i]) {
          this.iconsSearched[i] = this.customIcons[i];
        }
      });
    },
    filterCustomIcons: function (searchText) {
      this.search = searchText;
      const search = ((this.search == this.selected ? this.beforeSelect : this.search) || '').toLowerCase();
      return !search ? this.customIconsArray : this.customIconsArray.filter((i) => this.filterCustomFunction(i, search));
    },
    filterCustomFunction: function (i, search) {
      const icon = this.customIcons[i];
      return i.indexOf(search) !== -1 || icon.search.terms.some((t) => String(t).indexOf(search) !== -1);
    },

    async searchFontAwesome(searchText) {
      if (searchText?.length > 0) {
        let response;
        try {
          response = await fetch('https://api.fontawesome.com/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              query: `query SearchIcons { search(version: "6.5.1", query: "${searchText}", first: 50) { id } }`,
            }),
          });
        } catch (err) {
          console.log('Error querying Font Awesome:', err);
          return;
        }

        const data = await response.json();
        if (data.errors) {
          console.log('Error querying Font Awesome:', data.errors);
          return;
        }
        this.iconsTitles.push(...data.data.search.map((x) => x.id));
        this.iconsTitles.forEach((i) => {
          this.addFAIcon(i);
        });
      }
    },
    addFAIcon(name) {
      const iconBrand = icon({ prefix: 'fab', iconName: name });
      const iconSolid = icon({ prefix: 'fas', iconName: name });

      const iconData = iconBrand || iconSolid;
      if (iconData) {
        const newIcon = {
          label: iconData.iconName,
          styles: ['solid'],
          iconSet: this.getIconSet(),
          svg: { solid: { raw: iconData.html[0].replace(' fill="currentColor"', '') } },
        };
        this.iconsSearched[iconData.iconName] = newIcon;
        // console.log('Added icon:', iconData.iconName, newIcon);
      } else {
        // console.log('Icon not found:', name);
      }
    },
    getIconSVG(name) {
      if (this.customIcons[name]) {
        const result = {
          label: name,
          styles: ['custom'],
          svg: { custom: { raw: this.customIcons[name].svg.custom.raw } },
        };
        return result;
      } else {
        const iconBrand = icon({ prefix: 'fab', iconName: name });
        const iconSolid = icon({ prefix: 'fas', iconName: name });

        const iconData = iconBrand || iconSolid;
        if (iconData) {
          const result = {
            label: iconData.iconName,
            styles: ['solid'],
            iconSet: this.getIconSet(),
            svg: { solid: { raw: iconData.html[0].replace(' fill="currentColor"', '') } },
            title: iconData.iconName,
          };
          return result;
        } else return null;
      }
    },

    onColorClose: function () {
      this.editIcon = false;
      this.editIconBackground = false;
    },

    async getIconsAI() {
      if (this.preventAISearch === true) {
        // this.preventAISearch = false;
        return;
      }

      const message = { role: 'user', content: `get_icons(prompt: "${this.title}", quantity: "10", isDebug: "false")` };
      console.log('Prompting with message:', message.content);
      try {
        this.spinner = true;
        this.thread = await this.createThread();
        await this.createMessage(this.thread.data.id, message);
        this.run = await this.runAssistant(this.assistant.data.id, this.thread.data.id, '');
        await this.checkStatus();
      } catch (err) {
        this.spinner = false;
        console.log('Error reading icon assistant JSON', err);
        this.iconsTitles = this.icons;
        this.$forceUpdate();
      }
    },
    async checkStatus() {
      this.interval = setInterval(async () => {
        if (this.isCancelledAI == true) {
          this.cancelSearch();
        } else {
          const status = await this.runStatus(this.thread.data.id, this.run.data.id);
          if (status.data.status === 'requires_action') {
            this.readJSON(this.getJSONOutput(status));
            this.cancelSearch();
            console.log('Run status completed: ', status);
          } else if (status.data.status === 'failed') {
            this.cancelSearch();
            console.log('run status failed', status);
          }
        }
      }, 2500);
    },
    readJSON(json) {
      this.iconsSearched = {};
      this.iconsTitles = [];
      console.log('Reading get_icons json:', json);

      json.list.forEach((item) => {
        this.addFAIcon(item.icon);
      });
      for (const key in this.iconsSearched) {
        this.iconsTitles.push(key);
      }
      this.searchFontAwesome(this.title);

      this.$forceUpdate();
    },
    async cancelSearch() {
      this.spinner = false;
      clearInterval(this.interval);
      await this.runCancel(this.thread.data.id, this.run.data.id);
      this.isCancelledAI = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-selector div {
  width: 20px;
  height: 20px;
}
.color-selector .btn {
  padding: 0.25rem 0.25rem;
}
.vc-chrome {
  width: 175px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.loader {
  text-align: center;
  grid-column: 1 / -1;
  height: 100px;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 15px;
}
.svg-flex {
  display: flex;
  margin-bottom: 15px;
}

#spinner-container {
  margin: 1px 1px 0px 1px;
  padding: 10px;
  color: white;
  // background-color: rgb(1, 67, 100);
  border-radius: 4px;
}
</style>
