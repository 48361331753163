<template>
  <div class="mb-3">
    <label class="mb-3">{{ label || 'Upload Files' }}</label>
    <div>{{ description }}</div>
    <vue-dropzone
      :ref="this.id + 'myVueDropzone'"
      :include-styling="false"
      :useCustomSlot="true"
      :id="this.id + 'myVueDropzone'"
      :class="this.id"
      @vdropzone-upload-progress="uploadProgress"
      :options="dropzoneOptions"
      @vdropzone-file-added="fileAdded"
      @vdropzone-sending-multiple="sendingFiles"
      @vdropzone-success-multiple="success">
      <div class="dropzone-container" v-show="!useCroppie">
        <div class="file-selector">
          <figure>
            <svg
              width="104px"
              height="104px"
              viewBox="0 0 104 90"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                <circle id="path-1" cx="36" cy="36" r="36"></circle>
                <filter x="-37.5%" y="-29.2%" width="175.0%" height="175.0%" filterUnits="objectBoundingBox" id="filter-2">
                  <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                  <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                  <feColorMatrix
                    values="0 0 0 0 0.0117647059   0 0 0 0 0.0862745098   0 0 0 0 0.160784314  0 0 0 0.08 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"></feColorMatrix>
                  <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
                  <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
                  <feColorMatrix
                    values="0 0 0 0 0.0117647059   0 0 0 0 0.0862745098   0 0 0 0 0.160784314  0 0 0 0.11 0"
                    type="matrix"
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"></feColorMatrix>
                  <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
                  </feMerge>
                </filter>
              </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard" transform="translate(-460.000000, -125.000000)">
                  <g id="Group-4" transform="translate(412.000000, 129.000000)">
                    <g id="Group-2" transform="translate(58.000000, 0.000000)">
                      <circle id="Oval" fill="#01F2A6" opacity="0.200000001" cx="42" cy="42" r="42"></circle>
                      <g id="Group" transform="translate(6.000000, 6.000000)">
                        <g id="Oval">
                          <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                          <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
                          <use fill="#1DA9F330" fill-rule="evenodd" xlink:href="#path-1"></use>
                        </g>
                        <g
                          id="upload-cloud"
                          transform="translate(21.818182, 24.000000)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2">
                          <polyline id="Path" stroke="#000000" points="19.6458087 17.3789847 14.3565525 12.0897285 9.06729634 17.3789847"></polyline>
                          <path d="M14.3565525,12.0897285 L14.3565525,24.1794569" id="Path" stroke="#01F2A6"></path>
                          <path
                            d="M25.6438239,20.7792208 C28.2965835,19.3021499 29.6312816,16.1761528 28.8860265,13.1856562 C28.1407715,10.1951596 25.5052337,8.10125672 22.4838689,8.09921935 L20.8179512,8.09921935 C19.7219904,3.76967373 16.1275086,0.577339516 11.7773112,0.0700384831 C7.42711383,-0.43726255 3.22057026,1.84535014 1.19724759,5.81113853 C-0.826075091,9.77692693 -0.247870665,14.6059952 2.6515151,17.9569414"
                            id="Path"
                            stroke="#01F2A6"></path>
                          <polyline id="Path" stroke="#01F2A6" points="19.6458087 17.3789847 14.3565525 12.0897285 9.06729634 17.3789847"></polyline>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </figure>
          <div>Click Here or Drop To Add {{ this.uploadMultiple ? 'Files' : 'File' }}</div>
          <!--div class="seperator"> or </div>
            <button class="btn m-1 btn-secondary" type="button">Browse</button-->
        </div>
      </div>
    </vue-dropzone>
    <AttachmentList v-if="!useCroppie" :attachments="attachments" />
    <div class="row" v-else ref="croppieRow">
      <div class="col" v-if="googlePhotos && googlePhotos.length" cols="12">
        <div class="photo-options-container">
          <img
            v-for="(item, index) in googlePhotos"
            :src="item.getUrl()"
            :key="index"
            :class="{ 'photo-options': true, 'google-image': true, 'photo-selected': index === googleSelectedIndex }"
            @click="googlePhotoSelected(item.getUrl(), index)" />
        </div>
        <div>
          Images above are retrieved from Google places. If you want these modified, find instructions
          <a href="https://support.google.com/business/answer/6190090?hl=en" class="instruction-link" target="_blank">here.</a>
        </div>
      </div>
      <div class="col" v-show="cropInput">
        <div class="container" style="max-height: 300px; height: 300px">
          <cropper
            :ref="`${id}-cropper`"
            :src="cropInput"
            :resize-image="{ wheel: false }"
            :stencil-props="{
              aspectRatio: width / height,
              handlers: {
                eastNorth: true,
                north: false,
                westNorth: true,
                west: false,
                westSouth: true,
                south: false,
                eastSouth: true,
                east: false,
              },
            }"
            default-boundaries="fill"
            :default-size="setDefaultSize"
            priority="visibleArea"
            @change="onCropChange" />
        </div>
        <!-- <img v-if="cropOutput" :src="cropOutput" :style="getPreviewWidth" /> -->
      </div>
      <div class="col">
        <div class="container d-flex m-1">
          <div class="m-1" v-if="useCroppie">
            <input type="file" id="file" ref="fileInput" @change="newFileAdded" style="display: none" />
            <label for="file" class="btn btn-primary">Select New Image</label>
          </div>
          <button id="btn-upload" style="display: none" :class="getNewImageClass" type="button" :disabled="disabled">Select New Image</button>
          <button id="btn-external-photos" class="btn btn-primary m-1" type="button" @click="$refs[`${id}-externalPhotos`].show()">
            Find Stock Image
          </button>
          <button id="btn-image-ai" class="btn btn-primary m-1" type="button" @click="$refs.imagesAI.showModal()">Generate Image</button>
        </div>

        <div class="d-flex m-1">
          <div v-if="croppieAttachment">
            <button id="btn-remove" :class="getNewImageClass" type="button" @click.prevent="removeImage">Remove Image</button>
          </div>
          <div v-if="croppieAttachment && !cropInput">
            <button id="btn-crop" class="btn btn-primary m-1" type="button" @click.prevent="saveCrop()">Update Crop</button>
          </div>
          <div v-if="cropInput">
            <button class="btn m-1 btn-secondary" type="button" @click="saveCrop()">
              <div class="spinner-border spinner-border-sm text-white" v-if="spinnerActive" label="Spinner"></div>
              <div v-else>Use Image</div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ExternalPhotos
      :id="`${id}-externalPhotos`"
      :ref="`${id}-externalPhotos`"
      :bucket="bucket"
      :title="title"
      @show-spinner="(val) => (val ? $refs.overlay.show() : $refs.overlay.hide())"
      @selected-image="selectedExternal" />
    <ImagesAI ref="imagesAI" :bucket="bucket" @ai-image="selectedImageAI" />

    <Overlay ref="overlay" text="Uploading" />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import AttachmentList from './AttachmentList';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import axios from 'axios';
import ExternalPhotos from '@/components/modals/ExternalPhotosModal.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Overlay from './common/Overlay.vue';
import ImagesAI from '@/components/modals//ImagesAIModal.vue';

export default {
  name: 'Attachment',
  components: {
    ExternalPhotos,
    ImagesAI,
    vueDropzone: vue2Dropzone,
    AttachmentList: AttachmentList,
    Cropper,
    Overlay,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    needsUpdate: {
      type: Boolean,
      default: false,
    },
    convertPDF: {
      type: Boolean,
      default: true,
    },
    attachments: Array,
    label: String,
    bucket: String,
    fullBucket: Boolean,
    uploadMultiple: Boolean,
    useCroppie: Boolean,
    imageOnly: Boolean,
    id: String,
    acceptedFiles: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 375,
    },
    googlePhotos: Array,
    communityKey: String,
    customModalId: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const dropzoneOptions = {
      // The Url Where Dropped or Selected files will be sent
      url: `/`,
      // File Size allowed in MB
      maxFilesize: 102400000,
      // Authentication Headers like Access_Token of your application
      headers: {
        Authorization: `Access Token`,
      },
      // The way you want to receive the files in the server
      paramName: function () {
        return 'file[]';
      },
      dictDefaultMessage: 'Upload Files Here xD',
      includeStyling: false,
      previewsContainer: false,
      thumbnailWidth: 250,
      thumbnailHeight: 140,
      uploadMultiple: false,
      parallelUploads: 20,
    };
    if (this.useCroppie) {
      dropzoneOptions.clickable = `#btn-upload, #headermyVueDropzone`;
    }
    return {
      dropzoneOptions,
      imageBucket: 'reservations',
      storageRef: null,
      bucketRef: null,
      progressMap: {},
      croppieRef: Math.floor(Math.random() * 10000000),
      spinnerActive: false,
      croppieImage: false,
      croppieAttachment: null,
      unusedFile: false,
      croppieUpdated: true,
      googleSelectedIndex: -1,
      externalPhotos: {
        cssStyle: {
          width: '150px',
          height: '179px',
          'object-fit': 'cover',
          'object-position': 'center',
        },
        eventAdded: false,
        isLoading: false,
        showModal: false,
        query: {
          type: String,
          default: this.title || '',
        },
        photos: [],
        page: 1,
        // default number of items per page
        per_page: 15,
        per_row: 5,
        selectedPhoto: null,
        modalId: this.customModalId || 'external-photos-modal',
      },
      uploadTask: null,
      cropper: null,
      cropInput: null,
      cropOutput: null,
    };
  },
  computed: {
    getNewImageClass: function () {
      const classes = { btn: true, 'm-1': true, 'btn-primary': true };
      classes[this.id] = true;
      return classes;
    },
    // getPreviewWidth: function () {
    //   return `width: ${this.width || 375}px`;
    // },
  },
  watch: {
    attachments: {
      handler() {
        if (this.needsUpdate) {
          this.croppieImage = false;
        }
        console.log('attachments: ', this.attachments);
        this.setCrop(this.attachments[0] || null);
      },
      deep: true,
    },
    'dropzoneOptions.clickable': {
      handler() {
        console.log('clickable: ', this.dropzoneOptions.clickable);
      },
    },
    googlePhotos: {
      handler() {
        if ((this.attachments.length == 0 || !this.attachments[0]) && this.googlePhotos && this.googlePhotos.length > 0) {
          this.googlePhotoSelected(this.googlePhotos[0].getUrl(), 0, true);
        }
      },
      deep: true,
    },
    croppieAttachment: {
      handler() {
        console.log('croppie attchment: ' + JSON.stringify(this.croppieAttachment));
      },
    },
    'externalPhotos.selectedPhoto': {
      handler() {
        console.log('SelectedPhoto' + JSON.stringify(this.externalPhotos.selectedPhoto));
      },
    },
    'externalPhotos.showModal': {
      handler() {
        console.log('showModal: ', this.externalPhotos.showModal);
      },
    },
  },
  created() {
    if (this.acceptedFiles) {
      this.dropzoneOptions.acceptedFiles = this.acceptedFiles;
    }
    this.croppieRef = this.id || this.croppieRef;
    this.dropzoneOptions.uploadMultiple = this.uploadMultiple == true ? true : false;
    this.storageRef = firebase.storage().ref();
    const timestamp = new Date().getTime().toString();
    const bucket = this.bucket || 'misc';
    if (this.fullBucket && this.bucket) {
      this.imageBucket = firebase.storage().ref(bucket);
    } else {
      this.imageBucket = this.storageRef
        .child(this.communityKey || this.getCampgroundKey)
        .child('images')
        .child(bucket)
        .child(timestamp);
    }
    this.googleSelectedIndex = this.googlePhotos && this.googlePhotos.length ? 0 : -1;
    if (this.useCroppie) {
      this.setCrop(this.attachments[0] || null);
      console.log('Croppie attachment created(): ', this.croppieAttachment);
    }
  },
  mounted() {
    this.dropzoneOptions.clickable = `.${this.id}`;
    this.cropper = this.$refs[`${this.id}-cropper`];
  },
  methods: {
    async selectedImageAI(item) {
      this.croppieAttachment = item.newDrawableUrl;
      this.setCrop(item.newDrawableUrl);
      this.$emit('updateCroppieImage', item.newDrawableUrl);
    },
    async selectedExternal(item) {
      this.croppieAttachment = item.downloadUrl;
      this.setCrop(item.downloadUrl);
      this.$emit('updateCroppieImage', item.downloadUrl);
    },
    createAttachmentObject(file) {
      let attachment = {};
      attachment._id = file.upload ? file.upload.uuid : Date.now();
      attachment.title = file.name;
      attachment.type = 'file';
      attachment.extension = '.' + file.type.split('/')[1];
      attachment.user = JSON.parse(localStorage.getItem('user'));
      attachment.content = 'File Upload by Select or Drop';
      attachment.thumb = file.dataURL;
      attachment.thumb_list = file.dataURL;
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      attachment.downloadURL = '';
      return attachment;
    },
    // function called for every file dropped or selected
    async newFileAdded(event) {
      const file = event.target.files[0];
      await this.fileAdded(file);
    },
    async fileAdded(file) {
      const isCorrectMimeType = !this.acceptedFiles
        ? true
        : this.acceptedFiles.split(',').reduce((correctMime, mimeType) => {
            return mimeType.toLowerCase() == file.type.toLowerCase() ? true : correctMime;
          }, false);
      if (isCorrectMimeType) {
        this.$refs.overlay.show();
        if (!this.dropzoneOptions.uploadMultiple) {
          this.attachments.length = 0;
        }
        console.log(file?.type);
        if (file?.type == 'application/pdf' && this.convertPDF) {
          const dataURL = await this.processPDF(file);
          if (this.dataURL !== null) {
            file = new File([dataURL], file.name.replace('.pdf', '.jpeg'), { type: 'image/jpeg' });
          } else {
            console.error("Couldn't process .pdf to .jpeg.", dataURL);
          }
        }
        const attachObject = this.createAttachmentObject(file);
        this.attachments.push(attachObject);
        if (this.useCroppie) {
          this.croppieAttachment = attachObject;
          const reader = new FileReader();
          let vm = this;
          reader.onload = function (e) {
            console.log('file read', e.target.result);
            vm.setCrop(e.target.result);
            vm.$refs.overlay.hide();
          };
          reader.readAsDataURL(file);
        } else if (!this.dropzoneOptions.uploadMultiple) {
          this.uploadFile(file);
        }
      } else {
        this.$message.create({
          title: 'Incorrect Type',
          body: `Please upload files with the correct type: ${this.acceptedFiles}`,
          buttons: [this.$message.button('OK', null, null)],
        });
      }
      //this.attachments = [...this.attachments, this.createAttachmentObject(file)];
    },
    // a middle layer function where you can change the XHR request properties
    sendingFiles(files) {
      for (let i = 0; i < files.length; i++) {
        this.uploadFile(files[i]);
      }
    },
    // function where we get the upload progress
    uploadProgress() {
      //This function is empty as firebase upload is managing progress
    },
    async processPDF(file) {
      const pdfjsLib = await import('pdfjs-dist');
      const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          console.log(pdfjsLib);
          const pdf = await pdfjsLib.getDocument(e.target.result).promise;
          const page = await pdf.getPage(1);

          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
          const pdfImage = canvas.toDataURL('image/jpeg');
          const binary = Uint8Array.from(atob(pdfImage.split(',')[1]), (c) => c.charCodeAt(0));
          const result = new Blob([binary], { type: 'image/jpeg' });
          resolve(result);
        };
        reader.onerror = function (e) {
          reject(e);
        };
        reader.readAsDataURL(file);
      });
    },
    // called on successful upload of a file
    success() {
      //This function is empty as firebase upload is managing success, all uploads fail dropzone upload.
    },
    uploadFile(file) {
      this.unusedFile = true;
      const imageRef = this.imageBucket.child(file.name);
      const uploadTask = imageRef.put(file);
      this.progressMap[file.name] = 0;
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.progressMap[file.name] = progress;
          this.attachments.map((attachment) => {
            if (attachment.title === file.name) {
              attachment.progress = this.progressMap[file.name];
            }
          });
        },
        function (error) {
          console.log('TCL: uploadFile -> error', error);
          // Handle unsuccessful uploads
        },
        () => {
          this.attachments.map((attachment) => {
            if (attachment.title === file.name) {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                attachment.ref = uploadTask.snapshot.ref;
                attachment.downloadURL = downloadURL;
                this.$emit('fileAdded', attachment);
                this.$refs.overlay.hide();
              });
            }
          });
        }
      );
    },
    googlePhotoSelected: async function (drawable, index, removeCroppie) {
      console.log('google photo selected first');
      this.googleSelectedIndex = index;
      this.croppieAttachment = drawable;
      const url = `${this.$apihostname}/api/copyImage`;
      const newDrawable = (await axios.post(url, { url: drawable, communityKey: this.communityKey || this.getCampgroundKey, bucket: this.bucket }))
        .data.url;
      this.setCrop(newDrawable);
      this.$emit('updateCroppieImage', newDrawable);
      console.log('google photo selected');
    },
    getFileNameFromFB: function (downloadURL) {
      const imageURL = downloadURL.split('?')[0];
      const splitURL = imageURL.split('%2F');
      return splitURL[splitURL.length - 1];
    },
    removeImage: function () {
      this.croppieAttachment = null;
      this.cropInput = null;
      this.$emit('updateCroppieImage', null);
    },

    setDefaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    setCrop(image) {
      console.log('Setting crop:', image);

      if (!image) {
        console.log(`Invalid image: ${image}`);
        return;
      }

      //Vue-advanced-cropper hates URLs, which causes stretching, so convert to a blob.
      if (image && typeof image == 'string' && image.startsWith('http')) {
        fetch(image)
          .then((res) => res.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.cropInput = reader.result;
            };
            reader.readAsDataURL(blob);
          });
        return;
      } else {
        this.cropInput = image;
      }

      //Refresh cropper component three times because it doesn't always load the image and watch never triggers.
      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     this.refreshCropper();
      //   });
      // }, 500);
      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     this.refreshCropper();
      //   });
      // }, 1500);
      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     this.refreshCropper();
      //   });
      // }, 2500);
    },
    //This code may be obsolete now. Tested throughout.
    // refreshCropper() {
    //   if (this.cropper) this.cropper.refresh();
    //   else console.log('Cropper not found.');
    //   this.cropper.setCoordinates(({ _, imageSize }) => {
    //     const aspectRatio = this.width / this.height;
    //     if (aspectRatio * imageSize.height > imageSize.width) {
    //       const width = imageSize.width;
    //       const height = width / aspectRatio;
    //       return {
    //         width,
    //         height,
    //       };
    //     } else {
    //       const height = imageSize.height;
    //       const width = height * aspectRatio;
    //       return {
    //         width,
    //         height,
    //       };
    //     }
    //   });
    // },
    onCropChange({ coordinates, canvas }) {
      if (this.spinnerActive == true) return;
      if (canvas) {
        console.log('Crop changed:', coordinates, canvas);
        this.cropOutput = canvas.toDataURL();
        if (this.uploadTask) {
          this.uploadTask.cancel();
          this.uploadTask = null;
          this.spinnerActive = false;
          //console.log('Upload task canceled due to crop change.');
        }
      }
    },
    async saveCrop() {
      if (this.spinnerActive == true) return;

      this.spinnerActive = true;
      const image = this.cropOutput;
      if (!image) {
        console.log('Image received is invalid:', image);
        return;
      }

      const result = await this.rescaleImage(image);
      if (!result) {
        console.log('Invalid rescaled image:', result);
        return;
      }
      this.$refs.overlay.show();

      const filename = this.croppieAttachment ? this.croppieAttachment.title || this.getFileNameFromFB(this.croppieAttachment) : 'newImage';
      //Convert result to blob and get storage path.
      const response = await fetch(result);
      const blob = await response.blob();
      const imageRef = this.imageBucket.child(filename);

      this.uploadTask = imageRef.put(blob);
      try {
        await this.uploadTask;
      } catch (error) {
        console.error('Error uploading image:', error);
        this.spinnerActive = false;
        this.$refs.overlay.hide();
        return;
      }

      const downloadURL = await imageRef.getDownloadURL();
      this.setCrop(downloadURL);

      this.spinnerActive = false;
      this.unusedFile = false;
      this.$refs.overlay.hide();
      this.$emit('updateCroppieImage', downloadURL);
    },
    rescaleImage(image) {
      return new Promise((resolve, reject) => {
        const maxWidth = 2000;
        const img = new Image();
        img.src = image;
        img.onload = () => {
          if (img.width > maxWidth) {
            const scaleFactor = maxWidth / img.width;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const rescaledImage = canvas.toDataURL('image/png');

            resolve(rescaledImage);
          } else {
            resolve(image);
          }
        };
        img.onerror = reject;
      });
    },
    refreshCropper() {
      this.cropper.refresh();
    },
  },
};
</script>

<style>
.file-selector {
  font-weight: 600;
  color: #4e5b69;
  z-index: -9;
  display: inline-flex;
}
figure {
  margin: 0px;
}

.file-selector div {
  align-content: center;
  display: grid;
  width: 100px;
}
.dropzone-container {
  text-align: center;
  border: 1px dashed #999;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
}
.seperator {
  position: relative;
  padding: 0 4px;
  font-size: 12px;
}
.image-preview {
  width: 200px;
}
.photos-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.photos-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.google-image {
  height: 68px;
  width: 100px;
}
.photo-checkbox {
  height: 100px;
  width: 100px;
}

.always-top {
  display: inline-block;
  position: absolute;
  z-index: 10000;
  height: 32px;
  width: 32px;
}
</style>
