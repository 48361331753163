<template>
  <div class="p-2 form-container">
    <form @submit.prevent="submitUpdate" novalidate>
      <b-accordion id="eventConfig" :headings="['Audiences', 'User Created Events', 'Calendar Import Config']">
        <template #body="{ heading }">
          <div v-if="heading === 'Audiences'">
            <GenericItemList :items="audiences" title="New Audience" buttonTitle="Add Audience" />
          </div>

          <div v-if="heading === 'User Created Events'">
            <div class="card mb-1" v-for="(tab, index) in tabs" :key="index">
              <div class="card-header">{{ tab.title }} Event Tab</div>
              <div class="card-body">
                <div class="mb-3">
                  <div class="form-text">Allow end users the ability to submit events for inclusion on the {{ tab.title }} tab</div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="eventConfig[`userCreatedEventTab${index + 1}`]"
                      :id="`userCreatedEventTabCheckbox${index + 1}`" />
                    <label class="form-check-label" :for="`userCreatedEventTabCheckbox${index + 1}`">
                      Allow {{ tab.title }} Event User Submission
                    </label>
                  </div>
                </div>
                <div v-if="eventConfig[`userCreatedEventTab${index + 1}`]">
                  <div class="mb-3">
                    <div class="form-text">End user event requests will need approval by staff before being added in the {{ tab.title }} tab</div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="eventConfig[`requiresApprovalTab${index + 1}`]"
                        :id="`requiresApprovalTabCheckbox${index + 1}`" />
                      <label class="form-check-label" :for="`requiresApprovalTabCheckbox${index + 1}`">
                        {{ tab.title }} Event Submission Requires Approval
                      </label>
                    </div>
                    <Validation
                      :validator="vuelidate$.eventConfig[`emailsTab${index + 1}`].$dirty && vuelidate$.eventConfig[`emailsTab${index + 1}`].$error"
                      feedback="You must include at least one email address to have approval." />
                  </div>
                  <div class="mb-3">
                    <div class="form-text">
                      Enter email address(es) that will receive approval requests and notifications for guest submitted events
                    </div>
                    <GenericItemList :items="eventConfig[`emailsTab${index + 1}`]" title="Staff Email Address" buttonTitle="Add Email" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="heading === 'Calendar Import Config'">
            <ul class="list-unstyled">
              <li v-for="(calendar, index) in eventConfig.googleImport" :key="index">
                <div class="mb-3">
                  <label class="form-label">Calendar Type</label>
                  <select class="form-select" v-model="calendar.type" @change="updateType($event, index)">
                    <option :value="null">No Import</option>
                    <option value="google">Google Calendar</option>
                    <option value="iCal">iCal/ICS Feed</option>
                    <option value="tribe">The Events Calendar(Wordpress Plugin)</option>
                  </select>
                </div>
                <div v-if="calendar.type">
                  <div class="mb-3">
                    <label class="form-label">Calendar ID/URL</label>
                    <input class="form-control" v-model="calendar.calendarId" />
                    <div class="form-text">{{ getCalendarIDDescription(calendar.type) }}</div>
                    <Validation
                      :validator="vuelidate$.eventConfig.googleImport"
                      feedback="If you add a calendar ID you must include all selects below." />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Default Location</label>
                    <select class="form-select" v-model="calendar.defaultLocation">
                      <option :value="null || undefined" disabled>-- Select Default Location --</option>
                      <option value="">No Default Location</option>
                      <option v-for="(location, index) in locations" :key="index" :value="location.title">{{ location.title }}</option>
                    </select>
                    <div class="form-text">The location to use when there is no location.</div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Audience</label>
                    <div class="form-text">The audience that will be applied to all imported events. This can be modified after the import.</div>
                    <select class="form-select" v-model="calendar.audience">
                      <option :value="null || undefined" disabled>-- Select Audience --</option>
                      <option v-for="(option, index) in audienceOptions" :key="index" :value="option.value">{{ option.text }}</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Calendar Tab</label>
                    <div class="form-text">The tab that these events will be imported into</div>
                    <select class="form-select" v-model="calendar.eventContext">
                      <option :value="null || undefined" disabled>-- Select Tab --</option>
                      <option value="events-resort">{{ tabs[0].title }}</option>
                      <option value="events-local">{{ tabs[1].title }}</option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </b-accordion>

      <Validation :validator="vuelidate$.newItem" map="form" />

      <button class="btn m-1 btn-primary" type="submit">Submit</button>
      <button class="btn m-1 btn-secondary" type="button" @click.stop="cancelUpdate">Cancel</button>
    </form>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import GenericItemList from '../listitems/GenericItemList';

import { NonDetailFormMixin } from '../mixins/NonDetailFormMixin.js';
import BAccordion from '../common/BAccordion.vue';

export default {
  components: {
    GenericItemList,
    BAccordion,
  },
  mixins: [NonDetailFormMixin],
  props: ['dbContext', 'tabs', 'locations'],
  watch: {},
  data() {
    return {
      newItem: {
        title: '',
        latitude: '',
      },
      userCreated: false,
      eventConfig: {
        googleImport: [{}],
        emailsTab1: [],
        emailsTab2: [],
      },
      audiences: [],
    };
  },
  validations() {
    let validateConfig = {
      eventConfig: {
        emailsTab1: {},
        emailsTab2: {},
      },
    };
    if (this.eventConfig) {
      if (this.eventConfig.requiresApprovalTab1) {
        validateConfig.eventConfig.emailsTab1 = {
          required,
          minLength: 1,
        };
      }
      if (this.eventConfig.requiresApprovalTab2) {
        validateConfig.eventConfig.emailsTab2 = {
          required,
          minLength: 1,
        };
      }
      if (this.eventConfig.googleImport[0].calendarId) {
        validateConfig.eventConfig.googleImport = {
          $each: {
            audience: {
              required,
            },
            eventContext: {
              required,
            },
          },
        };
      }
    }

    return validateConfig;
  },
  created: async function () {
    this.getAudiences();
    const configSnapShot = await this.getContextRef().once('value');
    this.eventConfig = { ...this.eventConfig, ...configSnapShot.val() };
  },
  computed: {
    audienceOptions: function () {
      return this.audiences.map((x) => {
        return { text: x, value: x.toLowerCase() };
      });
    },
  },
  methods: {
    getAudiences: function () {
      this.getContextRef('audience')
        .once('value')
        .then((audienceSnapshot) => {
          const audiences = audienceSnapshot.val();
          this.audiences = audiences.map((x) => x.title);
        });
    },
    updateType(event, index) {
      if (!event) {
        this.eventConfig.googleImport[index] = {};
      }
    },
    getCalendarIDDescription: function (type) {
      let description = 'The URL for the calendar feed';
      if (type == 'google') {
        description = 'Your Google Calendar ID (src) will look something like an email address(*****@gmail.com or *****@group.calendar.google.com)';
      }
      return description;
    },
    submitUpdate: function () {
      this.vuelidate$.$touch();
      if (!this.vuelidate$.$invalid) {
        const audiences = this.audiences.map((x) => {
          return { title: x };
        });
        this.getContextRef('audience').set(audiences);
        this.getContextRef().update(this.eventConfig);
        this.exitEdit('submit');
      } else {
        this.$nextTick(() => {
          const firstError = document.querySelector('.invalid-feedback.d-block');
          if (firstError) {
            let firstErrorPanel = firstError;
            while ((firstErrorPanel = firstErrorPanel.parentElement) && firstErrorPanel.getAttribute('role') !== 'tabpanel');
            if (!firstErrorPanel.classList.contains('show')) {
              this.$root.$emit('bv::toggle::collapse', firstErrorPanel.getAttribute('id'));
            }
            this.$nextTick(() => {
              let domRect = firstError.parentElement.getBoundingClientRect();
              window.scrollTo(domRect.left + document.documentElement.scrollLeft, domRect.top + document.documentElement.scrollTop);
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.card {
  background-color: rgba(245, 245, 245, 0) !important;
}
</style>
