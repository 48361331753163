<template>
  <b-modal id="prompt-modal" :title="title" :class="classes" @ready="ready" @hide="destroy">
    <template #body> <div v-html="body"></div> </template>
    <template #footer-right>
      <!-- Loop through buttons prop -->
      <button
        v-for="(button, index) in buttons"
        :key="index"
        type="button"
        class="btn"
        :class="button.variant"
        @click="
          () => {
            if (button?.action) {
              console.log(`Clicked ${button.title} button in ${title}.`);
              button?.action();
            }
            hide();
          }
        ">
        {{ button.title }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import BModal from '../common/BModal.vue';

export default {
  name: 'PromptModal',
  components: {
    BModal,
  },
  props: {
    title: { type: String, default: 'Modal title' },
    body: { type: String, default: 'Modal description.' },
    buttons: {
      type: Array,
      default: () => [
        { title: 'Cancel', variant: 'btn-secondary', action: () => {} },
        { title: 'OK', variant: 'btn-accent', action: () => {} },
      ],
    },
    classes: { type: String, default: '' },
  },
  data() {
    return {
      modal: null,
    };
  },
  created() {
    //Add a default variant to each button if one isn't assigned.
    this.buttons.forEach((button) => {
      if (!button.variant) button.variant = 'btn-primary';
    });
  },
  methods: {
    ready(modal) {
      this.modal = modal;
    },
    show() {
      if (this.modal) this.modal.show();
    },
    hide() {
      if (this.modal) this.modal.hide();
    },
    destroy() {
      this.$emit('destroy');
    },
  },
};
</script>
