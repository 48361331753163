<template>
  <div>
    <div class="row">
      <div class="col-2" style="min-width: 75px">
        <strong>Dollars:</strong>
      </div>
      <div class="col">
        {{ dollarsData.currentDollars }}
      </div>
    </div>
    <template v-if="historyLoaded">
      <div v-if="historyArray.length == 0">There is no history for this user/business.</div>
      <div v-for="item in historyArray" v-bind:key="item.key">
        <div class="row mt-2">
          <div class="col-3">
            {{ Intl.DateTimeFormat('en', { dateStyle: 'short' }).format(new Date(item.transferDate)) }}<br />
            {{ Intl.DateTimeFormat('en', { timeStyle: 'short' }).format(new Date(item.transferDate)) }}
          </div>
          <div class="col">
            <span>{{ getHistoryText(item) }}</span>
            <div v-if="item.notes">
              <button
                class="btn btn-link p-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#{{item.key.substring(1)}}notes"
                @click.prevent>
                [notes]
              </button>
              <div class="collapse" id="{{item.key.substring(1)}}notes">{{ item.notes }}</div>
            </div>
            <div v-if="item.transfers && Object.keys(item.transfers).length > 1">
              <button
                class="btn btn-link p-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#{{item.key.substring(1)}}users"
                @click.prevent>
                [users]
              </button>
              <div class="collapse" id="{{item.key.substring(1)}}users">
                <div class="row" v-for="(userTransfer, transferKey) in item.transfers" v-bind:key="transferKey">
                  <div class="col-6">
                    {{ allUIDs[transferKey] ? allUIDs[transferKey].displayName : 'N/A' }}
                  </div>
                  <div class="col-6">
                    {{ userTransfer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="loading-item">
      <div class="spinner-border" style="width: 4rem; height: 4rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';
export default {
  props: ['type', 'dollarsId', 'dollarsActive'],
  data() {
    return {
      historyArray: [],
      historyLoaded: false,
      allUIDs: {},
      dollarsData: {},
      participatingBusinesses: [],
    };
  },
  created() {
    this.getDollarsData();
    this.getParticipatingBusinesses();
  },
  methods: {
    getDollarsData() {
      firebase
        .database()
        .ref('resort-navigator/amc-dollars')
        .child(this.type)
        .child(this.dollarsId)
        .once('value')
        .then((snapshot) => {
          this.dollarsData = snapshot.val() || { currentDollars: 0 };
          const { uids, newItems } = Object.keys(this.dollarsData.history || {}).reduce(
            (acc, historyItemKey) => {
              const historyItem = this.dollarsData.history[historyItemKey];
              historyItem.key = historyItemKey;
              if (historyItem.transfers) acc.uids = [...acc.uids, ...Object.keys(historyItem.transfers)];
              if (historyItem.to) acc.uids.push(historyItem.to);
              if (historyItem.from) acc.uids.push(historyItem.from);
              acc.newItems.unshift(historyItem);
              return acc;
            },
            { uids: [], newItems: [] }
          );
          this.historyArray = newItems;
          this.getAllUserData(Array.from(new Set(uids)));
          this.historyLoaded = true;
        });
    },
    getAllUserData(uids) {
      const getUserData = firebase.functions().httpsCallable('getUserData');
      getUserData({ uids }).then((result) => {
        this.allUIDs = { ...this.allUIDS, ...result.data };
      });
    },
    refundDollars(item) {
      this.$message.create({
        title: 'Refund Dollars',
        body: `To refund ${this.allUIDs[item.from].displayName} ${item.amount} dollars, click "Refund" below.`,
        classes: 'amc-modal',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary'),
          this.$message.button('Refund', 'btn-accent', () => {
            console.log('🚀 ~ file: DollarsDisplay.vue ~ line 110 ~ refundDollars ~ value', value);
          }),
        ],
      });
    },
    getParticipatingBusinesses() {
      firebase
        .database()
        .ref('resort-navigator/amc-dollars/participants')
        .once('value')
        .then(
          (snapshot) => {
            this.participatingBusinesses = this.objectFromArray(snapshot.val() || {});
            this.activeParticipatingBusinesses = this.objectFromArray(
              Object.keys(this.participatingBusinesses)
                .filter((businessKey) => this.participatingBusinesses[businessKey].dollarsActive)
                .map((businessKey) => this.participatingBusinesses[businessKey])
            );
            this.businessOptions = Object.keys(this.activeParticipatingBusinesses)
              .map((businessKey) => ({
                value: this.activeParticipatingBusinesses[businessKey].id,
                text: this.activeParticipatingBusinesses[businessKey].title,
              }))
              .sort((o1, o2) => (o1.text > o2.text ? 1 : -1));
          },
          (err) => console.log(err.message)
        );
    },
    objectFromArray(businessObject) {
      return typeof businessObject == 'object'
        ? businessObject
        : businessObject.reduce((acc, business, index) => {
            acc[index] = business;
            return acc;
          }, {});
    },
    getHistoryText(item) {
      switch (item.type) {
        case 'bought': {
          if (item.transfers) {
            const keyArray = Object.keys(item.transfers);
            const forText = keyArray.length > 1 ? `${keyArray.length} people` : this.getNameFromUID(keyArray[0]);
            return `You purchased ${item.amount} dollars for ${forText}`;
          }
          return `You purchased ${item.amount} dollars`;
        }
        case 'transfer': {
          return item.to && item.to !== this.userData.uid
            ? `You transferred ${item.amount} to ${this.getNameFromUID(item.to)}`
            : `You have recieved ${item.amount} from ${this.getNameFromUID(item.from)}`;
        }
        case 'purchase': {
          return this.type === 'users'
            ? `You paid ${item.amount} for a purchase at ${
                this.participatingBusinesses && this.participatingBusinesses[item.to] ? this.participatingBusinesses[item.to].title : 'N/A'
              }`
            : `You recieved ${item.amount} for a purchase from ${this.getNameFromUID(item.from)}`;
        }
        default:
          return null;
      }
    },

    getNameFromUID(id) {
      return !this.allUIDs[id] ? 'N/A' : this.allUIDs[id].displayName || 'N/A';
    },
  },
};
</script>

<style lang="scss" scoped></style>
