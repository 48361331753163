<template>
  <div class="two-column">
    <div id="center-column" :style="getUpdatedBackgroundStyle">
      <div id="item-header" :style="getHeaderStyles()">
        <b-carousel :headerInfo="headerInfo" :newItem="newItem" :headerStyles="getHeaderStyles()">
          <template #title>
            <h1 v-if="!headerInfo.hideTitle || (!headerInfo && newItem.displayName)">
              {{ newItem.title }}
            </h1>
          </template>
        </b-carousel>
      </div>
      <!-- <div id="item-header" :style="getHeaderStyles()">
        <iframe
          v-if="headerInfo.videoURL"
          width="490"
          height="261"
          :src="`https://www.youtube.com/embed/${headerInfo.videoURL}`"
          title="YouTube video player"
          frameborder="0"
          allow="mute; autoplay; allowfullscreen" />
        <div
          v-if="this.headerInfo && this.headerInfo.images && this.headerInfo.images.length > 0"
          id="listBuilderCarousel"
          class="carousel slide carousel-fade"
          :data-bs-ride="this.headerInfo.images && this.headerInfo.images.length > 1 ? 'carousel' : null">
          <ol class="carousel-indicators" style="list-style-type: none">
            <li
              v-for="(src, index) in this.headerInfo.images"
              :key="index"
              data-bs-target="#listBuilderCarousel"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }"></li>
          </ol>
          <div class="carousel-inner">
            <div v-for="(src, index) in this.headerInfo.images" :key="index" class="carousel-item" :class="{ active: index === 0 }">
              <img :src="src.imageURL" class="d-block w-100" :alt="src.caption" />
              <div class="carousel-caption">
                <h5>{{ src.caption }}</h5>
              </div>
            </div>
          </div>
          <a
            v-if="this.headerInfo.images && this.headerInfo.images.length > 1"
            class="carousel-control-prev"
            href="#listBuilderCarousel"
            role="button"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </a>
          <a
            v-if="this.headerInfo.images && this.headerInfo.images.length > 1"
            class="carousel-control-next"
            href="#listBuilderCarousel"
            role="button"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </a>
        </div>
        <h1 v-if="newItem.displayName">{{ newItem.title }}</h1>
        <h1 v-show="!this.newItem.headerInfo || !this.newItem.headerInfo.hideTitle">{{ newItem.title }}</h1>
      </div> -->
      <div ref="menuGrid" v-if="!newItem.isBusinessOnly || newItem.inProgress || this.menuId !== 'main-menu'">
        <Sortable
          :list="gridMenuItemsArray"
          itemKey="uniqueId"
          tag="div"
          class="grid-container"
          :options="sortableOptions"
          @end="(evt) => moveItems(evt)">
          <template #item="{ element, index }">
            <GridMenuItems
              :class="getSortClass(index)"
              :item="element"
              :itemId="Object.keys(gridMenuItems)[index]"
              :dbContext="menuId"
              :iconSet="updatedIconSet"
              :updatedColorIconShape="updatedColorIconShape"
              :updatedColorIconBackground="updatedColorIconBackground"
              :main="true"
              :hide-edit-buttons="action == 'sort-button'"
              :disable-click="action == 'sort-button'"
              @delete-item="deleteItem" />
          </template>
        </Sortable>
      </div>
      <div v-else>
        <div class="row buttonRow">
          <div class="col" v-if="newItem.phone">
            <DetailButton buttonText="Call" buttonIcon="call" />
          </div>
          <div class="col" v-if="displayMap">
            <DetailButton buttonText="Map" buttonIcon="map" />
          </div>
          <div class="col" v-if="newItem.reservationURL">
            <DetailButton buttonText="Reservations" buttonIcon="event" @click.native="openURL(newItem.reservationURL)" />
          </div>
          <div class="col" v-if="newItem.website">
            <DetailButton buttonText="Website" buttonIcon="link" @click.native="openURL(newItem.website)" />
          </div>
          <div class="col" v-if="newItem.hasSpecial">
            <DetailButton buttonText="Special" buttonIcon="new_releases" @click.native="openURL(newItem.menu)" />
          </div>
        </div>
        <div class="row m-1 mt-2">
          <div class="col">
            {{ newItem.address }}
          </div>
          <div>{{ newItem.city }}{{ newItem.city && newItem.state ? ',' : '' }} {{ newItem.state }} {{ newItem.zip }}</div>
        </div>
        <div class="row m-1 mt-2">
          <div class="col">
            <span v-html="newItem.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div id="right-column" class="p-2">
      <MainMenuItem
        v-if="action == `add` || action == `edit`"
        class="width-100"
        :action="action"
        @action="setAction"
        :itemsList="gridMenuItems"
        :rightColumnKey="selectedGridMenuItemKey" />
      <CommunitySetup v-else-if="action == `community-setup` || action == `add-community`" @update-style="updateStyle" />
      <component
        v-else-if="rightColumnComponent"
        :is="rightColumnComponent"
        v-bind="rightColumnProps"
        @action="setAction"
        :key="rightColumnKey"
        :rightColumnKey="rightColumnKey"
        :action="action"
        @header-info-update="received" />
      <ButtonNavigation v-else buttonSection="actions" @action="setAction" />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import GridMenuItems from '../components/listitems/GridMenuItems.vue';
import MainMenuItem from '../components/forms/MainMenuItem.vue';
import HeaderImages from '../components/forms/HeaderImages.vue';
import CommunitySetup from '../components/forms/CommunitySetup.vue';
import ButtonNavigation from '../components/ButtonNavigation.vue';
import { Sortable } from 'sortablejs-vue3';
import DetailButton from '../components/DetailButton';
import BCarousel from '../components/common/BCarousel.vue';

export default {
  components: {
    GridMenuItems,
    MainMenuItem,
    Sortable,
    ButtonNavigation,
    CommunitySetup,
    DetailButton,
    HeaderImages,
    BCarousel,
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.action == 'sort-submit') {
      firebase.database().ref('resort-navigator').child('location-data').child(this.getCampgroundKey).child(this.menuId).set(this.gridMenuItemsArray);
      this.$router.replace({ name: 'home' });
    } else if (to.params.action == 'sort-cancel') {
      this.gridMenuItemsArray = Object.values(this.gridMenuItems);
      this.$router.replace({ name: 'home' });
      this.$forceUpdate();
    }
    if (to.params.itemId) {
      this.selectedGridMenuItemKey = to.params.itemId;
    } else {
      if (to.name == from.name) {
        window.location.reload();
      }
    }
    this.getAction();
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.itemId) {
      next((vm) => {
        vm.selectedGridMenuItemKey = to.params.itemId;
        vm.getAction();
      });
    } else {
      next((vm) => {
        vm.selectedGridMenuItemKey = '';
        vm.getAction();
        vm.fetchData();
      });
    }
  },
  name: 'app',
  data() {
    return {
      action: '',
      gridMenuItems: {},
      gridMenuItemsArray: [],
      selectedGridMenuItemKey: '',
      itemToDelete: null,
      menuId: window.localStorage.getItem('MENU_SECTION') || 'main-menu',
      updatedBackground: null,
      updatedPrimary: null,
      updatedIconSet: null,
      updatedColorIconShape: null,
      updatedColorIconBackground: null,
      updatedDrawable: null,
      currentRightColumnComponent: null,
      rightColumnProps: {},
      rightColumnKey: '',
      newItem: {
        title: '',
        displayName: false,
        isBusinessOnly: '',
        inProgress: false,
        description: '',
      },
      headerInfo: [],
      campgroundDrawable: null,
    };
  },
  computed: {
    getUpdatedBackgroundStyle: function () {
      if (this.updatedBackground) {
        return `background-color: ${this.updatedBackground}`;
      }
      return '';
    },
    displayMap: function () {
      return this.newItem.address || this.newItem.city || (this.newItem.latitude && this.newItem.longitude);
    },
    rightColumnComponent: function () {
      return this.currentRightColumnComponent;
    },
    sortableOptions() {
      return {
        disabled: this.action !== 'sort-button',
        filter: '.undraggable',
        draggable: '.draggable',
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  // watch: {
  //   action: function (newAction) {
  //     console.log('action watch hit');
  //     if (newAction == 'sort-button') {
  //       this.sortableOptions.disabled = false;
  //       console.log('sort button hit', this.sortableOptions);
  //     } else {
  //       this.sortableOptions.disabled = true;
  //       console.log('sort button hit', this.sortableOptions);
  //     }
  //   },
  // },
  created: async function () {
    this.isResortAdmin =
      window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
    await this.fetchData();
    this.getAction();
  },
  mounted() {
    window.ZohoHCAsap =
      window.ZohoHCAsap ||
      function (a, b) {
        window.ZohoHCAsap[a] = b;
      };
    (function () {
      var d = document;
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.defer = true;
      s.src = 'https://desk.zoho.com/portal/api/web/inapp/578387000000195065?orgId=740859209';
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  },
  methods: {
    deleteItem() {
      this.fetchData();
    },
    received(item) {
      console.log('itemssss', item);
    },
    updateStyle: function (newItem) {
      if (newItem) {
        this.updatedBackground = newItem.colorBackground;
        this.updatedPrimary = newItem.colorPrimary;
        this.updatedIconSet = newItem.iconSet;
        this.updatedColorIconShape = newItem.colorIconShape;
        this.updatedColorIconBackground = newItem.colorIconBackground;
        this.updatedDrawable = newItem.drawable;
        this.newItem = newItem;
      } else {
        this.updatedBackground = null;
        this.updatedPrimary = null;
        this.updatedIconSet = null;
        this.updatedColorIconShape = null;
        this.updatedColorBackgroundShape = null;
        this.updatedDrawable = null;
      }
    },
    getHeaderStyles: function () {
      const image =
        this.headerInfo && this.headerInfo.images
          ? this.headerInfo.images[0].imageURL || null
          : this.headerInfo.videoURL
          ? null
          : this.updatedDrawable || this.campgroundDrawable;
      if (image) {
        return `height:261px;background-image:url("${image}");`;
      } else if (this.headerInfo.videoURL) {
        return `height:261px;`;
      } else return `${this.getBackgroundColorStyle}height:50px;`;
    },
    fetchData: async function () {
      this.headerInfo = (await this.getContextRef(`header-info/main-menu`).once('value')).val() || [];
      console.log('TCL: this.headerInfo', this.headerInfo);
      if (this.getCampgroundKey && this.action !== 'add-community') {
        try {
          this.getContextRef(this.menuId).once('value', (res) => {
            this.gridMenuItems = res.val() || {};
            this.gridMenuItems = Object.fromEntries(
              Object.entries(this.gridMenuItems).filter(([key, value]) => value !== undefined && value !== null)
            );
            this.gridMenuItemsArray = Object.values(this.gridMenuItems);
          });
          this.selectedGridMenuItemKey = this.$route.params.itemId || '';
          this.newItem = (
            await firebase.database().ref('resort-navigator').child('campground-locations').child(this.getCampgroundKey).once('value')
          ).val();
          this.setCampgroundLocationData(this.getCampgroundKey, this.newItem);
          this.campgroundDrawable = window.localStorage.getItem('CAMPGROUND_DRAWABLE');
        } catch (e) {
          console.log(e);
        }
      } else {
        this.unsetCampgroundLocationData();
        this.campgroundDrawable = '';
        this.newItem = {
          title: '',
          displayName: false,
          isBusinessOnly: '',
          inProgress: false,
          description: '',
        };
        this.gridMenuItems = {};
        this.gridMenuItemsArray = [];
        this.headerInfo = [];
        this.updatedDrawable = null;
        try {
          this.headerInfo[0].drawable = this.campgroundDrawable;
        } catch (err) {
          console.error(err);
        }
      }
    },
    getAction: function () {
      this.action = this.$route.params.action;
    },

    sortAlert: function () {
      this.$message.create({
        title: 'Sort Error',
        body: 'You may not change the sort order of the first six items. Your sort has been reset.',
        classes: 'amc-modal',
        buttons: [this.$message.button('OK')],
      });
    },
    // checkSort: function () {
    //   if ((this.$fbProject == 'campinginontario' || (this.$fbProject == 'campon' && this.menuId == 'main-menu')) && !this.isResortAdmin) {
    //     for (let i = 0; i < 6; i++) {
    //       if (this.gridMenuItemsArray[i] != i) {
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // },
    getSortClass(index) {
      if ((this.$fbProject == 'campinginontario' || (this.$fbProject == 'campon' && this.menuId == 'main-menu')) && !this.isResortAdmin) {
        if (index < 6) {
          if (this.action == 'sort-button') return 'undraggable';
          else return '';
        }
      }
      return 'draggable';
    },

    moveItems(evt) {
      const item = this.gridMenuItemsArray.splice(evt.oldIndex, 1)[0];
      this.gridMenuItemsArray.splice(evt.newIndex, 0, item);
    },
    setAction(action) {
      if (action == 'submit') {
        action = '';
        this.fetchData();
      }
      this.action = action;
      switch (action) {
        case 'sort-submit': {
          // if (this.checkSort()) {
          //   this.fetchData();
          //   this.sortAlert();
          //   return;
          // }
          firebase
            .database()
            .ref('resort-navigator')
            .child('location-data')
            .child(this.getCampgroundKey)
            .child(this.menuId)
            .set(this.gridMenuItemsArray);
          this.fetchData();
          break;
        }
        case 'sort-cancel':
          this.fetchData();
          break;
        case 'sort-alpha': {
          let submitRef = this.getContextRef(this.menuId);
          const itemArray = this.gridMenuItemsArray.sort((a, b) => {
            const titleA = a.title || a.name;
            const titleB = b.title || b.name;
            if (titleA < titleB) {
              return -1;
            }
            if (titleA > titleB) {
              return 1;
            }
            return 0;
          });
          submitRef.set(itemArray);
          this.action = null;
          break;
        }
        case 'header-images':
          this.rightColumnProps = {
            section: 'actions',
            context: action,
            dbContext: 'main-menu',
            displayType: 'HeaderImages',
            tabs: this.tabs,
          };
          this.currentRightColumnComponent = 'HeaderImages';
          break;
        default:
          if (this.currentRightColumnComponent) {
            this.currentRightColumnComponent = null;
          } else if (action !== 'sort-button') {
            this.$router.go(-1);
          }
      }
    },
  },
};
</script>

<style>
.width-100 {
  width: 100%;
}
.grid-container {
  min-height: 500px;
  list-style-type: none;
  margin: 0px;
  padding: 10px 0px;
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  grid-gap: 20px;
}

.IZ-select__item {
  display: inherit !important;
}

.undraggable {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
</style>
