<template>
  <div id="studio-page" class="w-100 text-center text-white">
    <div v-if="uid" id="header-buttons">
      <button id="logout" class="header-button" @click.stop="logout"><span>Log Out</span></button>
    </div>
    <img id="amc-logo" src="/amc_logo_large.png" />
    <div v-if="!completedWizard" class="mt-5">
      <div class="d-inline-flex">
        <div class="login">
          <div class="row" v-if="!uid">
            <div class="col">
              <button v-if="currentStep + 1 > steps.login" class="btn btn-primary my-2 w-100" data-bs-toggle="collapse" data-bs-target="#step-8">
                Create App
              </button>
              <div id="step-8" class="collapse mt-2" :class="{ show: currentStep == steps.login }">
                <h5>
                  Create a login so you can <br />
                  <span class="highlight">download your app</span> <br />directly to your phone and<br />
                  <span class="highlight">customize further.</span>
                </h5>
                <h4 class="mt-5">Google</h4>
                <ul class="list-unstyled">
                  <li>
                    <button class="firebaseui-idp-button btn btn-raised firebaseui-idp-google" data-provider-id="google.com" @click="googleLogin">
                      <span class="firebaseui-idp-icon-wrapper">
                        <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
                      </span>
                      <span class="firebaseui-idp-text firebaseui-idp-text-long">Sign in with Google</span>
                      <span class="firebaseui-idp-text firebaseui-idp-text-short">Google</span>
                    </button>
                  </li>
                </ul>
                - or -
                <h4 class="mt-3">Email</h4>
                <input v-model="displayName" type="text" placeholder="Full Name" class="form-control" />
                <input v-model="email" type="text" placeholder="Email" class="form-control" /><br />
                <input v-model="password" type="password" placeholder="Password" class="form-control" /><br />
                <ul class="list-unstyled mb-5">
                  <li class="firebaseui-list-item">
                    <button class="firebaseui-idp-button btn btn-raised firebaseui-idp-password" data-provider-id="password" @click="login">
                      <span class="firebaseui-idp-icon-wrapper">
                        <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg" />
                      </span>
                      <span class="firebaseui-idp-text firebaseui-idp-text-long">Sign in with email</span>
                      <span class="firebaseui-idp-text firebaseui-idp-text-short">Email</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row d-none">
            <div class="col">
              <button v-if="currentStep + 1 > steps.icons" class="btn btn-primary my-2 w-100" data-bs-toggle="collapse" data-bs-target="#step-7">
                Icons
              </button>
              <div id="step-7" class="collapse mt-2" :class="{ show: currentStep == steps.icons }">
                <div class="mb-3">
                  <label for="icon" class="form-label">Select Icon Style</label>
                  <IconSet v-model="newItem.iconSet" :color-icon-shape="newItem.colorBackground" color-icon-background="#ffffff" :icon="baseIcon" />
                </div>
                <button class="btn btn-accent my-2" @click="nextStep(steps.icons)">
                  {{ stepText(steps.icons) }}
                </button>
              </div>
            </div>
          </div>
          <div class="row d-none">
            <div class="col">
              <button v-if="currentStep + 1 > steps.colors" class="btn btn-primary my-2 w-100" data-bs-toggle="collapse" data-bs-target="#step-6">
                App Colors
              </button>
              <div id="step-6" class="collapse mt-2" :class="{ show: currentStep == steps.colors }">
                <div class="mb-3 text-start">
                  <div class="color-selector">
                    <label class="btn btn-light" @click.stop="editBackground = true">
                      <div :style="`background-color:${newItem.colorBackground}`">&nbsp;</div>
                    </label>
                    <span class="ms-2">Background Color</span>
                  </div>
                  <chrome-picker
                    v-show="editBackground"
                    id="editBackground"
                    v-model="colorBackground"
                    v-click-outside="onColorClose"
                    :data-edit="editBackground" />
                </div>
                <div class="mb-3 text-start">
                  <div class="color-selector">
                    <label class="btn btn-light" @click.stop="editPrimary = true">
                      <div :style="`background-color:${newItem.colorPrimary}`">&nbsp;</div>
                    </label>
                    <span class="ms-2">Header Color</span>
                  </div>
                  <chrome-picker v-show="editPrimary" id="editPrimary" v-model="colorPrimary" v-click-outside="onColorClose" />
                </div>
                <div class="mb-3 text-start">
                  <div class="color-selector">
                    <label class="btn btn-light" @click.stop="editAccent = true">
                      <div :style="`background-color:${newItem.colorAccent}`">&nbsp;</div>
                    </label>
                    <span class="ms-2">Accent Color</span>
                  </div>
                  <chrome-picker v-show="editAccent" id="editAccent" v-model="colorAccent" v-click-outside="onColorClose" />
                </div>
                <button class="btn btn-accent my-2" @click="nextStep(steps.colors)">
                  {{ stepText(steps.colors) }}
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-if="espApp && communityKey">
            <div class="col">
              <button v-if="currentStep + 1 > steps.playImage" class="btn btn-primary my-2 w-100" data-bs-toggle="collapse" data-bs-target="#step-5">
                Play Image
              </button>
              <div id="step-5" class="collapse mt-2" :class="{ show: currentStep == steps.playImage }">
                <div class="photo-options-container">
                  <img
                    v-for="(item, index) in photos"
                    :key="index"
                    :src="item.getUrl()"
                    :class="{ 'photo-options': true, 'photo-selected': item.getUrl() == newItem.playDrawable }"
                    @click="newItem.playDrawable = item.getUrl()" />
                </div>
                <div>
                  Images above are retrieved from Google places. If you want these modified, find instructions
                  <a href="https://support.google.com/business/answer/6190090?hl=en" class="instruction-link" target="_blank">here.</a>
                </div>
                <Attachment
                  id="play"
                  ref="image5"
                  class="mt-4"
                  custom-modal-id="play"
                  label="Upload New Play Image"
                  :attachments="newItem.playDrawable.startsWith('https://maps.googleapis.com') ? [] : [newItem.playDrawable]"
                  :use-croppie="true"
                  :width="240"
                  :height="128"
                  @updateCroppieImage="updateHeaderImage('play', $event)" />
                <button class="btn btn-accent my-2" @click="nextStep(steps.playImage)">
                  {{ stepText(steps.playImage) }}
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-if="espApp && communityKey">
            <div class="col">
              <button v-if="currentStep + 1 > steps.shopImage" class="btn btn-primary my-2 w-100" data-bs-toggle="collapse" data-bs-target="#step-4">
                Shop Image
              </button>
              <div id="step-4" class="collapse mt-2" :class="{ show: currentStep == steps.shopImage }">
                <div class="photo-options-container">
                  <img
                    v-for="(item, index) in photos"
                    :key="index"
                    :src="item.getUrl()"
                    :class="{ 'photo-options': true, 'photo-selected': item.getUrl() == newItem.shopDrawable }"
                    @click="newItem.shopDrawable = item.getUrl()" />
                </div>
                <div>
                  Images above are retrieved from Google places. If you want these modified, find instructions
                  <a href="https://support.google.com/business/answer/6190090?hl=en" class="instruction-link" target="_blank">here.</a>
                </div>
                <Attachment
                  id="shop"
                  ref="image4"
                  class="mt-4"
                  custom-modal-id="shop"
                  label="Upload New Shop Image"
                  :attachments="newItem.shopDrawable.startsWith('https://maps.googleapis.com') ? [] : [newItem.shopDrawable]"
                  :use-croppie="true"
                  :width="240"
                  :height="128"
                  @updateCroppieImage="updateHeaderImage('shop', $event)" />
                <button class="btn btn-accent my-2" @click="nextStep(steps.shopImage)">
                  {{ stepText(steps.shopImage) }}
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-if="espApp && communityKey">
            <div class="col">
              <button v-if="currentStep + 1 > steps.eatImage" class="btn btn-primary my-2 w-100" data-bs-toggle="collapse" data-bs-target="#step-3">
                Eat Image
              </button>
              <div id="step-3" class="collapse mt-2" :class="{ show: currentStep == steps.eatImage }">
                <div class="photo-options-container">
                  <img
                    v-for="(item, index) in photos"
                    :key="index"
                    :src="item.getUrl()"
                    :class="{ 'photo-options': true, 'photo-selected': item.getUrl() == newItem.eatDrawable }"
                    @click="newItem.eatDrawable = item.getUrl()" />
                </div>
                <div>
                  Images above are retrieved from Google places. If you want these modified, find instructions
                  <a href="https://support.google.com/business/answer/6190090?hl=en" class="instruction-link" target="_blank">here.</a>
                </div>
                <Attachment
                  id="eat"
                  ref="image3"
                  class="mt-4"
                  custom-modal-id="eat"
                  label="Upload New Eat Image"
                  :attachments="newItem.eatDrawable.startsWith('https://maps.googleapis.com') ? [] : [newItem.eatDrawable]"
                  :use-croppie="true"
                  :width="240"
                  :height="128"
                  @updateCroppieImage="updateHeaderImage('eat', $event)" />
                <button class="btn btn-accent my-2" @click="nextStep(steps.eatImage)">
                  {{ stepText(steps.eatImage) }}
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-if="communityKey">
            <div class="col">
              <button
                v-if="currentStep + 1 > steps.headerImage"
                class="btn btn-primary my-2 w-100"
                data-bs-toggle="collapse"
                data-bs-target="#step-2">
                Header Image
              </button>
              <div id="step-2" class="collapse mt-2" :class="{ show: currentStep == steps.headerImage }">
                <div class="photo-options-container">
                  <img
                    v-for="(item, index) in photos"
                    :key="index"
                    :src="item.getUrl()"
                    :class="{ 'photo-options': true, 'photo-selected': item.getUrl() == newItem.drawable }"
                    @click="newItem.drawable = item.getUrl()" />
                </div>
                <div>
                  Images above are retrieved from Google places. If you want these modified, find instructions
                  <a href="https://support.google.com/business/answer/6190090?hl=en" class="instruction-link" target="_blank">here.</a>
                </div>
                <Attachment
                  v-show="espApp"
                  id="headeresp"
                  ref="image2esp"
                  class="mt-4"
                  custom-modal-id="headeresp"
                  label="Upload New Header Image"
                  :attachments="newItem.drawable.startsWith('https://maps.googleapis.com') ? [] : [newItem.drawable]"
                  :use-croppie="true"
                  :width="240"
                  :height="96"
                  @updateCroppieImage="updateHeaderImage('headeresp', $event)" />
                <Attachment
                  v-show="!espApp"
                  id="header"
                  ref="image2"
                  class="mt-4"
                  custom-modal-id="header"
                  label="Upload New Header Image"
                  :attachments="newItem.drawable.startsWith('https://maps.googleapis.com') ? [] : [newItem.drawable]"
                  :use-croppie="true"
                  :width="240"
                  :height="128"
                  @updateCroppieImage="updateHeaderImage('header', $event)" />
                <button class="btn btn-accent my-2" @click="nextStep(steps.headerImage)">
                  {{ stepText(steps.headerImage) }}
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-primary w-100 mb-2" data-bs-toggle="collapse" data-bs-target="#community">Community</button>
              <div id="community" class="collapse mt-2" :class="{ show: currentStep == 1 }">
                <template v-if="waitingOnCurrentApps">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Finding Prior Apps</span>
                  </div>
                  <div>Finding Prior Apps</div>
                </template>
                <template v-else>
                  <button v-for="appName in currentAppNames" :key="appName" class="btn btn-primary w-100" @click="openAppByAppName(appName)">
                    Customize {{ appName }}
                  </button>
                  <div v-if="currentAppNames.length" class="my-4">- or -</div>
                  <LocationSelector
                    :data-object="newItem"
                    hide-components
                    :display-title="false"
                    type="create"
                    :placeholder="currentAppNames.length ? `Create New Community` : `Search for Your Community`"
                    @updateLocation="updateLocation" />
                  <div class="form-group" v-if="newItem.title" label="Community Type">
                    <select class="form-select" v-model="communityType">
                      <option value="resort">RV Park / Resort</option>
                      <option value="municipality">Municipality / Chamber of Commerce</option>
                      <option value="school">School</option>
                    </select>
                  </div>
                  <div class="form-group" v-if="communityType == 'municipality'">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="espApp" id="espAppCheckbox" />
                      <label class="form-check-label" for="espAppCheckbox"> Eat, Shop, Play Style App </label>
                    </div>
                  </div>
                  <button class="btn btn-accent my-2" @click="nextStep(1)">{{ stepText(1) }}</button>
                </template>
              </div>
            </div>
          </div>
          <div class="mt-5">All selections made while creating your app can be easily modified later.</div>
        </div>
        <div v-if="espApp" id="esp-container" class="phone-container">
          <div id="phone-data" :style="`background-color:${newItem.colorBackground}`">
            <div class="position-relative">
              <i id="banner-menu" class="material-icons mt-4">menu</i>

              <div class="position-absolute w-100" style="bottom: 5px; font-size: x-large; line-height: 26px; letter-spacing: 1.5px">
                EAT • SHOP • PLAY
                <br />
                {{ newItem.title.toUpperCase() }}
              </div>
              <img v-if="newItem.drawable" class="esp-header" :src="newItem.drawable" />
            </div>
            <div class="esp-section">
              <img v-if="newItem.drawable" class="esp-image" :src="newItem.eatDrawable" />
              <div class="esp-scrim">
                <div class="esp-text">EAT</div>
              </div>
            </div>
            <div class="esp-section">
              <img v-if="photos[1]" class="esp-image" :src="newItem.shopDrawable" />
              <div class="esp-scrim">
                <div class="esp-text">SHOP</div>
              </div>
            </div>
            <div class="esp-section">
              <img v-if="photos[2]" class="esp-image" :src="newItem.playDrawable" />
              <div class="esp-scrim">
                <div class="esp-text">PLAY</div>
              </div>
            </div>
          </div>
          <img class="phone-frame" src="/iPhoneFrame.png" />
        </div>
        <div class="phone-container">
          <div id="phone-data" :style="`background-color:${newItem.colorBackground}`">
            <div id="banner" :style="`background-color:${newItem.colorPrimary}`">
              <i id="banner-menu" class="material-icons">menu</i>
              <div id="banner-title">
                {{ bannerText() }}
              </div>
            </div>
            <img v-if="newItem.drawable" id="phone-header" :src="espApp ? newItem[`${bannerText().toLowerCase()}Drawable`] : newItem.drawable" />
            <ul v-if="mainMenu" class="position-relative grid-container">
              <GridMenuItems
                v-for="(item, index) in mainMenu"
                :key="index"
                :item="item"
                :item-id="index"
                db-context="main-menu"
                :icon-set="newItem.iconSet"
                :main="true"
                :updated-color-icon-shape="newItem.colorBackground"
                updated-color-icon-background="#ffffff" />
            </ul>
          </div>
          <img class="phone-frame" src="/iPhoneFrame.png" />
        </div>
      </div>
    </div>
    <div v-else class="mt-5">
      <div class="login position-relative">
        <div>
          <h4 id="sign-in">Download Now</h4>
          <!--div class="download-text"><div class="popover b-popover bs-popover-left bs-popover-left-docs" style="width:255px;"> <div class="arrow" style="top:0"></div> <h3 class="popover-header">Scan or Click</h3> <div class="popover-body"> Scan or click with your phone to download <br/>"AppMyCommunity Studio"<br/> and login to explore your app. </div> </div>
                    </div-->
          <div class="download-text">
            Scan or click with your phone to download
            <div class="mt-3 mb-3">"AppMyCommunity Studio"</div>
            Login to explore your app.
          </div>

          <img :src="`/amcstudio_onelink.png`" class="p-1" />
          <div class="mt-4">
            <a href="https://play.google.com/store/apps/details?id=com.armstrongsoft.amcstudio">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                style="height: 60px" />
            </a>
          </div>
          <div>
            <a
              href="https://itunes.apple.com/us/app/id1535230834"
              style="
                display: inline-block;
                overflow: hidden;
                background: url(https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1617580800&h=162d5a71490a3e8a731aa2c6c51c2cad)
                  no-repeat;
                width: 135px;
                height: 45px;
                background-size: cover;
                margin: auto;
              ">
            </a>
          </div>
          <div class="branded-text">
            Will my users need to download "AppMyCommunity Studio?"<br /><br />
            <span style="font-style: italic"> No! Your users will download your app, {{ newItem.title }}, 100% branded for your community. </span>
          </div>
        </div>
        <button class="btn btn-accent" :disabled="!completedAppCreation" @click="openApp(communityKey, true)">
          <template v-if="completedAppCreation"> Continue Customizing </template>
          <template v-else>
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            Building App
          </template>
        </button>
      </div>
    </div>
    <div id="zsiqwidget"></div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import LocationSelector from '../components/LocationSelector';
import GridMenuItems from '../components/listitems/GridMenuItems';
import Attachment from '../components/Attachment';
import { creationMenus } from '../json/menuTemplates.json';
import { Chrome } from 'vue-color';
import axios from 'axios';
import Vue from 'vue';
import { LoginMixin } from '../components/mixins/LoginMixin.js';
import tzLookup from 'tz-lookup';
import Hotjar from 'vue-hotjar';
import IconSet from '../components/inputfields/IconSet';

export default {
  name: 'login',
  components: {
    LocationSelector,
    'chrome-picker': Chrome,
    GridMenuItems,
    IconSet,
    Attachment,
  },
  mixins: [LoginMixin],
  data() {
    return {
      steps: {
        community: 1,
        headerImage: 2,
        eatImage: 3,
        shopImage: 4,
        playImage: 5,
        colors: 6,
        icons: 7,
        login: 8,
      },
      uid: firebase.auth().currentUser ? firebase.auth().currentUser.uid : '',
      newApp: true,
      currentStep: 1,
      espApp: false,
      creationMenus,
      newItem: {
        colorBackground: '#014364',
        colorPrimary: '#838383',
        colorAccent: '#1DA9F3',
        iconSet: 'svg_parks',
        drawable:
          'https://firebasestorage.googleapis.com/v0/b/fourfield-pond.appspot.com/o/fourfield-pond%2Fimages%2Fmisc%2F1588698065863%2Fnewheader.png?alt=media&token=9233bcd9-abb6-4892-ba8d-5d59372876dc',
        eatDrawable: '',
        shopDrawable: '',
        playDrawable: '',
      },
      mainMenu: {},
      editBackground: false,
      editPrimary: false,
      editAccent: false,
      colorBackground: '#1DA9F3',
      colorPrimary: '#183449',
      colorAccent: '#1DA9F3',
      communityKey: '',
      communityType: 'resort',
      photos: [],
      error: null,
      success: false,
      modalHTML: '',
      modalTitle: 'Reset Password',
      email: '',
      password: '',
      displayName: '',
      passwordReset: false,
      completedWizard: false,
      completedAppCreation: false,
      allAppInfo: {},
      currentAppNames: [],
      waitingOnCurrentApps: false,
      sendAddresses: [
        { email: 'rose@armstrongsoft.com' },
        { email: 'joe@armstrongsoft.com' },
        { email: 'mmbutler@armstrongsoft.com', type: 'municipality' },
        { email: 'paul@armstrongsoft.com', type: 'resort' },
      ],
      baseIcon: {
        search: {
          terms: [],
        },
        styles: ['solid'],
        label: 'Calendar',
        svg: {
          solid: {
            raw: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"/></svg>',
          },
        },
      },
    };
  },
  computed: {
    getUpdatedBackgroundColor: function () {
      return { 'background-color': this.newItem.colorBackground };
    },
  },
  watch: {
    colorBackground: function () {
      this.newItem.colorBackground = this.colorBackground.hex || this.newItem.colorBackground;
      this.$emit('update-style', this.newItem);
    },
    colorPrimary: function () {
      this.newItem.colorPrimary = this.colorPrimary.hex || this.newItem.colorPrimary;
      this.$emit('update-style', this.newItem);
    },
    colorAccent: function () {
      this.newItem.colorAccent = this.colorAccent.hex || this.newItem.colorAccent;
      this.$emit('update-style', this.newItem);
    },
    communityType: async function () {
      this.espApp = this.communityType !== 'municipality' ? false : this.espApp;
      this.setMenuItems();
    },
    espApp: async function () {
      if (this.espApp) {
        this.newItem.drawable =
          'https://firebasestorage.googleapis.com/v0/b/appmycommunity-studio.appspot.com/o/studio_header.jpg?alt=media&token=8a1a9540-5d1d-4382-bf55-331bdbee06c3';
      }
      this.setMenuItems();
    },
  },
  created: async function () {
    this.setMenuItems();
    Vue.use(Hotjar, {
      id: '3596566',
      isProduction: true,
    });
    if (this.uid) {
      this.getCurrentApps();
    }
  },
  mounted() {
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: '7959affddcac5507cb64242a9a9db6d8e92b82ddb6a39fcfb6560d1d7d77c44b761cd6989df461283b52601217c64533',
      values: {},
      ready: function () {},
    };
    var d = document;
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zoho.com/widget';
    var t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  },

  methods: {
    openAppByAppName: async function (appName) {
      let editorDefault = (
        await firebase.database().ref('user-authorization').child('users').child(this.uid).child('editorDefault').once('value')
      ).val();
      if (!this.allAppInfo[appName].startsWith(editorDefault)) {
        editorDefault = this.allAppInfo[`${appName}-eat`] || this.allAppInfo[appName];
      }
      this.openApp(editorDefault, false);
    },
    openApp: async function (campgroundKey, espCheck) {
      const updatedCampgroundKey = espCheck && this.espApp ? `${campgroundKey}-eat` : campgroundKey;
      firebase.database().ref('user-authorization').child('users').child(this.uid).child('editorDefault').set(updatedCampgroundKey);
      window.localStorage.setItem('CAMPGROUND_KEY', updatedCampgroundKey);
      this.setCampgroundLocationData(updatedCampgroundKey);
      window.location.href = '/';
    },
    getCurrentApps: async function () {
      this.waitingOnCurrentApps = true;
      const currentApps = (await firebase.database().ref('user-authorization').child('users').child(this.uid).once('value')).val();
      if (currentApps) {
        this.currentAppNames = [
          ...new Set(
            await Promise.all(
              Object.keys(currentApps)
                .filter((appId) => appId.startsWith('-'))
                .map(async (appId) => {
                  const appName = (
                    await firebase.database().ref('resort-navigator').child('campground-locations').child(appId).child('appName').once('value')
                  ).val();
                  this.allAppInfo[appName] = appId;
                  return appName;
                })
            )
          ),
        ].sort();
      }
      this.waitingOnCurrentApps = false;
    },
    bannerText: function () {
      if (this.espApp) {
        return this.currentStep <= this.steps.eatImage ? 'Eat' : this.currentStep == this.steps.shopImage ? 'Shop' : 'Play';
      }
      return this.newItem.title || 'Fourfield Pond RV Resort';
    },
    updateHeaderImage: function (option, imageURL) {
      if (option == 'header' || option == 'headeresp') {
        this.newItem.drawable = imageURL;
        this.newItem.localDrawable = imageURL;
      } else {
        this.newItem[`${option}Drawable`] = imageURL;
      }
    },
    setMenuItems: async function (type) {
      const communityType = this.espApp ? type || 'eat' : this.communityType;
      console.log('🚀 ~ file: Studio.vue:616 ~ communityType:', communityType);
      let templateContent = (
        await firebase.database().ref('resort-navigator').child('location-data').child(`templates-${communityType}`).child('main-menu').once('value')
      ).val();
      if (typeof templateContent == 'object') {
        templateContent = Object.values(templateContent);
      }
      this.mainMenu = templateContent.filter((item) => item);
    },
    stepText: function (currentStep) {
      return currentStep == this.currentStep ? 'Next' : 'Save';
    },
    nextStep: async function (submittedStep) {
      if (submittedStep > this.steps.community && submittedStep < this.steps.colors) {
        let imageName = `image${submittedStep}${submittedStep == this.steps.headerImage && this.espApp ? 'esp' : ''}`;
        await this.$refs[imageName].saveCrop();
      }
      if (submittedStep == this.currentStep) {
        firebase.database().ref('resort-navigator').child('studio-activity').child(this.communityKey).child('step').set(this.currentStep);

        this.currentStep++;
        if (this.currentStep == this.steps.login && this.uid) {
          this.createApp(this.uid, this.email);
        } else if (!this.espApp && this.currentStep == this.steps.eatImage) {
          this.currentStep = this.steps.colors;
        }

        if (this.currentStep == this.steps.colors) {
          this.currentStep = 8;
          if (this.getCurrentUserEmail()) {
            this.createApp(firebase.auth().currentUser.uid, this.getCurrentUserEmail());
          } else {
            this.sendCreateEmail();
          }
        }
      }
      if (this.currentStep == this.steps.shopImage) {
        this.setMenuItems('shop');
      } else if (this.currentStep == this.steps.playImage) {
        this.setMenuItems('play');
      }
      this.$root.$emit('bv::toggle::collapse', `step-${this.currentStep}`);
    },
    updateLocation: function (locationData) {
      this.newItem = { ...this.newItem, ...locationData };
      this.newItem.appName = this.newItem.title;
      let types = this.newItem.types || [];
      this.newItem.timeZone = tzLookup(locationData.latitude, locationData.longitude);
      if (types.includes('school')) {
        this.communityType = 'school';
      } else if (types.includes('locality')) {
        this.communityType = 'municipality';
      } else {
        this.communityType = 'resort';
      }
      if (this.newItem.photos && this.newItem.photos.length > 0) {
        this.newItem.drawable = this.newItem.photos[0].getUrl();
        this.newItem.eatDrawable = this.newItem.photos[1] ? this.newItem.photos[1].getUrl() : this.newItem.photos[0].getUrl();
        this.newItem.shopDrawable = this.newItem.photos[2] ? this.newItem.photos[2].getUrl() : this.newItem.photos[0].getUrl();
        this.newItem.playDrawable = this.newItem.photos[3] ? this.newItem.photos[3].getUrl() : this.newItem.photos[0].getUrl();
        this.photos = locationData.photos;
      } else {
        this.newItem.drawable = '';
      }
      this.photos = Array.isArray(this.photos) ? this.photos.slice(0, 9) : this.photos;
      this.newItem.iconSet = 'svg_round';
      this.$delete(this.newItem, 'hours');
      this.$delete(this.newItem, 'distance');
      this.$delete(this.newItem, 'location');
      this.$delete(this.newItem, 'types');
      this.$delete(this.newItem, 'photos');
      this.communityKey = firebase.database().ref('resort-navigator').push().key;
      window.localStorage.setItem('CAMPGROUND_KEY', this.communityKey);
      let body = `${this.newItem.title} - ${this.newItem.city}, ${this.newItem.state} has been requested with an id of ${this.communityKey}`;
      body += this.getCurrentUserEmail() ? ` Logged in email address: ${this.getCurrentUserEmail()}` : '';
      const queueDBRef = firebase.database().ref('resort-navigator').child('user-written').child('studio').child('email-queue');
      const updates = this.sendAddresses.reduce((acc, emailAddress) => {
        if (emailAddress.type && emailAddress.type !== this.communityType) {
          return acc;
        }
        const newPostKey = queueDBRef.push().key;
        acc[newPostKey] = {
          subject: 'New Location Searched',
          body: body,
          emailType: 3,
          email: emailAddress.email,
        };
        return acc;
      }, {});
      queueDBRef.update(updates);
    },
    onColorClose: function () {
      this.editBackground = false;
      this.editPrimary = false;
      this.editAccent = false;
    },
    getCurrentUserEmail() {
      return firebase.auth().currentUser?.email;
    },
    login: function () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async (result) => {
          this.updateUserAndCompleteLogin(result);
        })
        .catch((err) => {
          if (err.code == 'auth/user-not-found') {
            firebase
              .auth()
              .createUserWithEmailAndPassword(this.email, this.password)
              .then(async (result) => {
                this.updateUserAndCompleteLogin(result);
              })
              .catch((err) => {
                alert(err.message);
              });
          } else {
            alert(err.message);
          }
        });
    },
    updateUserAndCompleteLogin: function (userRecord) {
      firebase
        .auth()
        .currentUser.updateProfile({
          displayName: this.displayName,
        })
        .then(() => {
          this.uid = userRecord.user.uid;
          this.createApp(userRecord.user.uid, userRecord.user.email);
        })
        .catch((err) => {
          console.log('🚀 ~ file: Studio.vue ~ line 814 ~ .then ~ err', err.message);
        });
    },
    googleLogin: function () {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          this.uid = result.user.uid;
          this.createApp(result.user.uid, result.user.email);
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    logout: function () {
      window.location.href = '/login?action=logout';
    },
    sendCreateEmail: function (email) {
      let body = email
        ? `${this.newItem.title} - ${this.newItem.city}, ${this.newItem.state} has been created from the user: ${email}`
        : `${this.newItem.title} login step.`;
      body += ` The app id is ${this.communityKey}`;
      body += this.getCurrentUserEmail() ? ` Logged in email address: ${this.getCurrentUserEmail()}` : '';
      const queueDBRef = firebase.database().ref('resort-navigator').child('user-written').child('studio').child('email-queue');
      const updates = this.sendAddresses.reduce((acc, emailAddress) => {
        if (emailAddress.type && (emailAddress.type !== this.communityType || !email)) {
          return acc;
        }
        const newPostKey = queueDBRef.push().key;
        acc[newPostKey] = {
          subject: 'New Studio Location Created',
          body: body,
          emailType: 3,
          email: emailAddress.email,
        };
        return acc;
      }, {});
      queueDBRef.update(updates);
    },
    async createApp(uid, email) {
      this.sendCreateEmail(email);
      await this.getCurrentApps();
      this.newItem.appName = this.espApp ? `${this.newItem.appName} - Eat Shop Play` : this.newItem.appName;
      const count = this.currentAppNames.reduce((acc, name) => {
        return name.startsWith(this.newItem.appName) ? acc + 1 : acc;
      }, 0);
      this.newItem.appName = count > 0 ? `${this.newItem.appName} - V${count + 1}` : this.newItem.appName;

      firebase.database().ref('resort-navigator').child('studio-activity').child(this.communityKey).update({
        step: 0,
        uid,
        completed: Date.now(),
      });
      this.uid = uid;
      const url = `${this.$apihostname}/api/studio`;
      axios
        .post(url, {
          uid,
          communityData: this.newItem,
          communityKey: this.communityKey,
          communityType: this.communityType,
          mainMenu: creationMenus[this.communityType],
          espApp: this.espApp ?? false,
        })
        .then((response) => {
          window.localStorage.setItem('CAMPGROUND_KEY', response.data.communityKey);
          this.authorizeUser(uid, false);
          this.completedAppCreation = true;
        });
      this.completedWizard = true;
    },
  },
};
</script>

<style>
#step-4 #icon .select-icon {
  width: 70px;
  height: 70px;
  margin: auto;
}
.login .color-selector div {
  width: 20px;
  height: 20px;
}
.login .color-selector .btn {
  padding: 0.25rem 0.25rem;
}
.login .vc-chrome {
  width: 175px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
}
#banner-menu {
  position: absolute;
  left: 15px;
}

#banner {
  white-space: nowrap;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  font-weight: 700;
  overflow: hidden;
}
#banner-title {
  max-width: calc(100% - 85px);
}
#phone-data .grid-container {
  min-height: 350px;
  list-style-type: none;
  margin: 0px 10px;
  padding: 10px 0px;
  display: grid;
  grid-template-columns: repeat(3, minmax(60px, 1fr));
  grid-gap: 20px;
}
.photo-options-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.instruction-link {
  color: var(--accent);
}
span.highlight {
  color: var(--accent);
}
.photo-options.photo-selected {
  border-color: var(--accent);
}
.photo-options:hover {
  border-color: var(--secondary);
}
.photo-options {
  height: 68px;
  width: 110px;
  object-fit: cover;
  border: 2px solid;
  border-color: var(--primary);
  cursor: pointer;
}
@media (min-width: 1000px) {
  .download-text {
    position: absolute;
    width: 320px;
    top: 10px;
    margin-left: -350px;
    font-weight: 600;
    font-size: x-large;
    text-align: left;
  }
  .branded-text {
    position: absolute;
    width: 320px;
    margin-left: 450px;
    bottom: 0px;
    font-weight: 600;
    font-size: x-large;
    text-align: left;
  }
}
.esp-image {
  height: 143px;
  width: 275px;
  object-fit: cover;
}
.esp-header {
  height: 135px;
  width: 275px;
  object-fit: cover;
}

#phone-header {
  height: 169px;
  width: 275px;
  object-fit: cover;
}

#phone-data .grid-icon-clickable {
  min-height: 50px;
}

#phone-data .grid-menu-item-icon {
  height: 25%;
  width: 25%;
  min-height: 40px;
  min-width: 40px;
}
#phone-data .grid-menu-item-name {
  font-size: smaller;
}
#phone-data >>> .grid-icon-set {
  display: none;
}
.esp-section {
  position: relative;
}
.esp-scrim {
  top: 0px;
  position: absolute;
  width: 100%;
  height: 143px;
  background-image: radial-gradient(200px 50px at 0px bottom, #000000dd, #00000000);
}
.esp-text {
  position: absolute;
  bottom: 10px;
  width: 100%;
  font-size: x-large;
  text-align: left;
  margin-left: 20px;
  border-left: 1px solid white;
  padding-left: 5px;
  line-height: 25px;
}
.esp-text:after {
  content: '';
  height: 1px;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 50%);
  display: block;
  margin-left: -5px;
}
.phone-container {
  position: relative;
  width: 350px;
}
.phone-frame,
#phone-data {
  position: absolute;
}
.phone-frame {
  width: 350px;
  left: 0;
  top: 0;
}

#phone-data {
  top: 17px;
  left: 47px;
  height: 567px;
  width: 270px;
}
#phone-data ul {
  height: 350px;
  overflow: hidden;
}
#sign-in,
#reset-password,
#forgot-password a:link,
#forgot-password a:visited {
  color: #01f2a6;
}
#forgot-password {
  width: 100%;
  text-align: right;
}

input {
  min-width: 64px;
  padding: 0 16px;
}
#studio-page {
  background-color: transparent;
  background-image: linear-gradient(180deg, #0a141d 0%, #183449 26%);
  min-height: max(100vh, 800px);
}
.login {
  background-color: transparent;
  background-image: linear-gradient(40deg, rgba(29, 169, 243, 0.3) 0%, #1da9f3 100%);
  width: 450px;
  border-radius: 12px;
  margin: auto;
  padding: 30px;
}
#header-buttons {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 10px;
  height: 50px;
  z-index: 999998;
}
#header-buttons i.material-icons {
  font-size: 22px;
  line-height: normal;
}
.header-button {
  border-radius: 999rem;
  box-shadow: none;
  background-color: #01f2a6 !important;
  color: #0e6046 !important;
  fill: #0e6046 !important;
  bottom: 0;
  padding: 13px 22px;
  letter-spacing: 0.6;
  border: none !important;
  font-weight: 700;
  margin: 0rem 0.25rem;
}

#logout {
  font-size: 15px;
}

#logout span {
  height: 20px;
  line-height: 20px;
}

@media screen and (max-width: 981px) and (orientation: portrait), (max-width: 740px) {
  #studio-page #amc-logo {
    display: inline;
  }
}
@media screen and (max-width: 522px) and (orientation: portrait), (max-width: 522px) {
  #studio-page #amc-logo {
    display: inline;
    width: 100%;
  }
}
</style>
