<template>
  <div>
    <div class="grid-icon-clickable" @click="clickGridItem(item)">
      <img
        v-if="item.icon && !item.iconData"
        :src="getIconSrc(item.icon, getMappedIconSet(getIconSet(iconSet)))"
        :alt="getImageAltText"
        class="grid-menu-item-icon" />
      <div v-else-if="getIconSet()" :style="getIconStyle(iconSet)" :class="iconClass">
        <div
          v-html="
            getSVG(
              item.iconData && item.iconData.iconSet ? item.iconData.iconSet : getIconSet(iconSet),
              colorIconBackground(updatedColorIconBackground)
            )
          "
          class="icon-bg" />
        <div v-if="item.auth" class="menu-item-restricted" />
        <div v-html="item.iconData.svg" :class="{ 'fa-size': true, 'svg-inline--fa': true, 'fa-w-14': !item.iconData.noTint }" />
      </div>
      <div class="grid-menu-item-name">
        {{ item.name }}
      </div>
      <div v-if="!hideEditButtons" class="grid-icon-set" @click.stop>
        <i class="item-menu material-icons"> more_vert </i>
        <i class="item-edit material-icons" @click.stop="editItem(itemId)"> edit </i>
        <i class="item-remove material-icons" @click.stop="openDeleteConfirmation(itemId, item)" v-if="canDelete()"> delete </i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item', 'itemId', 'dbContext', 'iconSet', 'main', 'updatedColorIconShape', 'updatedColorIconBackground', 'hideEditButtons', 'disableClick'],
  data() {
    return { menuId: 'main-menu' };
  },
  computed: {
    getImageAltText: function () {
      return `Icon for ${this.item.name}`;
    },
    iconClass: function () {
      const iconSet = this.item.iconData && this.item.iconData.iconSet ? this.item.iconData.iconSet : this.getIconSet(this.iconSet);
      return `text-center p-2 position-relative icon-wrapper ${iconSet}`;
    },
  },
  created() {
    this.isResortAdmin =
      window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
  },
  methods: {
    colorIconBackground: function (updatedColorIconBackground) {
      return this.item.iconData && this.item.iconData.colorIconBackground
        ? this.item.iconData.colorIconBackground
        : updatedColorIconBackground || this.getIconBackgroundColor;
    },
    colorIconShape: function (iconSet, updatedColorIconShape) {
      if (this.item.iconData && this.item.iconData.colorIconShape) return this.item.iconData.colorIconShape;
      const isPlain = this.getIconSet(this.item.iconData && this.item.iconData.iconSet ? this.item.iconData.iconSet : iconSet) == 'svg_plain';
      const colorShapeIsBackground = updatedColorIconShape && updatedColorIconShape.toLowerCase() == this.getBackgroundColor.toLowerCase();
      if (isPlain && colorShapeIsBackground) return '#ffffff';
      return (
        updatedColorIconShape || (isPlain && this.getIconColor.toLowerCase() == this.getBackgroundColor.toLowerCase() ? '#ffffff' : this.getIconColor)
      );
    },
    getIconStyle: function (iconSet) {
      return `color: ${this.colorIconShape(iconSet, this.updatedColorIconShape)}`;
    },
    getMappedIconSet: function (iconSet) {
      return iconSet == 'svg_plain' ? 'handdrawn' : iconSet.replace('svg_', '');
    },
    clickGridItem: function (gridMenuItem) {
      if (this.disableClick) return;
      this.menuId = this.dbContext.split('/')[0];
      let path = `/list/${this.menuId}/${this.itemId}/0`;
      console.log('clickGridItem', gridMenuItem);
      if (gridMenuItem.url) {
        window.open(gridMenuItem.url, '_blank');
        return;
      } else if (gridMenuItem.uniqueId == 'orders-admin') {
        path = '/listnm/orders';
      } else if (gridMenuItem.type == 'store-detail') {
        path = `/detail/store/store/${gridMenuItem.uniqueId}`;
      } else if (gridMenuItem.uniqueId == 'rewards') {
        path = '/detail/rewards/rewards';
      } else if (gridMenuItem.type == 'form') {
        path = `/detail/forms/custom-forms/${gridMenuItem.uniqueId}`;
      } else if (gridMenuItem.type == 'business-detail') {
        path = `/detail/business/business-detail/${gridMenuItem.uniqueId}`;
      } else if (gridMenuItem.type == 'searchablemap-detail') {
        path = `/detail/searchablemap/searchablemap-detail/${gridMenuItem.uniqueId}`;
      } else if (gridMenuItem.uniqueId == 'events') {
        path = `/amc-calendar/menu/${this.dbContext}/${this.itemId}`;
      } else if (gridMenuItem.uniqueId == 'amc-calendar') {
        path = `/amc-calendar/${gridMenuItem.uniqueId || gridMenuItem.type}/0`;
      } else if (gridMenuItem.uniqueId == 'messages') {
        path = `/amc-messages/${gridMenuItem.uniqueId}`;
      } else if (gridMenuItem.type == 'booking') {
        //Open external booking page in new tab
        this.openAcuityBooking(gridMenuItem.dbContext);
        return;
      }
      this.$router.push(path);
    },
    editItem: function (gridMenuKey) {
      if (this.main) {
        const path = `/${this.$route.name.split(`/`)[0]}/edit/${gridMenuKey}`;
        this.$router.push(path);
      } else {
        this.$emit('editItem', this.itemId);
      }
    },
    canDelete: function () {
      return !(
        (this.$fbProject == 'campinginontario' || this.$fbProject == 'campon') &&
        !this.isResortAdmin &&
        this.dbContext == 'main-menu' &&
        this.itemId < 6
      );
    },
    openDeleteConfirmation: function (gridMenuKey, item) {
      this.$message.create({
        title: 'Delete Item',
        body: `Are you sure you want to delete this item? ${item.name}`,
        buttons: [this.$message.button('Cancel', 'btn-secondary'), this.$message.button('Delete', 'btn-danger', () => this.deleteItem(gridMenuKey))],
      });
    },
    deleteItem: function (gridMenuKey) {
      const ref = this.getContextRef();
      ref.off();
      this.getContextRef().child(gridMenuKey).remove();
      this.$emit('deleteItem', this.itemId);
    },
    openAcuityBooking(gridMenuKey) {
      let ownerId, calendarId;

      console.log('Opening acuity link for', gridMenuKey, 'in new tab.');
      const dbRef = this.getLocationRef();
      dbRef
        .child('bookings')
        .child(gridMenuKey)
        .once('value')
        .then((snapshot) => {
          const acuityData = snapshot.val();
          if (acuityData) {
            ownerId = acuityData.owner;
            calendarId = acuityData.calendarId;
            window.open(`https://app.acuityscheduling.com/schedule.php?owner=${ownerId}&calendarID=${calendarId}`, '_blank');
          }
        });
    },
  },
};
</script>

<style scoped>
.grid-icon-clickable {
  min-height: 99px;
  color: white;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.grid-menu-item-icon {
  height: 75px;
  width: 75px;
}
.grid-icon-set {
  position: absolute;
  top: 0px;
  right: 0px;
  display: grid;
  grid-template-columns: 1fr;
  width: 30px;
  padding: 2px;
  cursor: initial;
}
.grid-icon-set i:first-of-type,
.grid-icon-set:hover i {
  display: block;
}
.grid-icon-set:hover {
  border: 1px grey solid;
  border-radius: 10px;
  background: #00000022;
}

.grid-icon-set i {
  margin: 4px 0px;
  display: none;
  cursor: pointer;
}
.svg_plain .fa-size {
  font-size: 3.5em;
}

.menu-item-restricted {
  background-image: url('~@/assets/restricted_banner_1920.png');
  width: 110px;
  height: 110px;
  background-size: cover;
  margin-top: -10px;
  position: relative;
}

:root {
  --bs-modal-color: #212529;
}
</style>
../common/BModal.vue
