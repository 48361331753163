import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    detailId: '',
    detailContextId: '',
    remoteInfo: {},
    uuid: '',
    community: {},
    params: {},
  },
  getters: {
    detailId: (state) => state.detailId,
    detailContextId: (state) => state.detailId,
    uuid: (state) => state.uuid,
    remoteInfo: (state) => state.remoteInfo,
    community: (state) => state.community,
    params: (state) => state.params,
  },
  mutations: {
    updateDetailId(state, detailId) {
      state.detailId = detailId;
    },
    updateDetailContextId(state, detailContextId) {
      state.detailContextId = detailContextId;
    },
    updateRemoteInfo(state, remoteInfo) {
      state.remoteInfo = remoteInfo;
    },
    updateCommunity(state, community) {
      state.community = community;
    },
    updateUUID(state, uuid) {
      state.uuid = uuid;
    },
    updateParams(state, params) {
      state.params = params;
    },
  },
});
