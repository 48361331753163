import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  data() {
    return {
      _assistants: {
        'event-coordinator': 'asst_xLoomhn2uY6F46d1sBGKw5ks',
        'hunt-coordinator': 'asst_83NKzSzhutJwQutJQbkyD1Qr',
        'icon-selector': 'asst_uikbAfMLmIZpMf0OJr6uD6qg',
      },
    };
  },
  methods: {
    /* Generates a written response from ChatGPT.
     * @param {string} prompt
     * @param {string} model. The model to use. gpt-3.5-turbo, gpt-3.5-turbo-1106, gpt-4, gpt-4-1106-preview, etc. https://platform.openai.com/docs/models
     * @param {function} processCallback. Callback function to show or hide a loading spinner.
     */
    async promptGPT(prompt, model, processCallback) {
      try {
        console.log(`OpenAIMixin.js:promptGPT, 12. Prompting ${model} with '${prompt}'.`);

        processCallback(true);
        const openAI = firebase.functions().httpsCallable('openAIPrompts');
        const data = {
          model,
          messages: [{ role: 'user', content: prompt }],
        };
        const response = await openAI(data);
        processCallback(false);

        console.log(response);
        return response;
      } catch (error) {
        console.error('Error:', error);
        processCallback(false);
      }
    },
    async promptDallE(prompt, model, size, quality, processCallback) {
      try {
        console.log(`OpenAIMixin.js:promptDallE, 32. Prompting ${model} at ${size} ${quality} quality with '${prompt}'.`);

        processCallback(true);
        const openAI = firebase.functions().httpsCallable('openAIPrompts');
        const data = { model, prompt, n: 1, size, quality };
        const response = await openAI(data);
        processCallback(false);

        console.log(response);
        return response;
      } catch (error) {
        console.error('Error:', error);
        processCallback(false);
      }
    },

    // Typical assistant key steps.
    // 1. Create an assistant.
    // 2. Create a thread.
    // 3. Send message(s) to the thread. Display input message (if desired).
    // 4. Run message(s) through the assistant. Display response to user.

    // Creating assistants.
    async createAssistant(name, instructions, model, tools) {
      const data = { assistant: { name, instructions, model, tools } };
      const response = await this.callAssistant(data, 'createAssistant');

      console.log('OpenAIMixin.js:69, assistant result.', response);
      return response;
    },
    async getAssistant(assistantId) {
      const data = { assistantId };
      const response = await this.callAssistant(data, 'getAssistant');

      console.log('OpenAIMixin.js:76, assistant result.', response);
      return response;
    },
    async listAssistants() {
      const data = {};
      const response = await this.callAssistant(data, 'listAssistants');

      console.log('OpenAIMixin.js:83, list assistants.', response);
      return response;
    },

    // Creating/getting threads.
    async createThread() {
      const data = {};
      const response = await this.callAssistant(data, 'createThread');

      console.log('OpenAIMixin.js:75, thread result.', response);
      return response;
    },
    async getThread(threadId) {
      const data = { threadId };
      const response = await this.callAssistant(data, 'getThread');

      console.log('OpenAIMixin.js:82, thread result.', response);
      return response;
    },

    // Creating/getting messages.
    async createMessage(threadId, messages) {
      const data = { threadId, messages };
      const response = await this.callAssistant(data, 'createMessage');

      console.log('OpenAIMixin.js:91, message result.', response);
      return response;
    },
    async getMessages(threadId) {
      const data = { threadId };
      const response = await this.callAssistant(data, 'getMessages');

      console.log('OpenAIMixin.js:98, message result.', response);
      return response;
    },

    // Running assistants/status of runs.
    async runAssistant(assistantId, threadId, instructions) {
      const data = { threadId, assistantId, instructions };
      const response = await this.callAssistant(data, 'runAssistant');

      console.log('OpenAIMixin.js:107, run result.', response);
      return response;
    },
    async runStatus(threadId, runId) {
      const data = { threadId, runId };
      const response = await this.callAssistant(data, 'runStatus');

      console.log('OpenAIMixin.js:114, run status.', response);
      return response;
    },
    async runCancel(threadId, runId) {
      const data = { threadId, runId };
      const response = await this.callAssistant(data, 'runCancel');

      console.log('OpenAIMixin.js:121, cancelling run.', response);
      return response;
    },

    //Utility methods for cleaner code.
    getPromptText(prompt) {
      return prompt.data.choices[0].message.content;
    },
    getMessageText(message) {
      return message.data.body.data[0].content[0].text.value;
    },
    getImage(response) {
      return response.data.data[0].url;
    },
    getJSONOutput(status) {
      return JSON.parse(status.data.required_action.submit_tool_outputs.tool_calls[0].function.arguments);
    },
    assistantMap(name) {
      if (this._assistants[name]) {
        return this._assistants[name];
      }
      return 'invalid';
    },

    //Utility method used internally.
    async callAssistant(data, action) {
      const openAI = firebase.functions().httpsCallable('openAIAssistant');
      data.action = action;
      return await openAI(data);
    },
  },
};
