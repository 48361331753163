<template>
  <div class="mb-3" id="icon">
    <label class="form-label">Select Icon Style</label>
    <cool-select :value="value" :items="iconSets" :placeholder="value ? '' : 'Select Icon'" :inputStyles="getInputStyles" @select="iconSelected">
      <template #item="{ item: currentIconSet }">
        <div :style="getIconStyle(currentIconSet)" class="text-center p-2 position-relative icon-wrapper">
          <div v-html="getSVG(currentIconSet)" class="icon-bg" />
          <!--font-awesome-icon :icon="icon" class="fa-size" /-->
          <div v-html="getIconData" class="fa-size svg-inline--fa fa-w-14" />
        </div>
      </template>
      <!-- slot for the selected item (in the text field) -->
      <template #selection="{ item: currentIconSet }">
        <div :style="getIconStyle(currentIconSet)" class="text-center icon-selected p-2 position-relative icon-wrapper">
          <div v-html="getSVG(currentIconSet)" class="icon-bg" />
          <!--font-awesome-icon :icon="icon" class="fa-size" /-->
          <div v-html="getIconData" class="fa-size svg-inline--fa fa-w-14" />
        </div>
      </template>
    </cool-select>
  </div>
</template>

<script>
import { CoolSelect } from 'vue-cool-select';
export default {
  props: ['value', 'icon', 'colorIconShape', 'colorIconBackground'],
  components: {
    CoolSelect,
  },
  created() {},
  data: function () {
    return {
      iconSets: ['svg_parks', 'svg_round', 'svg_traditional', 'svg_vintage', 'svg_plain'],
    };
  },
  methods: {
    iconSelected: function (selectedSet) {
      this.$emit('input', selectedSet);
    },
    getIconStyle: function (iconSet) {
      let colorShape =
        iconSet == 'svg_plain' && (!this.colorIconShape || this.colorIconShape?.toLowerCase() == this.getBackgroundColor.toLowerCase())
          ? '#ffffff'
          : this.colorIconShape;
      colorShape =
        iconSet !== 'svg_plain' && colorShape?.toLowerCase() == this.colorIconBackground?.toLowerCase() ? this.getBackgroundColor : colorShape;
      return `background-color: ${this.getBackgroundColor}; color: ${colorShape}`;
    },
  },
  computed: {
    getInputStyles: function () {
      return { 'background-color': this.getBackgroundColor };
    },
    getIconData: function () {
      if (this.icon.isCustom == true) {
        return this.icon.svg;
      } else if (!this.icon.isCustom) {
        return this.icon.svg[this.icon.styles[0]].raw;
      }

      return null;
    },
  },
};
</script>
<style lang="scss" scoped></style>
