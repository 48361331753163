import axios from 'axios';

const photosMixinPerPage = 15;

var errorMessaging = function (error, endpoint) {
  console.log('🚀 ~ file: ExternalPhotosMixin.js:6 ~ errorMessaging ~ endpoint:', endpoint);
  if (error.response) {
    console.log('🚀 ~ file: ExternalPhotosMixin.js:36 ~ searchUnsplash:function ~ error.response.data', error.response.data);
    console.log('🚀 ~ file: ExternalPhotosMixin.js:38 ~ searchUnsplash:function ~ error.response.status', error.response.status);
    console.log('🚀 ~ file: ExternalPhotosMixin.js:40 ~ searchUnsplash:function ~ error.response.headers', error.response.headers);
  } else {
    console.log('🚀 ~ file: ExternalPhotosMixin.js:65 ~ searchUnsplash:function ~ error', error);
  }
};
export const ExternalPhotosMixin = {
  // data() {},
  methods: {
    searchPexels: async function (query, page) {
      console.log('searchPexels called');
      // need to assign this value somewhere secret
      const API_KEY = '563492ad6f9170000100000162a338c8c3ca4bd1b723395e024bf80b';
      const BASE_URL = 'https://api.pexels.com/v1';
      const searchEndpoint = `${BASE_URL}/search`;
      const endpoint = `${searchEndpoint}?query=${query}&page=${page}&per_page=${photosMixinPerPage}`;

      const response = await axios
        .get(endpoint, {
          headers: {
            Authorization: API_KEY,
          },
        })
        .catch(function (error) {
          errorMessaging(error, endpoint);
        });
      console.log('pexels photos: ', response?.data || null);
      return response?.data || null;
    },
    searchUnsplash: async function (query, page) {
      const ACCESS_KEY = 'H1UYa8o4JUsroyPKuPigXcYN_4h1T3Bll8Av-cXBXF8';
      // const SECRET_KEY = 'AAvjEQYhpBka9f5KMJPYdlp3IgezlDtA2paw_PRgLAY';
      const BASE_URL = 'https://api.unsplash.com';
      const searchEndpoint = `${BASE_URL}/search/photos`;
      const endpoint = `${searchEndpoint}?query=${query}&page=${page}&per_page=${photosMixinPerPage}&content_filter=high`;

      const response = await axios
        .get(endpoint, {
          headers: {
            Authorization: `Client-ID ${ACCESS_KEY}`,
          },
        })
        .catch(function (error) {
          errorMessaging(error, endpoint);
        });
      console.log('unsplash photos: ', response?.data || null);
      return response?.data || null;
    },
    searchPixabay: async function (query, page) {
      const API_KEY = '31425585-6deac0e2d093be8b8e24bf8db';
      const BASE_URL = 'https://pixabay.com/api/';
      const endpoint = `${BASE_URL}?key=${API_KEY}&q=${encodeURIComponent(
        query
      )}&page=${page}&image_type=photo&per_page=${photosMixinPerPage}&safesearch=true`;

      const response = await axios.get(endpoint).catch(function (error) {
        errorMessaging(error, endpoint);
        return null;
      });
      console.log('pixabay photos: ', response?.data || null);
      return response?.data || null;
    },
  },
};
