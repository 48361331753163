<template>
<div>
    <Attachment id="header"
        label="Header Image"
        :attachments="[item.value]"
        :height="120"
        :width="768"
        :useCroppie="true"
        :title="item.name"
        @updateCroppieImage="updateHeaderImage"
         ref="useImage"/>
        <button class="btn m-1 btn-secondary" type="button" @click.prevent="cancel">Cancel</button>
</div>
</template>

<script>
    import Attachment from '../Attachment'
    export default {
        props: ["name", "value"],
        data(){
            return{
                item: {
                    name: this.name,
                    value: this.value
                }
            }
        },
        methods: {
            updateHeaderImage: function(imageURL){
                this.item.value = imageURL;
                this.$emit('updatePrintout', this.item);
            },
            cancel: function(){
                this.item.value = this.value;
                this.$emit('updatePrintout', this.item);
            }
        },
        components: {
            Attachment
        }
    }
</script>

<style lang="scss" scoped>

</style>
