import { createApp, defineComponent } from 'vue';
import PromptModal from '@/components/modals/PromptModal.vue';

export const Message = {
  create(options) {
    const div = document.createElement('div');
    document.body.appendChild(div);

    const Modal = defineComponent({
      extends: PromptModal,
      props: Object.keys(options),
      mounted() {
        this.$emit('show');

        this.$on('destroy', () => {
          app.unmount(div);
          document.body.removeChild(div);
        });
      },
    });

    const app = createApp(Modal, options);
    const instance = app.mount(div);
    instance.show();

    return { app, instance };
  },
  button(title, variant, action) {
    return {
      title,
      action,
      variant: variant || 'btn-accent',
    };
  },
  show(modal) {
    modal.instance.show();
  },
  hide(modal) {
    modal.instance.hide();
  },
};
