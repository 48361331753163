<template>
  <div>
    <div class="row">
      <div class="cols-12 col-xxl-7">
        <div class="row">
          <div class="cols-12 col-xxxl-8">
            <div class="mb-3">
              <label class="form-label">{{ intTitle }}</label>
              <input type="text" class="form-control" v-model="item" :class="{ 'is-invalid': vuelidate$.item.$dirty && vuelidate$.item.$error }" />
              <Validation :validator="vuelidate$.item" :feedback="`${intTitle} is required.`" />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <button class="btn btn-secondary" type="button" @click="addItem">{{ intButtonTitle }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <Sortable
          v-if="localItems && localItems.length > 0"
          :list="items"
          :item-key="
            (item) => {
              return item;
            }
          "
          tag="ul"
          class="editable-list"
          :options="sortableOptions"
          @end="(evt) => moveItems(evt, items)">
          <template #item="{ element, index }">
            <li class="single-row">
              <div>{{ element }}</div>
              <i class="item-remove material-icons" @click="removeItem(index)">delete</i>
            </li>
          </template>
        </Sortable>
      </div>
    </div>
  </div>
</template>

<script>
import { Sortable } from 'sortablejs-vue3';
import { required } from '@vuelidate/validators';
export default {
  components: {
    Sortable,
  },
  props: {
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    buttonTitle: {
      type: String,
    },
  },
  computed: {
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  watch: {
    items: function () {
      this.localItems = this.items;
    },
  },
  data() {
    return {
      localItems: [],
      item: '',
      intButtonTitle: 'Add Item',
      intTitle: '',
    };
  },
  validations() {
    return {
      item: {
        required,
      },
    };
  },
  created() {
    this.intButtonTitle = this.buttonTitle || this.intButtonTitle;
    this.intTitle = this.title;
    this.localItems = this.items;
  },
  methods: {
    addItem() {
      this.vuelidate$.$touch();
      if (!this.vuelidate$.$invalid) {
        this.vuelidate$.$reset();
        this.localItems.push(this.item);
        this.item = '';
        //this.$emit('updateList', this.localItems);
      }
    },
    removeItem(index) {
      this.localItems.splice(index, 1);
      //this.$emit('updateList', this.localItems);
    },
    moveItems(evt, array) {
      const item = array.splice(evt.oldIndex, 1)[0];
      array.splice(evt.newIndex, 0, item);
    },
  },
};
</script>

<style scoped></style>
