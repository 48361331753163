<template>
  <div>
    <Sortable
      v-if="attachments.length > 0"
      :list="attachments"
      itemKey="date"
      tag="ul"
      class="editable-list"
      :options="sortableOptions"
      @end="(evt) => moveItems(evt)">
      <template #item="{ element, index }">
        <li class="editable-list-item">
          <div class="file-details">
            <div class="row">
              <div class="col-4">
                <div v-if="!checkProgress(element.fileInfo)" class="image-container">
                  <div class="form-check" v-if="!checkProgress(element.fileInfo)">
                    <!-- Radios for original/blurred/cropped -->
                    <template v-if="element.originalURL">
                      <input
                        :id="'original-radio-' + index"
                        class="form-check-input"
                        :ref="'original-radio-' + index"
                        type="radio"
                        v-model="element.imageURL"
                        :value="element.originalURL"
                        :checked="element.imageURL === element.originalURL"
                        :name="'selection-' + index" />
                      <label class="form-check-label" :for="'original-radio-' + index">Original</label>
                      <img :src="element.originalURL" @click="element.imageURL = element.originalURL" />
                    </template>

                    <template v-if="element.blurredURL">
                      <input
                        :id="'blurred-radio-' + index"
                        class="form-check-input"
                        :ref="'blurred-radio-' + index"
                        type="radio"
                        v-model="element.imageURL"
                        :value="element.blurredURL"
                        :name="'selection-' + index"
                        :checked="element.imageURL === element.blurredURL" />
                      <label class="form-check-label" :for="'blurred-radio-' + index">Blurred To Fit</label>
                      <img :src="element.blurredURL" @click="element.imageURL = element.blurredURL" />
                    </template>

                    <template v-if="element.croppedURL">
                      <input
                        :id="'cropped-radio-' + index"
                        class="form-check-input"
                        :ref="'cropped-radio-' + index"
                        type="radio"
                        v-model="element.imageURL"
                        :value="element.croppedURL"
                        :name="'selection-' + index"
                        :checked="element.imageURL === element.croppedURL" />
                      <label class="form-check-label" :for="'cropped-radio-' + index">Cropped</label>
                      <img :src="element.croppedURL" @click="element.imageURL = element.croppedURL" />
                    </template>
                  </div>
                  <!-- Displays file name -->
                  <div v-else-if="element.fileInfo && element.fileInfo.title" class="file-name display-flex align-center">
                    <span class="" ref="attachmentTitle">{{ element.fileInfo.title }}</span>
                  </div>
                  <div class="mb-3">
                    <button class="btn m-1 btn-secondary" type="button" @click="openCropModal(element.imageURL, index)">Crop Image</button>
                  </div>
                </div>
                <!-- Displays file name, progress, and upload progress. -->
                <div class="spinner-border text-secondary" role="status" v-else>
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="file-name display-flex align-center" v-if="checkProgress(element.fileInfo)">
                  <span class="" ref="attachmentTitle">{{ element.fileInfo.title }}</span>
                </div>
                <div class="file-info" v-if="checkProgress(element.fileInfo)">
                  <span class="upload-progress">
                    {{ `${element.fileInfo.progress} %` }}
                  </span>
                </div>
              </div>
              <div v-if="!imageOnly" class="col">
                <div class="mb-3">
                  <label class="form-label">Caption</label>
                  <input class="form-control" v-model="element.caption" />
                </div>
                <ActionSelector
                  :itemKey="element.imageURL"
                  :action="element.action"
                  :actionKey="element.actionKey"
                  :actionCommunityKey="getCampgroundKey"
                  :actionParams="element.actionParams"
                  single-community
                  use-analytics-title
                  :analyticsTitle="element.analyticsTitle"
                  :dirty="dirty"
                  @update="updateAction(index, $event)" />
              </div>
            </div>
            <i class="item-remove material-icons" @click="removeImage(index, element)">delete</i>
          </div>
        </li>
      </template>
    </Sortable>
    <CropperModal :id="id" :ref="`cropper-modal-list-${id}`" :image="imageCrop" :aspectRatio="width / height" @crop="(image) => saveCrop(image)" />
    <Overlay ref="overlay" :timeout="15000" />
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Sortable } from 'sortablejs-vue3';
import ActionSelector from './forms/ActionSelector';
import Vue from 'vue';
import CropperModal from '@/components/modals/CropperModal.vue';
import Overlay from '@/components/common/Overlay.vue';

export default {
  name: 'AttachmentList',
  components: {
    Sortable,
    ActionSelector,
    CropperModal,
    Overlay,
  },
  props: {
    attachments: Array,

    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 375,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'attachment-list',
    },
    dirty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      croppieRef: null,
      lastCount: 0,
      imageCrop: null,
      cropIndex: -1,
    };
  },
  computed: {
    sortableOptions() {
      return {
        animation: 150,
        easing: 'cubic-bezier(1, 0, 0, 1)',
      };
    },
  },
  watch: {
    attachments: {
      handler() {},
      immediate: false,
    },
  },
  methods: {
    openCropModal(image, index) {
      this.imageCrop = image;
      this.cropIndex = index;
      this.$refs[`cropper-modal-list-${this.id}`].show();
    },
    checkProgress(attachment) {
      return (
        (!attachment || !attachment.progress || attachment.progress === 100 ? false : true) &&
        attachment &&
        attachment.source === 'external' &&
        attachment.isLoading
      );
    },
    checkURL(attachment) {
      return (
        attachment.imageURL.match(/\.(jpeg|jpg|gif|png)$/) != null ||
        (attachment.fileInfo && attachment.fileInfo.extension.match(/\.(jpeg|jpg|gif|png)$/)) != null
      );
    },
    removeImage(index) {
      this.attachments.splice(index, 1);
    },
    async saveCrop(image) {
      if (!image) {
        console.log('Image received is invalid:', image);
        return;
      }

      const result = await this.rescaleImage(image);
      if (!result) {
        console.log('Invalid rescaled image:', result);
        return;
      }

      this.$refs.overlay.show();

      const filename = this.croppieAttachment
        ? this.croppieAttachment.title
          ? this.croppieAttachment.title.replace(/\.[^/.]+$/, '') + '_crop.jpg'
          : Date.now() + '_crop.jpg'
        : this.attachments[this.cropIndex].imageName.replace(/\.[^/.]+$/, '') + '_crop.jpg';
      const date =
        typeof this.attachments[this.cropIndex].date === 'string'
          ? String(this.attachments[this.cropIndex].date)
          : this.attachments[this.cropIndex].date.toString();

      //Convert result to blob and get storage path.
      const response = await fetch(result);
      const blob = await response.blob();
      const bucket = this.bucket || 'headers';
      const imageRef = firebase
        .storage()
        .ref()
        .child(this.communityKey || this.getCampgroundKey)
        .child('images')
        .child(bucket)
        .child(date)
        .child(filename);

      //Put image in bucket, get url, and set to attachments.
      await imageRef.put(blob);
      const downloadURL = await imageRef.getDownloadURL();
      this.spinnerActive = false;
      this.unusedFile = false;
      this.attachments[this.cropIndex].imageURL = downloadURL;
      this.attachments[this.cropIndex].croppedURL = downloadURL;
      this.cropIndex = -1;
      this.$refs.overlay.hide();
    },
    rescaleImage(image) {
      return new Promise((resolve, reject) => {
        const maxWidth = 2000;
        const img = new Image();
        img.src = image;
        img.onload = () => {
          if (img.width > maxWidth) {
            const scaleFactor = maxWidth / img.width;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const rescaledImage = canvas.toDataURL('image/png');

            resolve(rescaledImage);
          } else {
            resolve(image);
          }
        };
        img.onerror = reject;
      });
    },
    updateAction: function (index, data) {
      console.log('🚀 ~ data:', JSON.stringify(data));
      if (!data.action) {
        Object.assign(this.attachments[index], { action: null, actionKey: null, actionCommunityKey: null, actionParams: null, analyticsTitle: null });
        return;
      }
      Vue.set(this.attachments, index, { ...this.attachments[index], ...data });
    },
    moveItems(evt) {
      const item = this.attachments.splice(evt.oldIndex, 1)[0];
      this.attachments.splice(evt.newIndex, 0, item);
      this.$nextTick(() => {
        this.forceUpdateRadio(item, evt.newIndex);
      });
    },
    forceUpdateRadio(element, index) {
      const update = (element, index) => {
        const type = element.imageURL === element.croppedURL ? 'cropped' : element.imageURL === element.blurredURL ? 'blurred' : 'original';
        const ref = this.$refs[type + '-radio-' + index];
        if (ref) ref.checked = true;
      };

      //loop through all attachments and update radio buttons
      this.attachments.forEach((element, index) => {
        update(element, index);
      });
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
ul.editable-list li {
  width: 100%;
}
.file-details {
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.file-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  vertical-align: middle;
}
.image-container {
  text-align: left;
  height: 100%;
  width: 100%;
  margin-top: 10px;
}
img {
  max-height: 60px;
  max-width: 60px;
  display: block;
}
.image-radio {
  display: flex;
  margin: auto;
  vertical-align: middle;
  height: 100%;
}
</style>
