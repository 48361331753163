<template>
  <div>
    <div class="mb-3">
      <label class="form-label">Action</label>
      <select class="form-select" v-model="compAction" @change="updateCompAction">
        <option :value="null" :disabled="actionRequired" hidden>{{ actionText }}</option>
        <option v-for="action in actions" :key="action.value" :value="action.value">{{ action.text }}</option>
      </select>
      <small class="form-text text-muted">App redirect that will be performed after click</small>
      <Validation :validator="vuelidate$.compAction" feedback="Action is required." />
    </div>
    <div class="mb-3" v-if="communitySelectionRequired && communityList.length > 1 && !singleCommunity">
      <label class="form-label">Community</label>
      <select class="form-select" v-model="compActionCommunityKey">
        <option :value="null" disabled hidden>--Select Community--</option>
        <option v-for="community in communityList" :key="community.value" :value="community.value">{{ community.text }}</option>
      </select>
      <Validation :validator="vuelidate$.compActionCommunityKey" feedback="Community Selection is required." />
    </div>
    <div class="row" v-if="!communitySelectionRequired || compActionCommunityKey">
      <div class="col">
        <SelectList
          v-if="compAction == 'hunt'"
          v-model="compActionKey"
          dbContext="hunts"
          label="Scavenger Hunt"
          :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : true"
          :community="compActionCommunityKey"
          keyName="key"
          orderBy="title"
          @select="(val) => updateCompActionKey(val)" />

        <SelectList
          v-if="compAction == 'form'"
          v-model="compActionKey"
          dbContext="custom-forms"
          label="Form"
          :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : true"
          :community="compActionCommunityKey"
          orderBy="title"
          @select="(val) => updateCompActionKey(val)" />

        <SelectList
          v-if="compAction == 'store'"
          v-model="compActionKey"
          dbContext="store"
          label="Store Item"
          :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : true"
          :community="compActionCommunityKey"
          keyName="key"
          orderBy="title"
          @select="(val) => updateCompActionKey(val)" />

        <SelectList
          v-if="compAction == 'mainMenu'"
          v-model="compActionKey"
          dbContext="main-menu"
          label="Home Screen Item"
          :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : true"
          :community="compActionCommunityKey"
          keyName="uniqueId"
          orderBy="name"
          @select="(val) => updateCompActionKey(val)" />

        <SelectList
          v-if="compAction == 'booking'"
          v-model="compActionKey"
          dbContext="bookings"
          label="Booking Item"
          :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : true"
          :community="compActionCommunityKey"
          orderBy="name"
          @select="(val) => updateCompActionKey(val)" />

        <!--SelectList
              v-if="compAction == 'map'"
              v-model="compActionKey"
              dbContext="locations"
              label="Community Locations"
              :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : null"
              keyName="searchableName"
              orderBy="name"
            /-->

        <MapList
          v-if="compAction == 'searchablemap'"
          v-model="mapKey"
          dbContext="searchablemap-detail"
          label="Select Map/Image"
          :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : true"
          :community="compActionCommunityKey"
          orderBy="title" />
        <!--MapList
              v-if="mapKey"
              v-model="locationKey"
              :dbContext="`searchablemap-detail/${mapKey}/locations`"
              label="Select Map Location"
              keyName="key"
              :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : null"
              orderBy="title"
            /--->

        <FileList
          v-if="compAction == 'file'"
          v-model="compActionKey"
          :state="vuelidate$.compActionKey.$dirty ? !vuelidate$.compActionKey.$error : true"
          :community="compActionCommunityKey"
          @select="updateCompActionKey" />
        <div class="mb-3" v-if="compAction === `file`">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :id="`use-web-view${_uid}`" v-model="useWebView" />
            <label class="form-check-label" :for="`use-web-view${_uid}`">Use Web View</label>
          </div>
          <small class="form-text text-muted"
            >Use a web view to make the file open up inside the app. Otherwise the file will open in Safari/Chrome.</small
          >
        </div>
        <div class="mb-3" v-if="compAction === `website`">
          <label class="form-label">URL</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': vuelidate$.compActionKey.$dirty ? vuelidate$.compActionKey.$error : null }"
            id="url"
            v-model="compActionKey"
            placeholder="http://www.google.com"
            @change="updateCompActionKey(compActionKey)" />
          <Validation :validator="vuelidate$.compActionKey" map="url" />
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :id="`use-web-view${_uid}`" v-model="useWebView" />
            <label class="form-check-label" :for="`use-web-view${_uid}`">Use Web View</label>
          </div>
          <small class="form-text text-muted"
            >Use a web view to make the website open up while looking like it is still inside the app. Otherwise the website will open in
            Safari/Chrome.</small
          >
        </div>
        <div class="mb-3" v-if="compAction === `mailto`">
          <label class="form-label">Email Address</label>
          <input type="text" class="form-control" id="email" v-model="compActionKey" @change="updateCompActionKey(compActionKey)" />
          <Validation :validator="vuelidate$.compActionKey" map="email" />
        </div>
        <div class="mb-3" v-if="compAction === `call`">
          <label class="form-label">Phone Number</label>
          <input type="text" class="form-control" id="phoneNumber" v-model="compActionKey" @change="updateCompActionKey(compActionKey)" />
          <Validation :validator="vuelidate$.compActionKey" map="phone" />
        </div>

        <div class="mb-3" v-if="compAction && useAnalyticsTitle">
          <label class="form-label">Analytics Title</label>
          <input type="text" class="form-control" v-model="compAnalyticsTitle" placeholder="Header Without Analytics Title" />
          <small class="form-text text-muted"
            >If you want to track individual header clicks on this, add a title, otherwise reporting will be aggregated</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf, url, or } from '@vuelidate/validators';
import SelectList from '../inputfields/SelectList';
import FileList from '../inputfields/FileList';
import MapList from '../inputfields/MapList';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

export default {
  props: {
    action: {
      type: String,
      default: null,
    },
    actionKey: {
      type: String,
    },
    actionCommunityKey: {
      type: String,
    },
    actionParams: {
      type: Array,
    },
    actionRequired: {
      type: Boolean,
      default: false,
    },
    singleCommunity: {
      type: Boolean,
      default: false,
    },
    useAnalyticsTitle: {
      type: Boolean,
      default: false,
    },
    analyticsTitle: {
      type: String,
      default: null,
    },
    itemKey: {
      type: String,
      default: null,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      compAction: null,
      compActionCommunityKey: null,
      compActionKey: null,
      compActionParams: null,
      compAnalyticsTitle: null,
      mapKey: null,
      locationKey: null,
      useWebView: false,
      communityList: [],
      communitySelectionRequired: false,
      actions: [
        { value: null, text: 'No Action/Not Clickable', allowRemote: true, communitySelectionRequired: false },
        { value: 'store', text: 'Open Store Item', allowRemote: false, communitySelectionRequired: true },
        { value: 'form', text: 'Open Custom Form', allowRemote: false, communitySelectionRequired: true },
        { value: 'mainMenu', text: 'Open Main Menu Item', allowRemote: false, communitySelectionRequired: true },
        { value: 'searchablemap', text: 'Open Map/Image', allowRemote: false, communitySelectionRequired: true },
        { value: 'booking', text: 'Open Booking Item', allowRemote: false, communitySelectionRequired: true },
        { value: 'file', text: 'Open File', allowRemote: false, communitySelectionRequired: true },
        { value: 'website', text: 'Open Website', allowRemote: true },
        { value: 'hunt', text: 'Open Scavenger Hunt', allowRemote: false, communitySelectionRequired: true },
        { value: 'call', text: 'Dial Number', allowRemote: true },
        { value: 'mailto', text: 'Send Email', allowRemote: true },
      ],
    };
  },
  computed: {
    actionText: function () {
      return this.actionRequired ? '-- Select Action --' : 'No Action/Not Clickable';
    },
  },

  watch: {
    dirty: function () {
      if (this.dirty) {
        this.vuelidate$.$touch();
      }
    },
    compAnalyticsTitle: function () {
      this.updateAction();
    },
    useWebView: function () {
      this.updateAction();
    },
    compActionParams: {
      handler: function () {
        this.updateAction();
      },
      deep: true,
    },
    mapKey: {
      handler: function () {
        this.compActionKey = this.mapKey.mapKey;
        this.$nextTick(() => {
          this.compActionParams = this.mapKey.locations;
        });
      },
      deep: true,
    },
    itemKey: function () {
      this.setupComponent();
    },
  },
  validations() {
    return {
      compAction: {
        required: requiredIf(function () {
          return this.actionRequired;
        }),
      },
      compActionKey: {
        required: requiredIf(function () {
          return this.compAction !== null;
        }),
        urlValidator: or(url, function () {
          return this.compAction !== 'website';
        }),
      },
    };
  },
  async created() {
    this.setupComponent();
  },
  methods: {
    updateCompAction: function () {
      this.communitySelectionRequired = this.compAction
        ? this.actions.filter((action) => action.value === this.compAction)[0].communitySelectionRequired || false
        : false;
      this.compActionKey = null;
      this.compActionParams = [];
      this.updateAction();
    },
    updateCompActionKey: function (val) {
      this.compActionKey = val;
      this.updateAction();
    },
    setupComponent: async function () {
      this.compAction = this.action;
      this.compActionKey = this.actionKey;
      if (this.compAction == 'website' && this.compActionKey && this.compActionKey.includes('mailto:')) {
        this.compAction = 'mailto';
        this.compActionKey = this.compActionKey.replace('mailto:', '');
      }
      this.compActionCommunityKey = this.actionCommunityKey;
      this.compActionParams = this.actionParams;
      this.compAnalyticsTitle = this.analyticsTitle;
      if (this.compAction?.includes('webview') || this.actionParams?.useWebView) {
        this.useWebView = true;
        if (this.compAction?.includes('webview')) {
          this.compAction = this.compAction.replace('-webview', '');
        }
      }
      if (this.action === 'searchablemap') {
        this.mapKey = { mapKey: this.actionKey, locations: this.actionParams };
      }

      const allCommunities = (await firebase.database().ref('resort-navigator').child('campground-locations').once('value')).val() || {};
      this.communityList = Object.keys(allCommunities).map((x) => ({ value: x, text: allCommunities[x].title }));
      const isResortAdmin =
        window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
      if (!isResortAdmin) {
        const editLocations = JSON.parse(window.localStorage.getItem(`EDIT_LOCATIONS`)) || [];
        this.communityList = this.communityList.filter((x) => editLocations.includes(x.value));
      }
      if (this.communityList.length == 1) this.compActionCommunityKey = this.communityList[0].value;
    },
    updateAction: function () {
      let action = this.useWebView ? `${this.compAction}-webview` : this.compAction;
      let actionKey = this.compActionKey;
      if (this.compAction == 'mailto') {
        action = 'website';
        if (this.compActionKey && !this.compActionKey.includes('mailto:')) actionKey = `mailto:${this.compActionKey}`;
      }
      this.$emit('update', {
        action: action || null,
        actionKey: actionKey || null,
        actionParams: this.compActionParams || null,
        actionCommunityKey: this.compActionCommunityKey || null,
        analyticsTitle: this.compAnalyticsTitle || null,
        invalid: this.vuelidate$.$invalid,
      });
    },
  },
  components: {
    SelectList,
    FileList,
    MapList,
  },
};
</script>

<style lang="scss" scoped></style>
