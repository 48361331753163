<template>
  <div class="row align-items-center text-center m-1">
    <div class="col-auto px-0">
      <svg
        class="bi h2 p3 text-success"
        v-if="scoreMap[huntStep.key] === 1"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16">
        <path
          d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
      </svg>
      <svg
        class="bi h2 p3 text-danger"
        v-if="scoreMap[huntStep.key] === 0"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16">
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
    <div class="col-auto px-0" v-if="scoreMap[huntStep.key] === 0 || scoreMap[huntStep.key] === 1">
      <button class="btn btn-danger" @click="updateScore(huntStep.key, null)">Clear score</button>
    </div>
    <div class="col-auto px-0" v-if="scoreMap[huntStep.key] === null">
      <button class="btn btn-success" style="margin-left: 8px" @click="updateScore(huntStep.key, 1)">Correct</button>
    </div>
    <div class="col-auto px-0" v-if="scoreMap[huntStep.key] === null">
      <button class="btn btn-danger" style="margin-left: 8px" @click="updateScore(huntStep.key, 0)">Incorrect</button>
    </div>
  </div>
</template>

<script>
//import { BIconCheck, BIconX } from 'bootstrap-vue';

export default {
  components: {
    // BIconX,
    // BIconCheck
  },
  name: 'HuntEntryAction',
  props: ['scoreMap', 'huntStep'],
  emits: ['update-score'],
  methods: {
    updateScore: function (key, score) {
      this.$emit('update-score', key, score);
    },
  },
};
</script>

<style scoped></style>
