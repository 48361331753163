<template>
  <div>
    <div>
      <img id="amc-logo" src="/amc_logo.png" alt="App My Community Logo" @click="goHome()" />
      <i class="material-icons logo-icons" @click="goHome()">home</i>
    </div>
    <div class="position-relative">
      <div class="bg-light d-flex align-items-center justify-content-center">
        <div class="two-column">
          <div id="calendar-column">
            <div class="calendar-container">
              <iframe v-if="params.selectedTab" :src="getCalendarUrl" width="100%" :height="defaultHeight" allowfullscreen="" frameborder="0" />
              <div v-else>Please select a calendar on the right.</div>
            </div>
          </div>
          <div id="right-column" class="p-2">
            <div class="p-2 form-container">
              <div class="row">
                <div class="col">
                  <label class="form-label">Calendar</label>
                  <select v-model="params.selectedTab" class="form-select">
                    <option v-for="context in contexts" :key="context.key" :value="context.value">{{ context.key }}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col">Calendar Type</div>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    id="calendar-type-agenda"
                    v-model="params.defaultView"
                    class="form-check-input"
                    type="radio"
                    name="header-type"
                    value="listMonth" />
                  <label class="form-check-label" for="calendar-type-agenda">Agenda</label>
                </div>
                <div class="col">
                  <input
                    id="calendar-type-monthly"
                    v-model="params.defaultView"
                    class="form-check-input"
                    type="radio"
                    name="header-type"
                    value="dayGridMonth" />
                  <label class="form-check-label" for="calendar-type-monthly">Monthly</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Background Color</label>
                    <div class="color-selector">
                      <label class="btn btn-light" @click.stop="editColorsBackground = true">
                        <div :style="`background-color:${params.colorBackground}`">&nbsp;</div>
                      </label>
                    </div>
                    <chrome-picker
                      v-show="editColorsBackground"
                      id="editColorsBackground"
                      v-model="colorBackground"
                      v-click-outside="onColorClose"
                      :data-edit="editColorsBackground" />
                    <small class="form-text text-muted">Color used for calendar header/background</small>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Button Color</label>
                    <div class="color-selector">
                      <label class="btn btn-light" @click.stop="editColorsaccent = true">
                        <div :style="`background-color:${params.colorAccent}`">&nbsp;</div>
                      </label>
                    </div>
                    <chrome-picker v-show="editColorsaccent" id="editColorsaccent" v-model="colorAccent" v-click-outside="onColorClose" />
                    <small class="form-text text-muted">Color used for arrows</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label class="form-label">Text Color</label>
                    <div class="color-selector">
                      <label class="btn btn-light" @click.stop="editColorstext = true">
                        <div :style="`background-color:${params.colorText}`">&nbsp;</div>
                      </label>
                    </div>
                    <chrome-picker v-show="editColorstext" id="editColorstext" v-model="colorText" v-click-outside="onColorClose" />
                    <small class="form-text text-muted">Color used for URL links and unread messages</small>
                  </div>
                </div>
                <div v-if="params.defaultView === 'listMonth'" class="col">
                  <div class="mb-3">
                    <label class="form-label">Event Color</label>
                    <div class="color-selector">
                      <label class="btn btn-light" @click.stop="editColorsprimary = true">
                        <div :style="`background-color:${params.colorPrimary}`">&nbsp;</div>
                      </label>
                    </div>
                    <chrome-picker v-show="editColorsprimary" id="editColorsprimary" v-model="colorPrimary" v-click-outside="onColorClose" />
                    <small class="form-text text-muted">Color used for event in agenda</small>
                  </div>
                </div>
                <div v-else class="col">
                  <div class="mb-3">
                    <label class="form-label">Date Color</label>
                    <div class="color-selector">
                      <label class="btn btn-light" @click.stop="editColorsdate = true">
                        <div :style="`background-color:${params.colorDate}`">&nbsp;</div>
                      </label>
                    </div>
                    <chrome-picker v-show="editColorsdate" id="editColorsdate" v-model="colorDate" v-click-outside="onColorClose" />
                    <small class="form-text text-muted">Color used for event in agenda</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="form-label">Calendar Height</label>
                  <input v-model="params.defaultHeight" type="text" class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <input id="hide-arrows" v-model="params.hideArrows" class="form-check-input" type="checkbox" />
                    <label class="form-check-label" for="hide-arrows">Hide Navigation Arrows</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="params.defaultView === 'listMonth'" class="col">
                  <div class="form-check">
                    <input id="expand" v-model="params.expand" class="form-check-input" type="checkbox" />
                    <label class="form-check-label" for="expand">Expand All Events to Display Descriptions</label>
                  </div>
                </div>
                <div v-if="params.expand" class="col">
                  <div class="form-check">
                    <input id="expand" v-model="params.isCondensed" class="form-check-input" type="checkbox" />
                    <label class="form-check-label" for="expand">Condense Descriptions to Show More Events</label>
                  </div>
                </div>
              </div>
              <!---div class="row mt-3">
                <div class="col">
                  <button class="btn btn-block btn-secondary w-100" style="z-index: -99" @click="save">SAVE CONFIG</button>
                </div>
              </div--->
              <div class="row mt-3">
                <div class="col">
                  <label class="form-label">Embed Code</label>
                  <textarea :value="getCalendarHTML" style="width: 100%" rows="9" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <label class="form-label">URL</label>
                  <textarea :value="getCalendarUrl" style="width: 100%" rows="5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { LoginMixin } from '@/components/mixins/LoginMixin';

export default {
  name: 'CalendarBuilder',
  components: {
    'chrome-picker': Chrome,
  },
  mixins: [LoginMixin],
  data() {
    return {
      editColorsBackground: false,
      editColorsaccent: false,
      editColorsprimary: false,
      editColorstext: false,
      editColorsdate: false,
      colorBackground: null,
      colorAccent: null,
      colorPrimary: null,
      colorDate: '#0d6efd',
      colorText: '#ffffff',
      defaultHeight: '898px',
      contexts: [],
      calendarType: 'listMonth',
      params: {
        clocation: '',
        defaultHeight: '800px',
        defaultView: 'listMonth',
        colorBackground: '',
        colorAccent: '',
        colorPrimary: '',
        colorText: '#ffffff',
        colorDate: '#0d6efd',
        selectedTab: '',
        hideArrows: false,
        expand: false,
        isCondensed: false,
      },
    };
  },
  computed: {
    getCalendarUrl() {
      return `${this.$hostname}/calendar?${new URLSearchParams(this.params).toString()}`;
    },
    getCalendarHTML() {
      return `<iframe src="${this.getCalendarUrl}" width="100%" height="${this.params.defaultHeight}" allowfullscreen="" frameborder="0"></iframe>`;
    },
  },
  watch: {
    colorBackground: function () {
      this.params.colorBackground = this.colorBackground.hex || this.params.colorBackground;
    },
    colorPrimary: function () {
      this.params.colorPrimary = this.colorPrimary.hex || this.params.colorPrimary;
    },
    colorAccent: function () {
      this.params.colorAccent = this.colorAccent.hex || this.params.colorAccent;
    },
    colorText: function () {
      this.params.colorText = this.colorText.hex || this.params.colorText;
    },
    colorDate: function () {
      this.params.colorDate = this.colorDate.hex || this.params.colorDate;
    },
  },
  created() {
    this.params.clocation = this.getCampgroundKey;
    this.colorBackground = this.getBackgroundColor;
    this.colorAccent = this.getAccentColor;
    this.colorPrimary = this.getPrimaryColor;
    this.params.colorBackground = this.getBackgroundColor;
    this.params.colorAccent = this.getAccentColor;
    this.params.colorPrimary = this.getPrimaryColor;
    const promises = [];
    promises.push(this.getContextRef('events-resort').once('value'));
    promises.push(this.getContextRef('events-local').once('value'));
    promises.push(this.getContextRef('amc-events').child('config').orderByChild('title').once('value'));
    Promise.all(promises).then((snapshots) => {
      const [eventsResortSnap, eventsLocalSnap, amcEventsSnap] = snapshots;
      if (amcEventsSnap.hasChildren()) {
        amcEventsSnap.forEach((childSnap) => {
          this.contexts.push({ key: childSnap.val().title, value: childSnap.key });
        });
      }
      const legacyEventsDescriptor = this.contexts.length === 0 ? 'Activities' : 'Legacy Events';

      if (eventsResortSnap.hasChildren()) {
        this.contexts.push({ key: `${legacyEventsDescriptor} Tab 1`, value: 'events-resort' });
      }
      if (eventsLocalSnap.hasChildren()) {
        this.contexts.push({ key: `${legacyEventsDescriptor} Tab 2`, value: 'events-local' });
      }
    });
  },
  methods: {
    onColorClose: function () {
      const editColors = ['editColorsBackground', 'editColorsaccent', 'editColorsprimary', 'editColorstext', 'editColorsdate'];
      editColors.forEach((key) => {
        this[key] = false;
      });
    },
    save() {
      console.log(this.pageData);
    },
  },
};
</script>
<style scoped>
div.two-column {
  width: 100%;
  display: grid;
  grid-template-columns: 0.75fr 0.25fr;
}
.color-selector div {
  width: 20px;
  height: 20px;
}
.color-selector .btn {
  padding: 0.25rem 0.25rem;
}
.vc-chrome {
  width: 175px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
</style>
