<template>
  <div class="carousel-container" :style="headerStyles">
    <iframe
      v-if="headerInfo && headerInfo.videoURL"
      width="490"
      height="261"
      :src="`https://www.youtube.com/embed/${headerInfo.videoURL}`"
      title="YouTube video player"
      frameborder="0"
      allow="mute; autoplay; allowfullscreen"></iframe>
    <div
      id="listBuilderCarousel"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
      v-if="headerInfo && headerInfo.images && headerInfo.images.length > 0">
      <div class="carousel-inner">
        <div
          class="carousel-item"
          v-for="(src, index) in headerInfo.images"
          :data-bs-interval="headerInfo.delaySeconds * 1000 || 5000"
          :class="{ active: index === 0 }"
          :key="index">
          <img :src="src.imageURL" class="d-block w-100" :alt="src.caption" style="height: 261px" />
          <div class="carousel-caption d-none d-md-block">
            <p>{{ src.caption }}</p>
          </div>
        </div>
      </div>
      <ol class="carousel-indicators" style="list-style-type: none">
        <li
          v-for="(src, index) in headerInfo.images"
          :key="index"
          data-bs-target="#listBuilderCarousel"
          :data-bs-slide-to="index"
          :class="{ active: index === 0 }" />
      </ol>
      <button
        id="carousel-control-prev"
        v-if="headerInfo.images && headerInfo.images.length > 1"
        class="carousel-control-prev"
        type="button"
        data-bs-target="#listBuilderCarousel"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        id="carousel-control-next"
        v-if="headerInfo.images && headerInfo.images.length > 1"
        class="carousel-control-next"
        type="button"
        data-bs-target="#listBuilderCarousel"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <slot name="title" />
  </div>
</template>

<script>
export default {
  name: 'BCarousel',
  props: {
    headerInfo: {
      type: Object,
      required: true,
    },
    newItem: {
      type: Object,
      default: () => ({}),
    },
    headerStyles: {
      type: String,
      default: '',
    },
  },
  mounted() {
    //Fix for bootstrap not automatically starting carousel.
    setTimeout(() => {
      if (this.headerInfo && this.headerInfo.images && this.headerInfo.images.length > 1) {
        console.log('auto rotate start');
        const prev = document.getElementById('carousel-control-prev');
        const next = document.getElementById('carousel-control-next');
        next?.click();
        prev?.click();
      }
    }, 2000);
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}
.carousel-container h1 {
  background-color: #1b1b1bcc;
  position: absolute;
  width: 100%;
  height: 54px;
  top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
