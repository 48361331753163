<template>
  <div>
    <div class="mb-3">
      <label for="contentInput" class="form-label">Content</label>
      <input type="text" class="form-control" id="contentInput" v-model="item.value" />
    </div>
    <div v-if="item.name == 'headerText'">
      <div class="mb-3 form-check">
        <input type="checkbox" class="form-check-input" id="updateHeaderScrim" v-model="updateHeaderScrim" />
        <label class="form-check-label" for="updateHeaderScrim">Include light grey box behind text</label>
      </div>
      <div class="mb-3 form-check">
        <input type="checkbox" class="form-check-input" id="updateScrimFade" v-model="updateScrimFade" />
        <label class="form-check-label" for="updateScrimFade">Include fade out for box behind text</label>
      </div>
    </div>
    <button class="btn m-1 btn-primary" type="submit" @click.prevent="updateText">Update</button>
    <button class="btn m-1 btn-secondary" type="button" @click.prevent="cancel">Cancel</button>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: String,
    headerScrim: {
      type: Boolean,
      default: false,
    },
    scrimFade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updateHeaderScrim: false,
      updateScrimFade: false,
      item: {
        name: this.name,
        value: this.value,
      },
    };
  },
  created() {
    this.updateHeaderScrim = this.headerScrim ? true : false;
    this.updateScrimFade = this.scrimFade ? true : false;
  },
  methods: {
    updateText: function () {
      this.$emit('updatePrintout', { name: 'headerScrim', value: this.updateHeaderScrim }, true);
      this.$emit('updatePrintout', { name: 'scrimFade', value: this.updateHeaderScrim }, true);
      this.$emit('updatePrintout', this.item);
    },
    cancel: function () {
      this.item.value = this.value;
      this.updateText();
    },
  },
};
</script>

<style lang="scss" scoped></style>
