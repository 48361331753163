<template>
  <b-modal
    :id="`cropper-modal-${id}`"
    :ref="`cropper-modal-${id}`"
    title="Crop Image"
    size="lg"
    classes="amc-modal"
    :scrollable="false"
    :buttons="[
      { title: 'Cancel', variant: 'btn-secondary', action: () => {} },
      { title: 'Crop', variant: 'btn-accent', action: () => crop() },
    ]">
    <template #body>
      <div v-if="isLoading" class="d-flex justify-content-center align-items-center" style="height: 200px">
        <div class="spinner-border text-white" role="status" style="width: 3rem; height: 3rem">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="container">
        <cropper
          :ref="`cropper-${id}`"
          :src="image"
          :resize-image="{ wheel: false }"
          :stencil-props="{
            aspectRatio: aspectRatio,
            handlers: {
              eastNorth: true,
              north: false,
              westNorth: true,
              west: false,
              westSouth: true,
              south: false,
              eastSouth: true,
              east: false,
            },
          }"
          default-boundaries="fill"
          :default-size="setDefaultSize"
          priority="visibleArea"
          @ready="onCropReady"
          @change="onCropChange"
          @error="onCropError" />
      </div>
    </template>
  </b-modal>
</template>

<script>
import BModal from '@/components/common/BModal.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    BModal,
    Cropper,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    id: {
      type: String,
      default: 'cropper-modal',
    },
  },
  data() {
    return {
      isLoading: true,
      result: null,
    };
  },
  watch: {
    image: {
      handler(newImage, oldImage) {
        if (newImage != oldImage) {
          this.isLoading = true;
          this.$refs[`cropper-${this.id}`].reset();
        }
      },
    },
  },
  methods: {
    setDefaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },

    onCropReady() {
      this.isLoading = false;
    },
    onCropChange({ coordinates, canvas }) {
      if (canvas) {
        console.log('Crop changed:', coordinates, canvas);
        this.result = canvas.toDataURL();
      }
    },
    onCropError(evt) {
      console.log('Crop error:', evt);
    },

    crop() {
      this.$emit('crop', this.result);
      this.$refs[`cropper-modal-${this.id}`].hide();
    },

    show() {
      this.$refs[`cropper-modal-${this.id}`].show();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 400px);
  height: calc(100vh - 400px);
}
</style>
