import SlAlert from '@shoelace-style/shoelace/dist/components/alert/alert.js';

export const Toast = {
  message(message, variant = 'primary', duration = 3000, icon = '') {
    const alert = internalAlert(variant, duration, icon, `${escapeHtml(message)}`);
    document.body.append(alert);
    return alert.toast();
  },
  title(title, subtitle, variant = 'primary', duration = 3000, icon = '') {
    const alert = internalAlert(variant, duration, icon, `<strong>${escapeHtml(title)}</strong><br />${escapeHtml(subtitle)}`);
    document.body.append(alert);
    return alert.toast();
  },
};

function internalAlert(variant, duration, icon, html) {
  const iconVal = setDefaultIcon(variant, icon);
  return Object.assign(document.createElement('sl-alert'), {
    variant,
    closable: true,
    duration: duration,
    innerHTML: `<sl-icon name="${iconVal}" slot="icon"></sl-icon>${html}`,
  });
}
function escapeHtml(html) {
  const div = document.createElement('div');
  div.textContent = html;
  return div.innerHTML;
}
function setDefaultIcon(variant, icon) {
  if (icon == '') {
    switch (variant) {
      case 'primary':
        return 'info-circle';
      case 'success':
        return 'check2-circle';
      case 'danger':
        return 'exclamation-octagon';
      case 'warning':
        return 'exclamation-triangle';
      case 'neutral':
        return 'gear';
      default:
        return icon;
    }
  }
  return icon;
}
